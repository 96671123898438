import React, { useState } from 'react'
import tinnImg from '../../assets/admin/img/signFinal.jpg'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../common/firebase';
import { ADMIN_ROUTES, FRONTEND_ROUTES } from '../../constants/RouteConst'
import Alert from '../../components/alert/Alert';
import { getData } from '../../common/fetch';
import { collection, doc, getDoc } from 'firebase/firestore';
import { ROLES } from '../../constants/RoleConst';
import { useAuth } from '../../context/AuthContext';
import closeEye from '../../assets/admin/img/closeeye.svg'
import eye from '../../assets/admin/img/eye.svg'
import { createEvent } from '../../common/helper';
import { EventType } from '../../constants/EventConst';
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';
import Loader from '../extra/Loader';

const Login = () => {
  const navigate = useNavigate();
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const { authChecked, setAuthChecked } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      setSuccessMsg('');
      setErrorMsg('');
      setIsLoading(true);
      const docData = await getData(email);
      console.log(docData)
      const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
      const userDocRef = doc(usersCollection, docData.uid);
      console.log(userDocRef)
      const userDocSnap = await getDoc(userDocRef);
      console.log(userDocSnap)
      const userStatus = userDocSnap.data();
      const stateRef = collection(userDocRef, DATABASECOLLECTION.STATE);
      const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
      const currentDocSnap = await getDoc(currentDocRef);
      const userDetails = currentDocSnap.data();
      const data = {
        userId: docData.uid,
        type: EventType.LOGIN,
        details: {
          succeeded: false,
        }
      }
      // if (!userDetails.isEmailVerified && docData.customClaims.role === ROLES.CLINICIAN) {
      //   setErrorMsg("Your email has not been verified. Please verify your email to continue!");
      //   return false;
      // }
      if (userDetails && !userDetails.onboardComplete) {
        setIsLoading(false);
        data.details.succeeded = false;
        setErrorMsg("Your account has not been approved yet. Please contact at email info@neuromonics.com");
        return false;
      }
      console.log(userStatus)
      if (!userStatus.isActive) {
        setIsLoading(false);
        data.details.succeeded = false;
        setErrorMsg("Your account is deactivated. Please contact at email info@neuromonics.com");
        return false;
      }
      const userCredential = await signInWithEmailAndPassword(email, password);
      if (userCredential && userCredential.user) {
        localStorage.setItem('login_email', email);
        localStorage.setItem('role', docData.customClaims.role);
        localStorage.setItem('docid', docData.uid);
        data.details.succeeded = true;
        await createEvent(data);
        setSuccessMsg('Login successfully!');
        setAuthChecked(true);
        setIsLoading(false);
        if (docData.customClaims.role === ROLES.ADMIN) {
          navigate('/manage-users');
        } else {
          navigate('/manage-patients');
        }
      } else {
        console.debug("NO CRED")
        data.details.succeeded = false;
        await createEvent(data);
        setIsLoading(false);
        setErrorMsg("Entered Invalid credential");
      }
    } catch (error) {
      console.debug(error)
      setIsLoading(false);
      setErrorMsg("Entered Invalid credential");
      // setErrorMsg(error.message);
    }
  };

  return (
    <>
      <div className="authpages h-100 position-relative">
        <div className="container-fluid  h-100 signupSection">
          <div className="row h-100 align-items-lg-center justify-content-center ">
            <div className="col-12 col-lg-6 px-0 overflow-hidden">
              <div className="registerImg">
                <div className="position-relative h-100 w-100">
                  <img src={tinnImg} className="img-fluid w-100 h-100 object-fit-cover" alt="table accordion" />
                  <div className="homePageBtn">
                    <div className="honeBtn">
                      <Link to={FRONTEND_ROUTES.HOME_PAGE} className='d-flex align-items-center gap-1 text-decoration-none'>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className=' homepageBtnAnc' xmlns="http://www.w3.org/2000/svg"><path d="M216.4 163.7c5.1 5 5.1 13.3.1 18.4L155.8 243h231.3c7.1 0 12.9 5.8 12.9 13s-5.8 13-12.9 13H155.8l60.8 60.9c5 5.1 4.9 13.3-.1 18.4-5.1 5-13.2 5-18.3-.1l-82.4-83c-1.1-1.2-2-2.5-2.7-4.1-.7-1.6-1-3.3-1-5 0-3.4 1.3-6.6 3.7-9.1l82.4-83c4.9-5.2 13.1-5.3 18.2-.3z"></path></svg>
                        <span className='d-flex align-items-center ms-1 text-white btnName'>Go To Home Page</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-lg-5 col-xl-4">
              <div className="inputsignSide text-center py-4">
                <div className="row">
                  <div className="col-12">
                    <div className="headingI pb-1">
                      Welcome Back !
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="subHeding">
                      Sign in to continue to Neuromonics.
                    </div>
                  </div>
                  <div className="col-12">
                    <form className="MainForm" onSubmit={handleSubmit}>
                      {errorMsg && (
                        <Alert text={errorMsg} type="error" />
                      )}
                      {successMsg && (
                        <Alert text={successMsg} type="success" />
                      )}
                      {isLoading && (<p style={{ color: '#34617e', fontWeight: 600 }}><i>Please wait....</i></p>)}
                      <div className="form-floating mb-3">
                        <input type="email" className="form-control shadow-none formcontrolCustom" id="floatingInput" name="email" placeholder="name@example.com" />
                        <label htmlFor="floatingInput" className='formControlLable'>
                          <div className="labelTxt">
                            Email address
                          </div>
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="form-control shadow-none formcontrolCustom"
                          id="floatingPassword"
                          name="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="input-grouptext eyeImg" onClick={handleTogglePassword}>
                          <div className="eyeImgDiv">
                            {showPassword ?
                              <img src={eye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                              :
                              <img src={closeEye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                            }
                          </div>
                        </span>
                        <label htmlFor="floatingPassword" className='formControlLable'>
                          <div className="labelTxt">
                            Password
                          </div>
                        </label>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col px-lg-0 text-end">
                          <Link to={ADMIN_ROUTES.FORGET_PASSWORD_URL} className='text-decorationnone forgottxt'>
                            Forgotten Password?
                          </Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="signInButton mt-3 mt-md-4  text-center">
                          <button className='signinBtn' type='btn'>Sign In</button>
                        </div>
                      </div>
                      <div className="col-12 text-center mt-3" style={{ fontStyle: "italic", fontWeight: "600", fontSize: "15px" }}>
                        Want to be a Neuromonics clinician?  <br />
                        <Link to={ADMIN_ROUTES.SIGNUP_URL} className='text-decoration-none forgottxt'> Register your interest today.
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login