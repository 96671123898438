import React, { useEffect, useRef, useState } from 'react'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../common/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { ROLES } from '../../constants/RoleConst';
import Alert from '../../components/alert/Alert';
import { deleteUser, getData, sendEmail, setRoleData } from '../../common/fetch';
import { getTemplateContent } from '../../common/helper';
import { TEMPPLATETYPE } from '../../constants/TemplateTypeConst';
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';
import Loader from '../extra/Loader';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { ADMIN_ROUTES } from '../../constants/RouteConst';

const EditUser = () => {
    const uid = useParams();
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [qualifications, setQualifications] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [message, setMessage] = useState("");
    const [role, setRole] = useState("");
    const [status, setStatus] = useState("");
    const [onboardComplete, setOnboardComplete] = useState("");
    const [previousStatus, setPreviousStatus] = useState("");
    const [data, setData] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const userRole = localStorage.getItem('role');
    const [deleteModal, setDeleteModal] = useState(false)
    const handleClose = () => setDeleteModal(false)
    const navigate = useNavigate();

    const handleUpdate = async () => {
        try {
            setSuccessMsg('');
            setErrorMsg('');
            const isValidEmail = (email) => {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailRegex.test(email);
            };
            const validationRules = [
                { field: firstName, message: 'The first name field must not be left blank.' },
                { field: lastName, message: 'The last name field must not be left blank.' },
                { field: phone, message: 'The phone field must not be left blank.' },
                { field: state, message: 'The state field must not be left blank.' },
                { field: country, message: 'The country field must not be left blank.' },
                { field: role, message: 'The role field must not be left blank.' },
                { field: status, message: 'The status field must not be left blank.' },
            ];
            for (const rule of validationRules) {
                if (!rule.field) {
                    setErrorMsg(rule.message);
                    return false;
                }
            }
            if (!isValidEmail(email)) {
                setErrorMsg('Entered invalid email address');
                return false;
            }
            try {
                const userProfileDocRef = doc(collection(db, DATABASECOLLECTION.USERPROFILES), uid.uid);
                await updateDoc(userProfileDocRef, {
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    qualifications: qualifications,
                    state: state,
                    country: country,
                    message: message,
                    isActive: status === 'true' ? true : false,
                });
                if ((role === ROLES.CLINICIANPENDING || role === ROLES.CLINICIANINHOUSE || role === ROLES.CLINICIANINCLINIC) && (onboardComplete === 'approvedinclinic' || onboardComplete === 'approvedinhouse') && (previousStatus !== 'approvedinclinic' || previousStatus !== 'approvedinhouse')) {
                    const roleType = (onboardComplete === 'approvedinhouse') ? ROLES.CLINICIANINHOUSE : ROLES.CLINICIANINCLINIC;
                    const stateRef = collection(userProfileDocRef, DATABASECOLLECTION.STATE);
                    const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
                    await setRoleData(uid.uid, roleType);
                    await updateDoc(currentDocRef, {
                        completedHearingTest: false,
                        completedTRQ: false,
                        connectedClinician: false,
                        onboardComplete: (onboardComplete === 'approvedinclinic' || onboardComplete === 'approvedinhouse') ? true : false,
                    });
                    // await setRoleData(uid.uid, ROLES.CLINICIAN);
                    const userProfileClinicianDocData = await getDoc(userProfileDocRef);
                    const userProfileData = userProfileClinicianDocData.data();
                    const clinicianTemplate = await getTemplateContent(TEMPPLATETYPE.CLINICIANSTATUS);
                    const templateContent = clinicianTemplate[0].content;
                    const updatedEmailContent = templateContent.replace(/%%FirstName%%/g, firstName)
                        .replace(/%%LastName%%/g, lastName);
                    await sendEmail(email, clinicianTemplate[0].subject, updatedEmailContent, clinicianTemplate[0].bcc);
                } else {
                    await setRoleData(uid.uid, role);
                }
                const stateRef = collection(userProfileDocRef, DATABASECOLLECTION.STATE);
                const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
                await getDoc(currentDocRef);
                setSuccessMsg('User updated successfully!');
            } catch (error) {
                if (error.code === 'auth/email-already-in-use') {
                    setErrorMsg('This email is already in use. Please use a different email.');
                } else {
                    setErrorMsg('An error occurred during registration. Please try again.');
                }
            }
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setErrorMsg('This email is already in use. Please use a different email.');
            } else {
                setErrorMsg('An error occurred during registration. Please try again.');
            }
        }
    }
    useEffect(() => {
        const getUserDetails = async () => {
            const userCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
            const userDocRef = doc(userCollection, uid.uid);
            const userDocumentSnapshot = await getDoc(userDocRef);
            if (userDocumentSnapshot.data()) {
                const userDetails = userDocumentSnapshot.data();
                const stateRef = collection(userDocRef, DATABASECOLLECTION.STATE);
                const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
                const currentDocSnap = await getDoc(currentDocRef);
                const getUserRole = await getData(userDetails.emailAddress);
                console.log({ getUserRole })
                userDetails.status = currentDocSnap.data();
                userDetails.role = getUserRole?.customClaims?.role;
                const statusUpdated = userDetails?.isActive === true ? 'true' : 'false';
                var onboardCompleteUpdated;
                if (userDetails.role === ROLES.CLINICIANINCLINIC || userDetails.role === ROLES.CLINICIANINHOUSE) {
                    onboardCompleteUpdated = userDetails.status?.onboardComplete === true ? userDetails.role === ROLES.CLINICIANINCLINIC ? 'approvedinclinic' : 'approvedinhouse' : 'pending';
                } else {
                    onboardCompleteUpdated = userDetails.status?.onboardComplete === true ? 'approved' : 'pending';
                }
                setRole(getUserRole?.customClaims?.role);
                setFirstName(userDetails.firstName);
                setLastName(userDetails.lastName);
                setEmail(userDetails.emailAddress);
                setState(userDetails.state);
                setCountry(userDetails.country);
                setPhone(userDetails.phone);
                setQualifications(userDetails.qualifications);
                setMessage(userDetails.message);
                setStatus(statusUpdated);
                setOnboardComplete(onboardCompleteUpdated);
                setPreviousStatus(onboardCompleteUpdated);
                setData(userDetails);
                setIsLoading(false);
            }
        };
        getUserDetails();
    }, []);

    const deleteHandler = async () => {
        try {

            const res = await deleteUser(uid.uid) //disable authentication of user 
            if (res) {
                const userCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
                const userDocRef = doc(userCollection, uid.uid);

                const userDocumentSnapshot = await getDoc(userDocRef);

                const otherSettingsCollectionRef = collection(userDocRef, DATABASECOLLECTION.OTHERSETTINGS);

                if (role === ROLES.CLIENT || role === undefined) {

                    const clinicianIDUpdateDocRef = doc(otherSettingsCollectionRef, DATABASECOLLECTION.ACTIVATIONCODE);
                    const activationCodeGet = await getDoc(clinicianIDUpdateDocRef);

                    if (activationCodeGet.data()) {

                        const activationCodeGetData = activationCodeGet.data();
                        const previousClinicianUID = activationCodeGetData.clinicianUID.path?.trim();

                        const [previousClinicianCollection, previousClinicianDocID] = previousClinicianUID.split('/');
                        const previousClinicianDocRef = doc(collection(db, previousClinicianCollection), previousClinicianDocID?.trim());
                        const previousClinicianCollectionRef = collection(previousClinicianDocRef, DATABASECOLLECTION.OTHERSETTINGS);
                        const previousClinicianUpdateDocRef = doc(previousClinicianCollectionRef, 'clientUID');
                        const previousClinicianGet = await getDoc(previousClinicianUpdateDocRef);
                        const previousClinicianArr = previousClinicianGet?.data()?.clientUIDs;

                        const updatedArray = previousClinicianArr.filter(prevClc => `${DATABASECOLLECTION.USERPROFILES}/${uid.uid}` !== prevClc.path);

                        await updateDoc(previousClinicianUpdateDocRef, { clientUIDs: updatedArray }); // update client UID from previous Clinician
                    }
                }

                // deleted UIDs ----->
                const deletedCollections = collection(db, "deletedUIDs");
                const deletedUIDDocRef = doc(deletedCollections, uid.uid);
                await setDoc(deletedUIDDocRef, {});

                if (userDocumentSnapshot.data()) {
                    const userDetails = userDocumentSnapshot.data();
                    // adding user in deleteAccounts
                    // await setDoc(doc(collection(db, DATABASECOLLECTION.DELETED_ACCOUNTS), uid.uid), userDetails); //set the details in deleteAccounts

                    //    const deleteDocRef =  doc(db, DATABASECOLLECTION.DELETED_ACCOUNTS, uid.uid); //deletedAccounts document ref

                    //    await setDoc(deleteDocRef, {}); 

                    //    const userProfileSubCollectionRef = collection(deleteDocRef, "userProfiles") // creating userProfiles collection in deletedDocRef

                    //    const userProfileDocRefD = doc(userProfileSubCollectionRef, uid.uid); // creating new doc in the userProfiles (deletedAccounts)

                    //    await setDoc(userProfileDocRefD,userDetails); // setting the data from userProflies in to the deletedAccounts -> userProfiles




                    // if(role === ROLES.CLINICIAN || role === ROLES.CLINICIANINCLINIC || role === ROLES.CLINICIANINHOUSE || role === ROLES.CLINICIANPENDING){

                    //     // const collectionRef = collection(db, `${DATABASECOLLECTION.USERPROFILES}/${uid.uid}/Test`)


                    //     const querySnapshot = await getDocs(otherSettingsCollectionRef);// userProfiles otherSettings snapShot


                    //     if(!querySnapshot.empty){//checking if otherSettings is empty or not 

                    //          const otherSettingsRef = collection(userProfileDocRefD, DATABASECOLLECTION.OTHERSETTINGS); // creating the otherSettings collection in userProfile (deletedAccounts)

                    //          const clientUIDRef  = doc(otherSettingsRef, "clientUID");

                    //          await setDoc(clientUIDRef,{clientUIDs:[]}); // setting the clientUIDs arr = [] in deletedAccounts

                    //          //delete the clientUID doc here -----> 

                    //     }


                    // const userProfileState = collection(userDocRef, DATABASECOLLECTION.STATE);//userProfile state ref

                    // const userProfileStateSnapshot = await getDocs(userProfileState);// userProfiles state snapShot

                    // if(!userProfileStateSnapshot.empty){

                    //     const documents = userProfileStateSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));//get all the documents 

                    //     const deletedAccountsStateRef = collection(userProfileDocRefD,DATABASECOLLECTION.STATE); // creating the state collection in userProfile (deletedAccounts)

                    //     documents.forEach(async (item)=>{

                    //         const appDocRef  = doc(deletedAccountsStateRef, item.id); // creating a new document in the state (deleted accounts)

                    //         delete item.id; 

                    //         await setDoc(appDocRef,{...item}); // setting the app data 

                    //         // delete the docs here ----->

                    //     });
                    // }


                    //---------> userEvents code 





                    // }

                    // await deleteDoc(userDocRef); /// delete the account from userProfile
                    // Swal.fire('Deleted!', 'User Delete Successfully', 'success');
                    setDeleteModal(false)
                }

                setSuccessMsg("User Deleted Successfully ")
                navigate(ADMIN_ROUTES.MANAGE_USERS_URL)
            }
        } catch (error) {
            console.log({ error })
            // Swal.fire('Error!', 'Something went wrong, Try again', 'success');
            setErrorMsg("Error in deleting user, Try again")

        } finally {
            setDeleteModal(false)

        }

    }
    const onClickDelete = async () => {
        if (role === ROLES.CLINICIAN || role === ROLES.CLINICIANINCLINIC || role === ROLES.CLINICIANINHOUSE) {
            const userProfileClinicianDocRef = doc(collection(db, DATABASECOLLECTION.USERPROFILES), uid.uid);
            const otherSettingsClinicianCollectionRef = collection(userProfileClinicianDocRef, DATABASECOLLECTION.OTHERSETTINGS);
            const clientUIDDocRef = doc(otherSettingsClinicianCollectionRef, 'clientUID');
            const clientUIDDocSnapshot = await getDoc(clientUIDDocRef);
            const clientUIDLength = clientUIDDocSnapshot.data()?.clientUIDs.length;

            if (clientUIDLength) {
                Swal.fire("Unable to delete clinician", `Clinician has ${clientUIDLength} clients.  Only clinicians without clients can be deleted.  Re-assign all of clinician's clients before deleting this clinician`)
                return
            }
            setErrorMsg("")
            setSuccessMsg("")
            setDeleteModal(true)
        } else {
            setErrorMsg("")
            setSuccessMsg("")
            setDeleteModal(true)

        }
    }
    return (
        <>
            <div className="dashboardWrapper">
                <AdimnSidebar />
                <div className="adminDashBoard">
                    <AdminHeader />
                    <div className="adminBody">
                        <div className="registerUser commonCard">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-header ">
                                        <div className="SectionHeadingMain">
                                            Edit User
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="MainForm">
                                        {errorMsg && (
                                            <Alert text={errorMsg} type="error" />
                                        )}
                                        {successMsg && (
                                            <Alert text={successMsg} type="success" />
                                        )}
                                        {isLoading && <Loader />}
                                        {!isLoading && (
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text"
                                                            className="form-control shadow-none border"
                                                            id="firstName"
                                                            name="firstName"
                                                            placeholder=""
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                        />
                                                        <label htmlFor="firstName" className='formControlLable'>
                                                            <div className="labelTxt">
                                                                First Name
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none border"
                                                            id="lastname"
                                                            name="lastname"
                                                            placeholder=""
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                        />
                                                        <label htmlFor="lastname" className='formControlLable'>
                                                            <div className="labelTxt">
                                                                Last Name
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="email"
                                                            className="form-control shadow-none border"
                                                            id="email"
                                                            name="email"
                                                            placeholder="name@example.com"
                                                            value={email}
                                                            readOnly
                                                        />
                                                        <label htmlFor="email" className='formControlLable'>
                                                            <div className="labelTxt">
                                                                Email
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="tel"
                                                            className="form-control shadow-none border"
                                                            id="phone"
                                                            name="phone"
                                                            placeholder=""
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                        />
                                                        <label htmlFor="phone" className='formControlLable'>
                                                            <div className="labelTxt">
                                                                Phone Number
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3 dropdown-container">
                                                        <select
                                                            className="form-control shadow-none border"
                                                            name="isActive"
                                                            onChange={(event) => setStatus(event.target.value)}
                                                            value={status}
                                                        >
                                                            <option value="false">No</option>
                                                            <option value="true">Yes</option>
                                                        </select>
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Is Active?
                                                            </div>
                                                        </label>
                                                        <div className="dropdown-icon">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3 dropdown-container">
                                                        <select
                                                            className="form-control shadow-none border"
                                                            name="role"
                                                            onChange={(event) => setRole(event.target.value)}
                                                            value={role ? role : data.role}
                                                        >
                                                            {(role === ROLES.CLINICIANPENDING)
                                                                && (
                                                                    <option value={ROLES.CLINICIANPENDING}>{ROLES.CLINICIANPENDING}</option>
                                                                )}
                                                            {(role === ROLES.CLINICIANINCLINIC || role === ROLES.CLINICIAN || role === ROLES.CLINICIANINHOUSE || role === ROLES.CLINICIANPENDING)
                                                                && (
                                                                    <>
                                                                        <option value={ROLES.CLINICIANINCLINIC}>{ROLES.CLINICIANINCLINIC}</option>
                                                                        <option value={ROLES.CLINICIANINHOUSE}>{ROLES.CLINICIANINHOUSE}</option>
                                                                    </>
                                                                )}
                                                            <option value={ROLES.CLIENT}>{ROLES.CLIENT}</option>
                                                            <option value={ROLES.ADMIN}>{ROLES.ADMIN}</option>

                                                        </select>
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Role
                                                            </div>
                                                        </label>
                                                        <div className="dropdown-icon">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                {role && role !== ROLES.CLIENT && (
                                                    <>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-floating mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control shadow-none border"
                                                                    id="qualifications"
                                                                    name="qualifications"
                                                                    placeholder=""
                                                                    value={qualifications}
                                                                    onChange={(e) => setQualifications(e.target.value)}
                                                                />
                                                                <label htmlFor="qualifications" className='formControlLable'>
                                                                    <div className="labelTxt">
                                                                        Qualifications
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-floating mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control shadow-none border"
                                                                    id="state"
                                                                    name="state"
                                                                    placeholder=""
                                                                    value={state}
                                                                    onChange={(e) => setState(e.target.value)}
                                                                />
                                                                <label htmlFor="state" className='formControlLable'>
                                                                    <div className="labelTxt">
                                                                        State/province
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-floating mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control shadow-none border"
                                                                    id="country"
                                                                    name="country"
                                                                    placeholder=""
                                                                    value={country}
                                                                    onChange={(e) => setCountry(e.target.value)}
                                                                />
                                                                <label htmlFor="country" className='formControlLable'>
                                                                    <div className="labelTxt">
                                                                        Country
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className={`col-12 ${role && role !== ROLES.CLIENT ? 'col-sm-6' : 'col-sm-12'}`}>
                                                    <div className="form-floating mb-3 dropdown-container">
                                                        <select
                                                            className="form-control shadow-none border"
                                                            name="onboardComplete"
                                                            value={onboardComplete}
                                                            onChange={(event) => setOnboardComplete(event.target.value)}
                                                        >
                                                            {/* <option value="">Choose</option> */}
                                                            <option value="pending">Pending</option>
                                                            {/* {(role !== ROLES.CLINICIANINCLINIC || role !== ROLES.CLINICIANINHOUSE) ? (
                                                                <option value="approved">Approved</option>
                                                            ) :
                                                                (
                                                                    <> */}
                                                            <option value="approvedinclinic">Approved (in-clinic)</option>
                                                            <option value="approvedinhouse">Approved (in-house)</option>
                                                            {/* </>
                                                                )} */}

                                                        </select>
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Status
                                                            </div>
                                                        </label>
                                                        <div className="dropdown-icon">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                {role && role !== ROLES.CLIENT && (
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-floating mb-3 ">
                                                            <textarea
                                                                className="form-control formcontroltxtCustom border shadow-none"
                                                                rows="3" placeholder="Leave a comment here"
                                                                name="message"
                                                                id="message"
                                                                value={message}
                                                                onChange={(e) => setMessage(e.target.value)}
                                                            ></textarea>
                                                            <label htmlFor="message">
                                                                <div className="labelTxt">
                                                                    Message  (Optional)
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-4 col-md-3">
                                                    <div className="signInButton mt-3 mt-md-4 ">
                                                        <button className='signinBtn' onClick={handleUpdate}>Update</button>
                                                    </div>

                                                </div>
                                                {
                                                    userRole === ROLES.ADMIN ? (
                                                        <div className="col-4 col-md-3">
                                                            <div className="signInButton mt-3 mt-md-4 ">
                                                                <button
                                                                    className='signinBtn bg-danger'
                                                                    onClick={onClickDelete}
                                                                // onClick={() => {
                                                                //     setErrorMsg("")
                                                                //     setSuccessMsg("")
                                                                //     setDeleteModal(true)
                                                                // }}
                                                                >
                                                                    Delete User
                                                                </button>
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteUserModal
                show={deleteModal}
                setShow={setDeleteModal}
                handleClose={handleClose}
                firstName={data?.firstName}
                lastName={data?.lastName}
                emailAddress={data?.emailAddress}
                deleteHandler={deleteHandler}
            // deleteHandler={() => console.log("deleting")}
            />
        </>
    )
}

export default EditUser


const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

function DeleteUserModal({ show, setShow, handleClose, firstName, lastName, emailAddress, deleteHandler }) {

    const [email, setEmail] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const timerOutRef = useRef(null)


    useEffect(() => {
        timerOutRef.current = setTimeout(() => {
            checkEmail()
        }, 1000)

    }, [email])

    const checkEmail = () => {
        if (email) {
            const adminMail = localStorage.getItem('login_email')
            if (!isValidEmail(email)) {
                setErrorMsg('Entered invalid email address');
                return false;
            }
            if (adminMail !== email) {
                setErrorMsg("Email doesn't matches");
                return
            }
            setErrorMsg("");
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMsg && (
                        <Alert text={errorMsg} type="error" />
                    )}
                    {successMsg && (
                        <Alert text={successMsg} type="success" />
                    )}

                    <div className='mb-3'>
                        You are requesting to delete the following account:<strong className='font-weight-bold'> {firstName}</strong> <strong> {lastName}</strong>
                        <strong> {" "}{emailAddress}{" "} </strong>. Confirm you wish to permanently delete this account by typing in  your email address and clicking Confirm
                    </div>
                    <div>
                        {/* <input type="text"  placeholder='place your mail'/> */}
                        <div className="form-floating ">
                            <input
                                type="text"
                                className="form-control shadow-none border"
                                id="adminEmail"
                                name="Email"
                                placeholder=""
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label htmlFor="adminEmail" className='formControlLable'>
                                <div className="labelTxt">
                                    Email
                                </div>
                            </label>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer >
                    <div className="signInButton mt-1 mt-md-2 ">
                        <button className='signinBtn' onClick={handleClose} >Close</button>
                    </div>

                    <div className="signInButton mt-1 mt-md-2 ">
                        <button className='signinBtn bg-danger'
                            onClick={() => deleteHandler()}
                        // onClick={() => console.log("frr")}

                        >Confirm</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}