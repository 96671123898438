import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/effect-fade';
import "swiper/css";
import 'swiper/css/free-mode';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { FRONTEND_ROUTES } from '../../../constants/RouteConst';

const TestimonialComponent = ({ TestimonialData, dir }) => {
    return (
        <>
            {/* <Swiper
                // slidesPerView={1}
                slidesPerView={'auto'}
                spaceBetween={20}
                loop={true}
                // freeMode={true}
                dir={dir}
                // centeredSlides= {true}
                speed={9000}
                allowTouchMove={false}
                autoplay={{
                    delay: 0,
                    enabled: true,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                modules={[FreeMode, Autoplay]}
                breakpoints={{
                    360: {
                        slidesPerView: 1.1,
                    },
                    576: {
                        slidesPerView: 1.5,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 2.5,
                    },
                    1200: {
                        slidesPerView: 2.5,
                    },
                    1400: {
                        slidesPerView: 2.5,
                    },
                }}
                modules={[FreeMode, Autoplay]}
                className="mySwiper testimonialsSwiper"
            >
                {
                    TestimonialData && TestimonialData.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="testimonialWrapper h-100">
                                <div className="row flex-column h-100">
                                    <div className="col-12">
                                        <div className="stars">
                                            <div className="userName"><Link to={FRONTEND_ROUTES.BLOG_DETAILS + '/' + item.slug} className="link">{item.title}</Link></div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center p-2">
                                        <div className="para flex-fill h-100 ">{item.quote}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-auto">
                                        <div className="row mx-0">
                                            <div className="col-auto px-0"><div className="user"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" aria-hidden="true" height="50px" width="50px" style={{ color: '#2c2c2c' }} xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd"></path></svg></div></div>
                                            <div className="col">
                                                <div className="userName">{item.name}</div>
                                                <div className="designation">{item.role}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper> */}

            <div className='row testimonialFluid mx-0 mt-4 overflow-hidden'>
                <div className='d-flex align-items-center flex-nowrap gap-3 overflow-hidden maintesti '>
                    <div className='w-auto d-flex align-items-center overflow-hidden gap-3 p-0 mainsectiontesti'>
                        {
                            TestimonialData && TestimonialData.map((item, index) => (
                                <div className='testimonialWrapper h-100' key={index}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="stars">
                                                <div className="userName">
                                                    <Link to={FRONTEND_ROUTES.BLOG_DETAILS + '/' + item.slug} className="link">{item.title}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center align-items-center p-2">
                                            <div className="para flex-fill h-100 ">
                                                {item.quote}
                                            </div>
                                        </div>
                                        <div className="col-12 mt-auto">
                                            <div className="row mx-0 align-items-center">
                                                <div className="col-auto px-0">
                                                    <div className="user">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" aria-hidden="true" height="50px" width="50px" style={{ color: '#2c2c2c' }} xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd"></path></svg>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="userName">
                                                        {item.name}
                                                    </div>
                                                    <div className="designation">
                                                        {item.role}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='w-auto d-flex align-items-center overflow-hidden gap-3 p-0 mainsectiontesti'>
                        {
                            TestimonialData && TestimonialData.map((item, index) => (
                                <div className='testimonialWrapper h-100' key={index}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="stars">
                                                <div className="userName">
                                                    <Link to={FRONTEND_ROUTES.BLOG_DETAILS + '/' + item.slug} className="link">{item.title}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center align-items-center p-2">
                                            <div className="para flex-fill h-100 ">
                                                {item.quote}
                                            </div>
                                        </div>
                                        <div className="col-12 mt-auto">
                                            <div className="row mx-0 align-items-center">
                                                <div className="col-auto px-0">
                                                    <div className="user">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" aria-hidden="true" height="50px" width="50px" style={{ color: '#2c2c2c' }} xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd"></path></svg>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="userName">
                                                        {item.name}
                                                    </div>
                                                    <div className="designation">
                                                        {item.role}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestimonialComponent