import React, { useEffect, useState } from "react";
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import { db } from '../../common/firebase';
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, where } from 'firebase/firestore';
import Alert from '../../components/alert/Alert';
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";

const AddCoupon = () => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [allClient, setAllClient] = useState();
    const [couponCode, setCouponCode] = useState();
    const [owner, setOwner] = useState();
    const [value, setValue] = useState();
    const [type, setType] = useState('personal');
    const [description, setDescription] = useState();
    const [isVisible, setIsVisible] = useState('true');
    const [expirationDate, setExpirationDate] = useState();

    useEffect(() => {
        const getAllClients = async () => {
            const activationCollection = collection(db, DATABASECOLLECTION.ACTIVATIONCODES);
            const activationQuery = query(activationCollection, where('isUsed', '==', false));
            const activationDocsSnapshot = await getDocs(activationQuery);
            const clientUIDDataArray = [];
            for (const activationDoc of activationDocsSnapshot.docs) {
                const clientUIDReference = activationDoc.data().clientUID;
                const clientUIDDoc = await getDoc(clientUIDReference);
                if (clientUIDDoc.exists()) {
                    const clientUIDData = clientUIDDoc.data();
                    clientUIDData.uid = clientUIDDoc.id;
                    clientUIDDataArray.push(clientUIDData);
                }
            }
            setAllClient(clientUIDDataArray);
        };
        getAllClients();
    }, []);

    const handleAdd = async () => {
        try {
            setErrorMsg('');
            setSuccessMsg('');
            if (!couponCode || !owner || !value || !type || !description || !isVisible || !expirationDate) {
                setErrorMsg('Please fill out all the required fields.');
                return;
            }

            const currentDate = new Date();
            const selectedExpirationDate = new Date(expirationDate);

            if (selectedExpirationDate < currentDate) {
                setErrorMsg('Please select an expiration date in the future.');
                return;
            }

            const expirationTimestamp = expirationDate ? Timestamp.fromDate(new Date(expirationDate)) : null;
            await addDoc(collection(db, DATABASECOLLECTION.COUPONS), {
                couponCode: couponCode,
                owner: doc(db, owner),
                value: value,
                type: type,
                description: description,
                isVisible: isVisible,
                createdDate: serverTimestamp(),
                expirationDate: expirationTimestamp,
                createdBy: doc(db, `${DATABASECOLLECTION.USERPROFILES}/` + localStorage.getItem('docid')),
            });
            setCouponCode('');
            setOwner('');
            setValue('');
            setType('');
            setDescription('');
            setIsVisible('');
            setExpirationDate('');
            setSuccessMsg('New Coupon added successfully!');
        } catch (error) {
            setErrorMsg('Please fill out all the values.');
        }
    };

    return (
        <>
            <div className="dashboardWrapper">
                <AdimnSidebar />
                <div className="adminDashBoard">
                    <AdminHeader />
                    <div className="adminBody">
                        <div className="registerUser commonCard">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-header ">
                                        <div className="SectionHeadingMain">
                                            Add Coupon
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {errorMsg && (
                                        <Alert text={errorMsg} type="error" />
                                    )}
                                    {successMsg && (
                                        <Alert text={successMsg} type="success" />
                                    )}
                                    <div className="card-body pt-3">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control shadow-none border formcontrolCustom controlRounded" placeholder="" onChange={(event) => setCouponCode(event.target.value)} value={couponCode} />
                                                    <label htmlFor="floatingInput" className="formControlLable">
                                                        <div className="labelTxt">
                                                            Coupons Code
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-floating mb-3 dropdown-container">
                                                    <select
                                                        className="form-control shadow-none border formcontrolCustom controlRounded"
                                                        name="onboardComplete"
                                                        value={owner}
                                                        onChange={(event) => setOwner(event.target.value)}
                                                    >
                                                        <option value="">--Choose--</option>
                                                        {allClient && allClient.map((item) => (
                                                            <option
                                                                key={item.uid}
                                                                value={`${DATABASECOLLECTION.USERPROFILES}/` + item.uid}
                                                            >
                                                                {item.firstName + ' ' + item.lastName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="floatingInput" className="formControlLable">
                                                        <div className="labelTxt">
                                                            Owner
                                                        </div>
                                                    </label>
                                                    <div className="dropdown-icon">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control shadow-none border formcontrolCustom controlRounded" onChange={(event) => setValue(event.target.value)} value={value} placeholder="" />
                                                    <label htmlFor="floatingInput" className="formControlLable">
                                                        <div className="labelTxt">
                                                            Value
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <div className="form-floating mb-3 dropdown-container">
                                                    <select
                                                        className="form-control shadow-none border formcontrolCustom controlRounded"
                                                        name="isVisible"
                                                        onChange={(event) => setIsVisible(event.target.value)} value={isVisible}
                                                    >
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                    <label htmlFor="floatingInput" className="formControlLable">
                                                        <div className="labelTxt">
                                                            Is Visible?
                                                        </div>
                                                    </label>
                                                    <div className="dropdown-icon">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <div className="form-floating mb-3 dropdown-container">
                                                    <select
                                                        className="form-control shadow-none border formcontrolCustom controlRounded"
                                                        name="type"
                                                        onChange={(event) => setType(event.target.value)} value={type}
                                                    >
                                                        <option value="personal">Personal</option>
                                                        <option value="organisation">Organisation</option>
                                                        <option value="global">Global</option>
                                                    </select>
                                                    <label htmlFor="floatingInput" className="formControlLable">
                                                        <div className="labelTxt">
                                                            Type
                                                        </div>
                                                    </label>
                                                    <div className="dropdown-icon">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <div className="form-floating mb-3">
                                                    <input type="datetime-local"
                                                        className="form-control shadow-none border formcontrolCustom controlRounded" onChange={(event) => setExpirationDate(event.target.value)} value={expirationDate} placeholder="" />
                                                    <label htmlFor="floatingInput" className="formControlLable">
                                                        <div className="labelTxt">
                                                            Expiry Date
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <div className="form-floating mb-3">
                                                    <textarea className="form-control shadow-none border formcontrolCustom controlRounded" onChange={(event) => setDescription(event.target.value)} value={description} placeholder="" cols="3"></textarea>
                                                    <label htmlFor="floatingInput" className="formControlLable">
                                                        <div className="labelTxt">
                                                            Description
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 col-sm-6 col-md-2 col-xl-2 mx-auto mx-sm-0">
                                    <div className="signInButton mt-md-3 text-center">
                                        <button className='signinBtn rounded-2' type='btn' onClick={handleAdd}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default AddCoupon