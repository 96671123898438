import React, { useEffect, useState } from 'react'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { db } from '../../common/firebase';
import Alert from '../../components/alert/Alert';
import 'react-quill/dist/quill.snow.css';
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';
import { PAYMENTS } from '../../constants/PaymentConst';

const EditRedirect = () => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [url, setURL] = useState("");
    const [uid, setUid] = useState("");

    const handleUpdate = async () => {
        try {
            setSuccessMsg('');
            setErrorMsg('');
            const validationRules = [
                { field: url, message: 'The url field must not be left blank.' },
            ];
            for (const rule of validationRules) {
                if (!rule.field) {
                    setErrorMsg(rule.message);  
                    return false;
                }
            }
            try {
                const userProfileDocRef = doc(collection(db, DATABASECOLLECTION.PAYMENTREDIRECTION), uid);
                const updateData = {
                    redirectURL: url,
                    updated_at: serverTimestamp(),
                };

                await updateDoc(userProfileDocRef, updateData);
                setSuccessMsg('Payment URL updated successfully!');
            } catch (error) {
                console.log(error);
                setErrorMsg('Something went wrong!. Please try again.');
            }
        } catch (error) {
            console.log(error);
            setErrorMsg('Something went wrong!. Please try again.');
        }
    }

    useEffect(() => {
        const getPaymentRedirect = async () => {
            const templateCollection = collection(db, DATABASECOLLECTION.PAYMENTREDIRECTION);
            const querySnapshot = await getDocs(query(templateCollection, where("type", "==", PAYMENTS.INCLINIC)));

            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const templateDetails = doc.data();
                    setURL(templateDetails.redirectURL);
                    setUid(doc.id);
                });
            } else {
                console.log("No documents found with type 'Neuromonics (in-clinic)'.");
            }

        };
        getPaymentRedirect();
    }, []);

    return (
        <>
            <div className="dashboardWrapper">
                <AdimnSidebar />
                <div className="adminDashBoard">
                    <AdminHeader />
                    <div className="adminBody">
                        <div className="registerUser commonCard">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-header ">
                                        <div className="SectionHeadingMain">
                                            Update {PAYMENTS.INCLINIC} URL
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="MainForm">
                                        {errorMsg && (
                                            <Alert text={errorMsg} type="error" />
                                        )}
                                        {successMsg && (
                                            <Alert text={successMsg} type="success" />
                                        )}
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <div className="form-floating mb-3">
                                                    <input type="text"
                                                        className="form-control shadow-none border"
                                                        id="url"
                                                        name="url"
                                                        placeholder=""
                                                        value={url}
                                                        onChange={(e) => setURL(e.target.value)}
                                                    />
                                                    <label htmlFor="subject" className='formControlLable'>
                                                        <div className="labelTxt">
                                                            URL
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="signInButton mt-3 mt-md-4">
                                                    <button className='signinBtn' onClick={handleUpdate}>Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditRedirect