import React, { useState } from 'react';

const MAX_VOLUME = 100;
const MINUTES_IN_DAY = 24 * 60;

function hashTrackToColor(track) {
    let baseRed = 100;
    let baseGreen = 200;
    let baseBlue = 50;
    let red = (baseRed + track * 70) % 256;
    let green = (baseGreen + track * 130) % 256;
    let blue = (baseBlue + track * 190) % 256;
    return '#' + red.toString(16).padStart(2, '0') + green.toString(16).padStart(2, '0') + blue.toString(16).padStart(2, '0');
}

const CreateDaysEvents = ({ events, dateString }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    var isFirstEvent = true;
    const eventRows = [];
    const eventTypes = {};
    if(events.length === 0){
        return (
            <div className='event-row' key={dateString} >
                <div className='event-date'>{dateString}</div>
                <div className="event-container">
                    <div className="event-stats">
                        <svg stroke="currentColor" style={{ color: 'red' }} fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>&nbsp;&nbsp;&nbsp;0 mins
                    </div>
                    <div className="customHGraph">
                        <div className="timeline-label left"><div className="word">midnight</div></div>
                        <div className="timeline-label center"><div className="word">midday</div></div>
                        <div className="timeline-label right"><div className="word">midnight</div></div>
                        <div
                            key={dateString}
                            className="usage-event"
                        >
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    events.forEach((event) => {
        const eventType = event.type;
        if (!eventTypes[eventType]) eventTypes[eventType] = [];
        eventTypes[eventType].push(event);
    });

    for (let eventType in eventTypes) {
        const events = eventTypes[eventType];
        // const eventElements = eventsOfType.forEach((event, index) => {
        if (eventType === 'characterisation') {
            // const answers = events[0].answers.join(", ");
            const eventRow = (
                <div className='event-row' key={events[0].start}>
                    <div className='event-date'>{(isFirstEvent) ? events[0].start.substring(0, 10) : '-'}</div>
                    <div className='event-container'>
                        <div className='event-stats'>Characteriation</div>
                        <div className="container non-usage-event">{events[0].answers}</div>
                    </div>
                </div>
            );
            eventRows.push(eventRow);
        }
        else if (eventType === 'TRQ') {
            const score = events[0].answers.reduce((acc, curr) => acc + curr, 0);
            const eventRow = (
                <div className='event-row' key={events[0].start} >
                    <div className='event-date'>{(isFirstEvent) ? events[0].start.substring(0, 10) : '-'}</div>
                    <div className='event-container'>
                        <div className='event-stats'>TRQ</div>
                        <div className="container non-usage-event">TRQ score = {score} / 100</div>
                    </div>
                </div>
            );
            eventRows.push(eventRow);
        }
        else if (eventType === 'audiogram') {
            const frequencies = ["500", "750", "1k", "2k", "3k", "4k", "6k", "8k", "10k", "12.5k"];
            const left = events[0].left.map((value, index) => <td key={index} className="left">{value}</td>);
            const right = events[0].right.map((value, index) => <td key={index} className="right">{value}</td>);
            const leftFrequencies = frequencies.map((frequency, index) => <td key={index}>{frequency}</td>);
            const rightFrequencies = frequencies.map((frequency, index) => <td key={index}>{frequency}</td>);
            const eventRow = (
                <div className='event-row' key={events[0].start} >
                    <div className='event-date'>{(isFirstEvent) ? events[0].start.substring(0, 10) : '-'}</div>
                    <div className='event-container'>
                        <div className='event-stats'>Audiogram</div>
                        <div className="customHGraph non-usage-event audiogram-event">
                            <table className="audiogram">
                                <tr className="values">
                                    {left}
                                    {right}
                                </tr>
                                <tr className="frequencies">
                                    {leftFrequencies}
                                    {rightFrequencies}
                                </tr>
                                <tr>
                                    <td colSpan="10" className="left labeltext">Left</td>
                                    <td className="spacer"></td>
                                    <td colSpan="10" className="right labeltext">Right</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            );
            eventRows.push(eventRow);
        }
        else if (eventType === 'usage') {
            let totalTime = 0;
            const eventElements = [];
            let index = 0;
            for (const key in events) {
                if (Object.hasOwnProperty.call(events, key)) {
                    const eventData = events[key];
                    const startTime = new Date(eventData.start);
                    const endTime = new Date(eventData.finish);
                    const duration = (endTime - startTime) / (1000 * 60);
                    totalTime += duration;
                    const startPos = (startTime.getHours() * 60 + startTime.getMinutes()) / MINUTES_IN_DAY * 100;
                    const eventWidth = (duration / MINUTES_IN_DAY) * 100;
                    let height = (Math.log(1 + eventData.volume / MAX_VOLUME) / Math.log(2)) * 200;
                    if (height < 10) {
                        height = 1;
                    }
                    height = `${height}%`;                    
                    const width = `${eventWidth }%`;
                    const left = `${startPos}%`;
                    const color = hashTrackToColor(eventData.track);
                    const eventElement = (
                        <div
                            key={key}
                            className="usage-event"
                            style={{ height, width, left, backgroundColor: color }}
                            onMouseEnter={() => setHoveredIndex(key)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            {hoveredIndex === key && (
                                <div id={`neural-stimulus2-range-tooltip-${index}`} className="tooltip">
                                    <div className='toptipSection'>
                                        <span className='toltips'>Song: {eventData.title}</span>
                                        <span> Duration: {Math.ceil(duration)} min</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                    eventElements.push(eventElement);
                    index++;
                }
            }

            const eventStats = totalTime < 120 ? (
                <div className="event-stats">
                    <svg stroke="currentColor" style={{ color: 'red' }} fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>&nbsp;&nbsp;&nbsp;{Math.ceil(totalTime)} mins
                </div>
            ) : (
                <div className="event-stats">
                    <svg stroke="currentColor" style={{ color: 'green' }} fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                        &nbsp;&nbsp;&nbsp;{Math.ceil(totalTime)} mins
                </div>
            );

            const eventRow = (
                <div className='event-row' key={events[0].start} >
                    <div className='event-date'>{(isFirstEvent) ? events[0].start.substring(0, 10) : '-'}</div>
                    <div className="event-container">
                        {eventStats}
                        <div className="customHGraph">
                            <div className="timeline-label left"><div className="word">midnight</div></div>
                            <div className="timeline-label center"><div className="word">midday</div></div>
                            <div className="timeline-label right"><div className="word">midnight</div></div>
                            {eventElements}
                        </div>
                    </div>
                </div>
            );
            eventRows.push(eventRow);

        }else{
            const eventRow = (
                <div className='event-row' key={events[0].start}>
                    <div className='event-date'>{(isFirstEvent) ? events[0].start.substring(0, 10) : '-'}</div>
                    <div className='event-container'>
                        <div className='event-stats'>No Data</div>
                    </div>
                </div>
            );
            eventRows.push(eventRow);
        }
        isFirstEvent = false;
    }
    return eventRows;
};

const EventGraph = ({eventData=[],nextData}) => {
    let events = [];
    const formatDate = (seconds) => {
        const date = new Date(seconds * 1000);
        return date.toISOString();
    };
    eventData.forEach(ev => {
        if(ev.type === 'trq'){
            const answers = ev.details.responses.map(response => response.value);
            const formattedData = {
                type: "TRQ",
                start: formatDate(ev.details.date.seconds),
                answers: answers.slice(0, -2),
                date:formatDate(ev.date)
            };
            events.push(formattedData);
        } 
        else if (ev.type === 'tinnitusCharactersation') {
            const formattedData = {
                type: "characterisation",
                start: formatDate(ev.details.date.seconds),
                answers: ev.details.responses.join(", "),
                date: formatDate(ev.date)
            };
            events.push(formattedData);
        }
        else if (ev.type === 'audiogram') {
            const formattedData = {
                type: "audiogram",
                start: formatDate(ev.details.date.seconds),
                left: Object.values(ev.details.audiogram.left),
                right: Object.values(ev.details.audiogram.right),
                date: formatDate(ev.date)
            };
            events.push(formattedData);
        }
        else if (ev.type === 'usage') {
            let number = 0;
            for (let i = 0; i < ev.details.songId.length; i++) {
                number += ev.details.songId.charCodeAt(i) * (i + 1);
            }
            const formattedData = {
                type: "usage",
                start: formatDate(ev.details.startTime.seconds),
                finish: formatDate(ev.details.endTime.seconds),
                volume: ev.details.endVolume*30,
                track: number,
                title:ev.details.title,
                date: formatDate(ev.date)
            };
            events.push(formattedData);
        }
    });
    let groupedEvents = {};
    events.forEach(event => {
        const datePart = event.start.split('T')[0];
        if (!groupedEvents[datePart]) {
            groupedEvents[datePart] = [];
        }
        groupedEvents[datePart].push(event);
    });

    const currentDate = new Date().toISOString().split('T')[0];

    const secondsInADay =24*60*60 

    const nextDataStartDate = nextData?.details?.startTime?.seconds ? formatDate(nextData?.details?.startTime?.seconds-secondsInADay):null;

    const startDate = Object.keys(groupedEvents).sort()[0];
    const endDate =nextDataStartDate ? nextDataStartDate :currentDate;
    // const endDate =currentDate;
    const generateDateRange = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);
        while (currentDate <= new Date(endDate)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const dateRange = generateDateRange(startDate, endDate);

    dateRange.forEach(date => {
        if (!groupedEvents[date]) {
            groupedEvents[date] = [];
        }
    });

    const sortedKeys = Object.keys(groupedEvents).sort();
    return (
        <>
            {sortedKeys.map(day => (
                <CreateDaysEvents events={groupedEvents[day]} key={day} dateString={day} />
            ))}
        </>
    );
};

export default EventGraph;