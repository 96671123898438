import React from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../constants/RouteConst';

const PaymentSuccessful = () => {
  return (
    <div>
      <div className="box">
          <div className="success custom-alert">
              <div className="alert-body">
                  Payment Successful !
              </div>
          </div>
          <div className="homeBtnheaderDiv">
            <Link to={ADMIN_ROUTES.LOGIN_URL} className='text-text-decoration-none homeBtnheaderDivanc'>Go to Dashboard</Link>
          </div>
      </div>
    </div>
  )
}

export default PaymentSuccessful;