import React, { useEffect, useState } from 'react'
import logo from '../../../assets/common/img/logo.png';
import HeaderOffcanvas from '../headerOffcanvas/HeaderOffcanvas';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTES, FRONTEND_ROUTES } from '../../../constants/RouteConst';
import { ModalBody, ModalHeader } from 'react-bootstrap';
import ReactModal from 'react-modal';
import { getAuth, signInAnonymously, signOut } from 'firebase/auth';
import { checkEmailExists, getTemplateContent } from '../../../common/helper';
import { EventType } from '../../../constants/EventConst';
import { getData, sendEmail } from '../../../common/fetch';
import Alert from '../../../components/alert/Alert';
import { createEvent } from '../../../common/helper';
import { TEMPPLATETYPE } from '../../../constants/TemplateTypeConst';
import Loader from '../../../pages/extra/Loader';

const Header = ({ scrollContainer, secondHeader }) => {

    const [scrolled, setScrolled] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [show, setShow] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [authHomeChecked, setAuthHomeChecked] = useState(false);

    // To handle Enquire Form
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [message, setMessage] = useState("");

    const handleClick = () => {
        setIsActive(!isActive);
    };

    /* Enquiry Modal */
    const openModal = () => {
        setSuccessMsg('');
        setErrorMsg('');
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    /* Enquiry Modal */

    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainer.current) {
                const scrollY = scrollContainer.current.scrollTop;
                if (scrollY > 80) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            };
        }

        if (scrollContainer.current) {
            scrollContainer.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer.current) {
                scrollContainer.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    useEffect(() => {
        const isAuthenticated = localStorage.getItem('role') !== '' && localStorage.getItem('role') !== null;
        setAuthHomeChecked(isAuthenticated);
    }, [authHomeChecked]);

    // Handle the enquire by Unauthorized or Authorized user from Public web
    const handleEnquire = async () => {
        try {
            setErrorMsg('');
            setSuccessMsg('');
            setIsLoading(true);
            const isValidEmail = (email) => {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailRegex.test(email);
            };

            const mandatoryFields = [firstName, email, message];

            for (const field of mandatoryFields) {
                if (!field) {
                    setIsLoading(false);
                    setErrorMsg('Please complete all mandatory fields (indicated by an asterisk).');
                    return;
                }
            }

            if (!isValidEmail(email)) {
                setIsLoading(false);
                setErrorMsg('Invalid email format. Please enter a valid email address.');
                return;
            }

            const auth = getAuth();
            const checkClientEmailExists = await checkEmailExists(email);
            const userId = checkClientEmailExists ? (await getData(email)).uid : (await signInAnonymously(auth)).user.uid;

            const data = {
                userId: userId,
                type: EventType.ENQUIRY,
                details: {
                    firstName: firstName,
                    emailAddress: email,
                    state: state,
                    country: country,
                    message: message,
                }
            };

            await createEvent(data); //create event for enquiry data by unauthorized user from public website

            const adminTemplate = await getTemplateContent(TEMPPLATETYPE.WEBSITEENQUIRYADMIN);
            const templateAdminContent = adminTemplate[0].content;
            const updatedEmailAdminContent = templateAdminContent.replace(/%%FirstName%%/g, firstName)
                .replace(/%%Email%%/g, email)
                .replace(/%%State%%/g, state).replace(/%%Country%%/g, country)
                .replace(/%%Message%%/g, message);

            const clientTemplate = await getTemplateContent(TEMPPLATETYPE.WEBSITEENQUIRYCLIENT);
            const templateClientContent = clientTemplate[0].content;
            const updatedEmailClientContent = templateClientContent.replace(/%%FirstName%%/g, firstName);

            await sendEmail(adminTemplate[0].recipient, adminTemplate[0].subject, updatedEmailAdminContent, adminTemplate[0].bcc);
            await sendEmail(email, clientTemplate[0].subject, updatedEmailClientContent, adminTemplate[0].bcc);

            setErrorMsg('');
            // setFirstName('');
            // setEmail('');
            // setState('');
            // setCountry('');
            // setMessage('');
            setIsLoading(false);
            setSuccessMsg('Enquiry sent successfully!');
            await signOut(auth);

        } catch (error) {
            setIsLoading(false);
            if (error.code === 'auth/email-already-in-use') {
                setErrorMsg('This email is already in use. Please use a different email.');
            } else {
                console.log(error);
                setErrorMsg('An error occurred. Please try again later.');
            }
        }
    };

    const handleShow = () => setShow(true);
    return (
        <>
            <div className={`header ${scrolled ? 'scrolled' : ''} ${secondHeader ? 'darkHeader' : ''}`}>
                <div className="container-fluid headerFluid ">
                    <div className="container px-0">
                        {
                            !secondHeader ?
                                <div className="row navWrapper d-sm-block d-none mx-0 headerDivider">
                                    <div className="col-12 d-flex justify-content-end pe-0">
                                        <ul className='navlinks navBottomBar m-0 p-p d-flex align-items-center list-unstyled'>
                                            <li className='navLi'><Link className='navAnchor navAnchorTop' to={FRONTEND_ROUTES.FINDCLINIC}>Find your nearest clinician</Link></li>
                                            <li className='navLi'><Link to="tel:+17208933700" className='navAnchor navAnchorTop'>tel:+1.720.893.3700</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                : ''
                        }
                        <div className="row align-items-center navWrapper w-100 mx-0 px-0 px-sm-3 flex-nowrap">
                            <div className="col-auto ps-0"><div className="logo"><Link to={FRONTEND_ROUTES.HOME_PAGE}><img src={logo} alt="" /></Link></div></div>
                            <div className="col d-flex justify-content-end d-lg-flex d-none">
                                <div className="row mx-0">
                                    <div className="col-12">
                                        <ul className='navlinks m-0 p-p d-flex align-items-center list-unstyled'>
                                            <li className='navLi'><Link className='navAnchor' to={FRONTEND_ROUTES.TINNITUS}>What is Tinnitus?</Link></li>
                                            <li className='navLi'><Link className='navAnchor' to={FRONTEND_ROUTES.NEUROMONICS}>Neuromonics</Link></li>
                                            <li className='navLi'><Link className='navAnchor' to={FRONTEND_ROUTES.JOURNALS}>Resources</Link></li>
                                            {/* <li className='navLi'><Link className='navAnchor' to={FRONTEND_ROUTES.ABOUT}>About</Link></li> */}
                                            <li className='navLi'><Link className='navAnchor' to={FRONTEND_ROUTES.BLOGS}>Blog</Link></li>
                                            {(authHomeChecked) ? (
                                                <li className='navLi'><Link className='navAnchor' to={ADMIN_ROUTES.LOGIN_URL}>My Account</Link></li>

                                            ) : (
                                                <li onClick={handleClick} className={`clickLi navLi  ${isActive ? 'active' : ''}`}>
                                                    <button className='navAnchor bg-transparent me-0 me-sm-2 px-3'>
                                                        Login
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.8 9.7l6.2 6.3 6.2-6.3c.2-.2.3-.5.3-.7s-.1-.5-.3-.7c-.2-.2-.4-.3-.7-.3h-11c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7s.1.5.3.7z"></path>
                                                        </svg>
                                                    </button>
                                                    <div className="dropDownWrapper">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <ul className='p-0 m-0 dropUl'>
                                                                    <li className='dropLi'>
                                                                        <Link className='dropAnchor' to={ADMIN_ROUTES.SIGNUP_URL}>Apply to become a Neuromonics Clinician</Link>
                                                                    </li>
                                                                    <li className='dropLi'>
                                                                        <Link to={ADMIN_ROUTES.LOGIN_URL} className='dropAnchor'>Login</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                            <li><Link onClick={openModal} className='navBtn d-sm-block d-none text-decoration-none'>Enquire Now</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto d-flex justify-content-end ms-auto pe-0">
                                <ul className='navlinks m-0 p-p d-flex align-items-center list-unstyled'>
                                    <li onClick={handleClick} className={`clickLi d-lg-none d-block me-3 ${isActive ? 'active' : ''}`}>
                                        <button className='navBtn me-0 me-sm-2 px-3'
                                            // className='navBtn me-0 me-sm-2 px-3'
                                            onClick={openModal}
                                        >
                                            Enquire Now
                                            {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.8 9.7l6.2 6.3 6.2-6.3c.2-.2.3-.5.3-.7s-.1-.5-.3-.7c-.2-.2-.4-.3-.7-.3h-11c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7s.1.5.3.7z"></path>
                                            </svg> */}
                                        </button>
                                        {/* <div className="dropDownWrapper">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className='p-0 m-0 dropUl'>
                                                        <li className='dropLi'><Link to={ADMIN_ROUTES.SIGNUP_URL} className='dropAnchor'>Apply to become a Neuromonics Clinician</Link></li>
                                                        <li className='dropLi'><Link to={ADMIN_ROUTES.LOGIN_URL} className='dropAnchor'>Login</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> */}
                                    </li>
                                    <li>
                                        <Link to="">
                                            <div onClick={handleShow} className="menuBar d-lg-none d-block me-3 menuColorClass"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z"></path></svg>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Your Modal"
                className="custom-modal"
                style={{
                    content: {
                        // width: '50%',
                        height: '90%',
                        margin: 'auto',
                        left: '356px',
                        marginTop: '20px',

                        backgroundColor: '#ffffff',
                        border: '1px solid',
                        overflow: "auto",
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    overlay: {
                        backgroundColor: 'rgb(255 255 255 / 88%)',
                        zIndex: "99999",
                    }
                }}
            >
                <ModalHeader style={{
                    padding: "10px",
                    background: "#517991",
                    color: "#fff",
                    position: "sticky",
                    top: "0",
                    zIndex: "100000",
                }} className='row gx-0 align-items-center justify-content-center'>
                    <div className="col-10">
                        <div className="enqnumLogo">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className="col-2"><Link className='text-light d-flex justify-content-end' onClick={closeModal} ><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "24px", width: "24px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path></svg></Link></div>

                </ModalHeader>
                <ModalBody style={{
                    overflow: "auto",
                    maxHeight: "73%%",
                    padding: "20px",
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div className="col-12">
                        <div className="enquiretxt mb-2">
                            Enquiry Form
                        </div>
                        {/* <p className="enqpara">
                            Please fill out the form below to send your inquiry.
                        </p> */}
                    </div>
                    <div className="col-12 flex-fill">
                        {errorMsg && (
                            <Alert text={errorMsg} type="error" />
                        )}
                        {successMsg && (
                            <Alert text={successMsg} type="success" />
                        )}
                        {isLoading && <Loader isEnquieySpinner={true} />}
                        {!isLoading && (
                            <div className="MainForm">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="firstName"
                                                name="firstName"
                                                placeholder=""
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            <label htmlFor="firstName" className='formControlLable'>
                                                <div className="labelTxt">
                                                    Name *
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="email"
                                                name="email"
                                                placeholder=""
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label htmlFor="email" className='formControlLable'>
                                                <div className="labelTxt">
                                                    Email *
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="state"
                                                name="state"
                                                placeholder=""
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                            <label htmlFor="state" className='formControlLable'>
                                                <div className="labelTxt">
                                                    State
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="country"
                                                name="country"
                                                placeholder=""
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                            />
                                            <label htmlFor="country" className='formControlLable'>
                                                <div className="labelTxt">
                                                    Country
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3 ">
                                            <textarea
                                                className="form-control formcontroltxtCustom shadow-none maptextarea"
                                                rows="3" placeholder="Leave a comment here"
                                                name="message"
                                                id="message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            ></textarea>
                                            <label htmlFor="message">
                                                <div className="labelTxt">
                                                    Message*
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 mx-auto">
                                        <div className="signInButton mt-3 text-center">
                                            <button className='signinBtn' onClick={handleEnquire}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </ModalBody>
            </ReactModal>
            <HeaderOffcanvas show={show} setShow={setShow} />
        </>
    )
}

export default Header