import React, { useEffect, useState } from "react";
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import { db } from '../../common/firebase';
import { useParams } from 'react-router-dom';
import { Timestamp, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import Alert from '../../components/alert/Alert';
import { toDate } from "date-fns";
import { EventType } from "../../constants/EventConst";
import { createEvent } from "../../common/helper";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";

const EditCoupon = () => {
    const uid = useParams();
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [data, setData] = useState();
    const [allClient, setAllClient] = useState();
    const [couponCode, setCouponCode] = useState();
    const [owner, setOwner] = useState();
    const [value, setValue] = useState();
    const [type, setType] = useState();
    const [description, setDescription] = useState();
    const [isVisible, setIsVisible] = useState();
    const [expirationDate, setExpirationDate] = useState();

    useEffect(() => {
        const getCoupons = async () => {
            const couponCollection = collection(db, DATABASECOLLECTION.COUPONS);
            const couponDocRef = doc(couponCollection, uid.uid);
            const couponDocumentSnapshot = await getDoc(couponDocRef);
            if (couponDocumentSnapshot.data()) {
                const userDetails = couponDocumentSnapshot.data();
                setCouponCode(userDetails.couponCode);
                setOwner(userDetails.owner.path);
                setValue(userDetails.value);
                setType(userDetails.type);
                setDescription(userDetails.description);
                setIsVisible(userDetails.isVisible);
                const timestampInSeconds = userDetails.expirationDate.seconds;
                const expirationDateObj = toDate(timestampInSeconds * 1000);
                const formattedExpirationDate = expirationDateObj.toISOString().slice(0, -8);
                setExpirationDate(formattedExpirationDate);
                setData(userDetails);
            }
        };
        const getAllClients = async () => {
            const activationCollection = collection(db, DATABASECOLLECTION.ACTIVATIONCODES);
            const activationQuery = query(activationCollection, where('isUsed', '==', false));
            const activationDocsSnapshot = await getDocs(activationQuery);
            const clientUIDDataArray = [];
            for (const activationDoc of activationDocsSnapshot.docs) {
                const clientUIDReference = activationDoc.data().clientUID;
                const clientUIDDoc = await getDoc(clientUIDReference);
                if (clientUIDDoc.exists()) {
                    const clientUIDData = clientUIDDoc.data();
                    clientUIDData.uid = clientUIDDoc.id;
                    clientUIDDataArray.push(clientUIDData);
                }
            }
            setAllClient(clientUIDDataArray);
        };
        getCoupons();
        getAllClients();
    }, []);

    const handleUpdateStatus = async () => {
        try {
            setErrorMsg('');
            setSuccessMsg('');
            if (!couponCode || !owner || !value || !type || !description || !isVisible || !expirationDate) {
                setErrorMsg('Please fill out all the required fields.');
                return;
            }

            const currentDate = new Date();
            const selectedExpirationDate = new Date(expirationDate);

            if (selectedExpirationDate < currentDate) {
                setErrorMsg('Please select an expiration date in the future.');
                return;
            }
            
            const expirationTimestamp = expirationDate ? Timestamp.fromDate(new Date(expirationDate)) : null;
            const couponDoc = doc(collection(db, DATABASECOLLECTION.COUPONS), uid.uid);
            const data = {
                userId: localStorage.getItem('docid'),
                type: EventType.EDITCOUPON,
                details: {
                    couponCode: couponCode,
                }
            }
            await updateDoc(couponDoc, {
                couponCode:couponCode,
                owner: doc(db, owner),
                value: value,
                type: type,
                description: description,
                isVisible:isVisible,
                expirationDate:expirationTimestamp
            });
            await createEvent(data);
            setSuccessMsg('Coupon details updated successfully!');
        } catch (error) {
            console.error('Error updating clinician status:', error);
            setErrorMsg('An error occurred during updation. Please try again.');
        }
    };

    return (
        <>
            <div className="dashboardWrapper">
                <AdimnSidebar />
                <div className="adminDashBoard">
                    <AdminHeader />
                    {data && (
                        <div className="adminBody">
                            <div className="registerUser commonCard">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-header ">
                                            <div className="SectionHeadingMain">
                                                Coupon Details
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {errorMsg && (
                                            <Alert text={errorMsg} type="error" />
                                        )}
                                        {successMsg && (
                                            <Alert text={successMsg} type="success" />
                                        )}
                                        <div className="card-body pt-3">
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control shadow-none border formcontrolCustom controlRounded" placeholder="" onChange={(event) => setCouponCode(event.target.value)} value={couponCode ?couponCode:data.couponCode} />
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Coupons Code
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3 dropdown-container">
                                                        <select
                                                            className="form-control shadow-none border formcontrolCustom controlRounded"
                                                            name="onboardComplete"
                                                            value={owner? owner: data.owner.path}
                                                            onChange={(event) => setOwner(event.target.value)}
                                                        >
                                                            {allClient && allClient.map((item,index) => (
                                                                <option
                                                                    key={index}
                                                                    value={`${DATABASECOLLECTION.USERPROFILES}/` + item.uid}
                                                                    selected={`${DATABASECOLLECTION.USERPROFILES}/` + item.uid === data.owner.path}
                                                                >
                                                                    {item.firstName + ' ' + item.lastName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Owner
                                                            </div>
                                                        </label>
                                                        <div className="dropdown-icon">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="number" className="form-control shadow-none border formcontrolCustom controlRounded" onChange={(event) => setValue(event.target.value)} value={value ? value : data.value} placeholder="" />
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Value
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 ">
                                                    <div className="form-floating mb-3 dropdown-container">
                                                        <select
                                                            className="form-control shadow-none border formcontrolCustom controlRounded"
                                                            name="isVisible"
                                                            onChange={(event) => setIsVisible(event.target.value)} value={isVisible ? isVisible : data.isVisible}
                                                        >
                                                            <option value="true" selected={data.isVisible && true}>Yes</option>
                                                            <option value="false" selected={!data.isVisible && false}>No</option>
                                                        </select>
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Is Visible?
                                                            </div>
                                                        </label>
                                                        <div className="dropdown-icon">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 ">
                                                    <div className="form-floating mb-3 dropdown-container">
                                                        <select
                                                            className="form-control shadow-none border formcontrolCustom controlRounded"
                                                            name="type"
                                                            onChange={(event) => setType(event.target.value)} value={type ? type : data.type}
                                                        >
                                                            <option value="organisation" selected={data.type === "organisation"}>Organisation</option>
                                                            <option value="personal" selected={data.type === "personal"}>Personal</option>
                                                            <option value="global" selected={data.type === "global"}>Global</option>
                                                        </select>
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Type
                                                            </div>
                                                        </label>
                                                        <div className="dropdown-icon">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "32px", width: "32px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 ">
                                                    <div className="form-floating mb-3">
                                                        <input type="datetime-local"
                                                            className="form-control shadow-none border formcontrolCustom controlRounded" onChange={(event) => setExpirationDate(event.target.value)} value={expirationDate} placeholder="" />
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Expiry Date
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <div className="form-floating mb-3">
                                                        <textarea className="form-control shadow-none border formcontrolCustom controlRounded" onChange={(event) => setDescription(event.target.value)} value={description ? description : data.description} placeholder="" cols="3">{data.description}</textarea>
                                                        <label htmlFor="floatingInput" className="formControlLable">
                                                            <div className="labelTxt">
                                                                Description
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 col-sm-6 col-md-2 col-xl-2 mx-auto mx-sm-0">
                                        <div className="signInButton mt-md-3 text-center">
                                            <button className='signinBtn rounded-2' type='btn' onClick={handleUpdateStatus}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )

}
export default EditCoupon