import React, { useState } from 'react'
import tinnImg from '../../assets/admin/img/signFinal.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { ADMIN_ROUTES } from '../../constants/RouteConst'
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { auth, db } from '../../common/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { checkEmailExists } from '../../common/helper';
import Alert from '../../components/alert/Alert';

const Forget = () => {
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(auth);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    try {
      setSuccessMsg('');
      setErrorMsg('');
      const email = e.target.email.value;
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        await sendPasswordResetEmail(email)
        .then(() => {
          setSuccessMsg("Password reset email sent!");
        }) 
          .catch(err => {
            setErrorMsg(error);
          });
      } else {
        setErrorMsg("Email does not exist in our database");
      }
    } catch (error) {
      setErrorMsg("Authentication failed: " + error.message);
    }
  }
  return (
    <>
      <div className="authpages h-100 overflow-y-auto">
        <div className="container-fluid  h-100 signupSection forgetpassword">
          <div className="row h-100 align-items-lg-center justify-content-center ">
            <div className="col-12 col-lg-6 px-0 overflow-hidden">
              <div className="registerImg">
                <img src={tinnImg} className="img-fluid w-100 h-100 object-fit-cover" alt="table accordion" />
              </div>
            </div>
            <div className="col-12 col-sm-10 col-lg-5 col-xl-4">
              <div className="inputsignSide text-center text-lgstart">
                <div className="row">
                  <div className="col-12">
                    <div className="headingI pb-1">
                      Forget Password
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="subHeding">
                      Sign in to continue to Neuromonics.
                    </div>
                  </div>
                  <div className="col-12">
                    <form className="MainForm" onSubmit={handleForgetPassword}>
                      {errorMsg && (
                        <Alert text={errorMsg} type="error" />
                      )}
                      {successMsg && (
                        <Alert text={successMsg} type="success" />
                      )}
                      <div className="form-floating mb-3">
                        <input type="email" className="form-control shadow-none formcontrolCustom" name="email" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput" className='formControlLable'>
                          <div className="labelTxt">
                            Email address
                          </div>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="signInButton mt-3 mt-md-4  text-center">
                          <button className='signinBtn' type='btn'>Reset Password</button>
                        </div>
                      </div>
                      <div className="col-12 text-center mt-3">
                        <Link to={ADMIN_ROUTES.LOGIN_URL} className='text-decoration-none forgottxt'>
                          Back To Sign In?
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Forget