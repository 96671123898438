export const TEMPPLATETYPE = {
    ADDCLIENTC: 'Add Client (Clinician)',
    ADDCLIENT: 'Add Client(Client)',
    CLINICIANSTATUS: 'Clinician Approval',
    CLINICIANAPPLICATION: 'Clinician Application (Applicant)',
    WEBSITEENQUIRYCLIENT: 'Website Enquiry(Client)',
    WEBSITEENQUIRYADMIN: 'Website Enquiry(Administrator)',
    NEWADMINADDEDCLIENT: 'New Admin Added(Client)',
    CLINICIANPURCHASEORDER: 'Clinician Purchase Order',
    CLIENTREASSIGN: "Client Re-Assignment Request"
}