import React from "react";
import "./app.scss";
import RoutesComponent from "./routes/RoutesComponent";

function App() {
  return (
    <>
    <RoutesComponent/>
      </>
  );
}

export default App;
