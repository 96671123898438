import React, { useEffect, useState } from 'react'
import user from '../../assets/admin/img/user.jpg'
import menu from '../../assets/admin/img/menu.svg'
import logo from '../../assets/admin/img/dashboardLogo.png'
import Dropdown from 'react-bootstrap/Dropdown';
import { useSignOut } from 'react-firebase-hooks/auth';
import { db, auth } from '../../common/firebase';
import { Link, useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES, FRONTEND_ROUTES } from '../../constants/RouteConst';
import { createEvent, getAuthDetails, getUserName } from '../../common/helper';
import { useAuth } from '../../context/AuthContext';
import { EventType } from '../../constants/EventConst';

const AdminHeader = ({ clinicianNameLetterUpdated, clinicianNameUpdated }) => {
    const [show, setShow] = useState(false);
    const [clinicianNameLetter, setClinicianNameLetter] = useState();
    const [clinicianName, setClinicianName] = useState();
    const navigate = useNavigate();
    const { authChecked, setAuthChecked } = useAuth();
    console.log({ clinicianName })
    const handleToggle = () => {
        setShow(!show);
    }
    const [signOut, user] = useSignOut(auth);
    const data = {
        userId: localStorage.getItem('docid'),
        type: EventType.LOGOUT,
        details: {
            succeeded: true,
        }
    }
    const handleLogout = async () => {
        await signOut()
            .then(() => {
                createEvent(data);
                setAuthChecked(false);
                localStorage.clear();
                navigate('/login');
            })
            .catch((error) => {
                data.details.succeeded = false;
                createEvent(data);
                console.error('Error during logout:', error.message);
            });
    };
    useEffect(() => {
        document.body.className = show ? 'show' : '';
    }, [show]);

    useEffect(() => {
        const fetchData = async () => {
            const docData = await getUserName(localStorage.getItem('login_email'));
            docData && setClinicianNameLetter(docData.substring(0, 2).toUpperCase());
            setClinicianName(docData);
        };
        authChecked && fetchData();
    }, [clinicianName]);


    return (
        <>
            <div className="adminHeader">
                <div className="row justify-content-between align-items-center h-100">
                    <div className="col-auto">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="menuBtn h-100 d-flex" onClick={handleToggle}><img src={menu} /></div>
                            </div>
                            <div className="col">
                                {/* <div className="headerLogo">
                                        <img src={logo} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                        <span className="logoTxt ps-2">
                                            NEUROMONICS
                                        </span>
                                    </div> */}
                                <div className="homeBtnheaderDiv">
                                    <Link to={FRONTEND_ROUTES.HOME_PAGE} className='text-text-decoration-none homeBtnheaderDivanc'>Go to Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto d-flex justify-content-end align-items-center">
                        <Dropdown className='adminSidebarDropdown'>
                            <Dropdown.Toggle variant="success" className='m-0' id="dropdown-basic">
                                <div className="adminUser">
                                    <div className='adminUserImg'>{clinicianNameLetterUpdated ? clinicianNameLetterUpdated : clinicianNameLetter}</div>
                                </div>
                                <div className="adminName ps-2 d-none d-sm-flex">{clinicianNameUpdated ? clinicianNameUpdated : clinicianName}</div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => navigate(ADMIN_ROUTES.PROFILE)}>My Profile</Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminHeader