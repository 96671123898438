export const PrimaryBadge = ({text})=> {
    return (
        <>
            <div className="customBadge prcolor">{text}</div>
        </>
    )
}
export const SuccessBadge = ({text})=> {
    return (
        <>
            <div className="customBadge sucolor">{text}</div>
        </>
    )
}
export const InfoBadge = ({text})=> {
    return (
        <>
            <div className="customBadge incolor">{text}</div>
        </>
    )
}
export const WarningBadge = ({text})=> {
    return (
        <>
            <div className="customBadge warcolor">{text}</div>
        </>
    )
}
export const DangerBadge = ({text})=> {
    return (
        <>
            <div className="customBadge dancolor">{text}</div>
        </>
    )
}