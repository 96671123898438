import React, { useEffect, useState } from "react";
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import Dropdown from 'react-bootstrap/Dropdown';
import { collection, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../common/firebase";
import { ADMIN_ROUTES } from "../../constants/RouteConst";
import { Link } from "react-router-dom";
import Loader from "../extra/Loader";
import Swal from "sweetalert2";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";

const Coupons = () => {
    const [couponData, setCouponData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const couponsRef = collection(db, DATABASECOLLECTION.COUPONS);
            const querySnapshot = await getDocs(couponsRef);
            if (querySnapshot.empty) {
                setIsLoading(false);
                // console.log('No documents found in coupons');
                return;
            }
            const resultData = [];
            for (const docn of querySnapshot.docs) {
                const couponsSnapshot = docn.data();
                const ownerProfileSnapshot = await getDoc(couponsSnapshot.owner);
                const createdProfileSnapshot = await getDoc(couponsSnapshot.createdBy);
                resultData.push({
                    id: docn.id,
                    ownerData: ownerProfileSnapshot.data(),
                    createdByData: createdProfileSnapshot.data(),
                    ...couponsSnapshot,
                });
            }
            setCouponData(resultData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching documents:', error.message);
        }
    };

    const handleDelete = async (itemId) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this coupon!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
        });
        if (result.isConfirmed) {
            try {
                await deleteDoc(doc(collection(db, DATABASECOLLECTION.COUPONS), itemId));
                Swal.fire('Deleted!', 'Your coupon has been deleted.', 'success');
                fetchData();
            } catch (error) {
                Swal.fire('Error!', 'Failed to delete the item.', 'error');
            }
        }
    };

    return (
        <>
            <div className="dashboardWrapper">
                <AdimnSidebar />
                <div className="adminDashBoard">
                    <AdminHeader />
                    <div className="adminBody couponsSection commonCard" style={{ backgroundColor: "#eef1f9" }}>
                        <div className="row">
                            <div className="col-12">
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h4 className='SectionHeadingMain fw-bold'>Coupons</h4>
                                    </div>
                                    <div className="col-auto">
                                        <Link to={`${ADMIN_ROUTES.ADD_COUPON}`} className="text-decoration-none">
                                            <button className='editBtn btn btn-dark text-white'>
                                                <div className="editPencilImg d-flex align-items-center gap-1">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" style={{ width: '14px', height: '14px' }} xmlns="http://www.w3.org/2000/svg"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg> Add
                                                </div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {isLoading && <Loader />}
                            <div className="col-12 col-md-12 col-xl-12 col-xxl-12">
                                {!isLoading &&
                                    couponData ? couponData.map((item, index) => (
                                        <div className="mainCard shadow-lg my-3" style={{ backgroundColor: "#fff", borderRadius: '10px' }} key={index}>
                                            <div className="row align-items-cente mx-0">
                                                <div className="col-auto d-none d-sm-flex">
                                                    <div className="cardIcon">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="m20.749 12 1.104-1.908a1 1 0 0 0-.365-1.366l-1.91-1.104v-2.2a1 1 0 0 0-1-1h-2.199l-1.103-1.909a1.008 1.008 0 0 0-.607-.466.993.993 0 0 0-.759.1L12 3.251l-1.91-1.105a1 1 0 0 0-1.366.366L7.62 4.422H5.421a1 1 0 0 0-1 1v2.199l-1.91 1.104a.998.998 0 0 0-.365 1.367L3.25 12l-1.104 1.908a1.004 1.004 0 0 0 .364 1.367l1.91 1.104v2.199a1 1 0 0 0 1 1h2.2l1.104 1.91a1.01 1.01 0 0 0 .866.5c.174 0 .347-.046.501-.135l1.908-1.104 1.91 1.104a1.001 1.001 0 0 0 1.366-.365l1.103-1.91h2.199a1 1 0 0 0 1-1v-2.199l1.91-1.104a1 1 0 0 0 .365-1.367L20.749 12zM9.499 6.99a1.5 1.5 0 1 1-.001 3.001 1.5 1.5 0 0 1 .001-3.001zm.3 9.6-1.6-1.199 6-8 1.6 1.199-6 8zm4.7.4a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path></svg>
                                                    </div>
                                                </div>
                                                <div className="col-auto d-none d-sm-flex">
                                                    <div className="borderDiv"></div>
                                                </div>
                                                <div className="col">
                                                    <div className="cardContent">
                                                        <div className="row align-items-center">
                                                            <div className="col-4">
                                                                <div className="row align-items-center py-1">
                                                                    <div className="col-auto">
                                                                        <div className="head">
                                                                            Coupon Code :
                                                                        </div>
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <div className="subHead">
                                                                            {item.couponCode}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="row align-items-center py-1">
                                                                    <div className="col-auto">
                                                                        <div className="head">
                                                                            Owner :
                                                                        </div>
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <div className="subHead">
                                                                            {item.ownerData.firstName + ' ' + item.ownerData.lastName}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="row align-items-center py-1">
                                                                    <div className="col-auto">
                                                                        <div className="head">
                                                                            Is Visible :
                                                                        </div>
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <div className="subHead">
                                                                            {item.isVisible === 'true' ? 'Yes' : 'No'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="row align-items-center py-1">
                                                                    <div className="col-auto">
                                                                        <div className="head">
                                                                            Value  :
                                                                        </div>
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <div className="subHead">
                                                                            {item.value}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="row align-items-center py-1">
                                                                    <div className="col-auto">
                                                                        <div className="head">
                                                                            Type  :
                                                                        </div>
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <div className="subHead">
                                                                            {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <Dropdown className="cuopanDropdown">
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        More Details...
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="DropdownMenu">
                                                                        <Dropdown.Item href="#/action-1" className="dropdownItem">
                                                                            <div className="row gy-2">
                                                                                <div className="col-12 px-0">
                                                                                    <div className="head">
                                                                                        Created On :
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 px-0 px-0">
                                                                                    <div className="subHead">
                                                                                        {new Date(item.createdDate.seconds * 1000).toLocaleString([], {
                                                                                            year: 'numeric',
                                                                                            month: 'short',
                                                                                            day: 'numeric',
                                                                                            hour: 'numeric',
                                                                                            minute: 'numeric',
                                                                                            hour12: true,
                                                                                        })}                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-2" className="dropdownItem">
                                                                            <div className="row gy-2">
                                                                                <div className="col-12 px-0">
                                                                                    <div className="head">
                                                                                        Created By :
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 px-0 px-0">
                                                                                    <div className="subHead">
                                                                                        {item.createdByData.firstName + ' ' + item.createdByData.lastName}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-2" className="dropdownItem">
                                                                            <div className="row gy-2">
                                                                                <div className="col-12 px-0">
                                                                                    <div className="head">
                                                                                        Expiry Date :
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 px-0 px-0">
                                                                                    <div className="subHead">
                                                                                        {new Date(item.expirationDate.seconds * 1000).toLocaleString([], {
                                                                                            year: 'numeric',
                                                                                            month: 'short',
                                                                                            day: 'numeric',
                                                                                            hour: 'numeric',
                                                                                            minute: 'numeric',
                                                                                            hour12: true,
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-3" className="dropdownItem">
                                                                            <div className="row gy-2">
                                                                                <div className="col-12 px-0">
                                                                                    <div className="head">
                                                                                        Description :
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 px-0 px-0">
                                                                                    <div className="subHead">
                                                                                        {item.description}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            <div className="col-1">
                                                                <div className="row align-items-center py-1">
                                                                    <div className='d-flex gap-2 text-nowrap tableButton'>
                                                                        <Link to={`${ADMIN_ROUTES.EDIT_COUPON}/`+item.id}>
                                                                            <button className='editBtn btn btn-warning text-decoration-none text-white shadow'>
                                                                                <div className="editPencilImg d-flex align-items-center gap-1">
                                                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" style={{ width: '14px', height: '14px' }} xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="44" d="M358.62 129.28L86.49 402.08 70 442l39.92-16.49 272.8-272.13-24.1-24.1zm54.45-54.44l-11.79 11.78 24.1 24.1 11.79-11.79a16.51 16.51 0 000-23.34l-.75-.75a16.51 16.51 0 00-23.35 0z"></path></svg>
                                                                                </div>
                                                                            </button>
                                                                        </Link>
                                                                        <button className='editBtn btn btn-danger text-decoration-none text-white shadow' onClick={() => handleDelete(item.id)}>
                                                                            <div className="editPencilImg d-flex align-items-center gap-1">
                                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" style={{width:'14px', height:'14px'}} xmlns="http://www.w3.org/2000/svg"><path fill="#D9D9D9" d="M292.7 840h438.6l24.2-512h-487z"></path><path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-504-72h304v72H360v-72zm371.3 656H292.7l-24.2-512h487l-24.2 512z"></path></svg>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className="mainCard shadow-lg my-3" style={{ backgroundColor: "#fff", borderRadius: '10px' }}>
                                        <div className="row align-items-cente mx-0">
                                            <div className="col">
                                                <div className="cardContent">
                                                    <div className="row align-items-center">
                                                        <div className="col-12">
                                                            <div className="row align-items-center py-1">
                                                                <div className="col-auto">
                                                                    <div className="head">
                                                                        No Coupons Available
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Coupons