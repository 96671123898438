import React, { useRef } from 'react'
import Header from '../../../components/frontendComponents/header/Header'
import five from '../../../assets/common/img/five.jpg';
import one from '../../../assets/common/img/one.jpg';
import two from '../../../assets/common/img/two.jpg';
import four from '../../../assets/common/img/four.jpg';
import Footer from '../../../components/frontendComponents/footer/Footer';
import BlogComponent from '../../../components/frontendComponents/homePage/BlogComponent';
import ContactComponent from '../../../components/frontendComponents/homePage/ContactComponent';


const About = () => {
    const scrollContainer = useRef(null)
    const TestimonialData = [1, 2, 3, 4, 5, 6, 7]
    return (
        <>
            <div className='frontendOuter paddingTopHeader aboutPadding' ref={scrollContainer}>
                <Header scrollContainer={scrollContainer} secondHeader={true} />
                {/* home Section start */}
                <div className="container-fluid aboutHomeSec  px-3 px-md-4 px-lg-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 order-2 order-md-1 pb-3 pb-lg-0">
                            <div className="heading">
                                About Us
                            </div>
                            <div className="description pt-3 pt-xl-5">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae, asperiores ipsam a neque quod reprehenderit eum facilis repellat nihil dolorum quis laboriosam sequi ipsum consectetur dolores sint eius! Possimus, error?
                            </div>
                            <div className="pt-3 pt-xl-5">
                                <button className="nuroBtn">
                                    Learn More
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row gy-4 py-4">
                                        <div className="col-12">
                                            <div className="sideImg overflow-hidden ">
                                                <img src={five} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="sideLower overflow-hidden">
                                                <img src={one} alt="" className='h-100 w-100' />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row gy-4 py-4">
                                        <div className="col-12">
                                            <div className="leftUpper overflow-hidden ">
                                                <img src={two} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="leftLower overflow-hidden">
                                                <img src={four} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* home Section Ends */}
                {/* card Section Start */}
                <div className="container-fluid cardThreeSection py-3 py-lg-5">
                    <div className="container">
                        <div className="col-12 d-flex justify-content-start">
                            <div className="heroBanner row">
                                <div className="col-auto">
                                    <div className="heroPill">
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                        Services
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="heroHeading">
                                        Our Services
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="heroSubHeading">
                                        Lorem, ipsum dolor Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur, magni! sit amet consectetur adipisicing elit. Expedita, ex?
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gy-4 gy-md-5 align-items-stretch">
                            <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                <div className="mainCard shadow-lg rounded-3 h-100">
                                    <div className="icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" className="h-100 w-100 img-fluid" xmlns="http://www.w3.org/2000/svg"><path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"></path></svg>
                                    </div>
                                    <div className="cardheading pt-4 pb-3">
                                        Visit with an Audiologist
                                    </div>
                                    <div className="description">
                                        Meet with an audiologist and begin your personalized treatment plan.
                                    </div>
                                    {/* <div className="cardButton">
                                        <button className="btn cardbtnbtn rounded-pill">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                <div className="mainCard shadow-lg rounded-3 h-100">
                                    <div className="icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="h-100 w-100 img-fluid" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"></path></svg>
                                    </div>
                                    <div className="cardheading pt-4 pb-3">
                                        Use Neuromonics for 4-6 months
                                    </div>
                                    <div className="description">
                                        Start your journey to healing and comfort. Have peace of mind knowing there’s finally a solution to the constant pain, discomfort, or annoyance.
                                    </div>
                                    {/* <div className="cardButton">
                                        <button className="btn cardbtnbtn rounded-pill">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                <div className="mainCard shadow-lg rounded-3 h-100">
                                    <div className="icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="h-100 w-100 img-fluid" xmlns="http://www.w3.org/2000/svg"><path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path><path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.492 1.492 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.72.72 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.72.72 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"></path></svg>
                                    </div>
                                    <div className="cardheading pt-4 pb-3">
                                        Have long term relief of symtoms
                                    </div>
                                    <div className="description">
                                        After 4-6 months of consistant use of our app, you can be one of the thousands to have 40%+ fewer symptoms.
                                    </div>
                                    {/* <div className="cardButton">
                                        <button className="btn cardbtnbtn rounded-pill">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*card Section Ends */}
                {/* mission Section Start */}
                <div className="container-fluid missionSec py-3 py-lg-5">
                    <div className="container">
                        <div className="row align-items-center gy-3">
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-start">
                                        <div className="heroBanner row">
                                            <div className="col-auto">
                                                <div className="heroPill">
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                    Mission
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroHeading">
                                                    Mission
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroSubHeading descripton">
                                                    Lorem, ipsum dolor Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur, magni! sit amet consectetur adipisicing elit. Expedita, ex?
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="missionImg">
                                    <img src={five} alt="" className='h-100 w-100' />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center gy-3 pt-3 pt-md-5">
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="missionImg">
                                    <img src={five} alt="" className='h-100 w-100' />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-start">
                                        <div className="heroBanner row ps-md-3">
                                            <div className="col-auto">
                                                <div className="heroPill">
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                    Vision
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroHeading">
                                                    Vision
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroSubHeading descripton">
                                                    Lorem, ipsum dolor Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur, magni! sit amet consectetur adipisicing elit. Expedita, ex?
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* mission Section End */}
                {/* recent section Start */}
                <div className="container-fluid blogFluid position-relative py-lg-5 py-3" style={{ zIndex: 5, background: '#EAE6E1' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                                            </svg>
                                            Our Blog
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">Recent Post</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <BlogComponent />
                        </div>
                    </div>
                </div>
                {/* recent section End */}
                {/* contact section Start */}
                <div className="container-fluid py-3 py-lg-5 contactSection">
                    <ContactComponent />
                </div>
                {/* contact section Ends */}
                {/* faq Section start */}
                {/* <div className="container-fluid faqSection py-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                    <div className="col-12 d-flex justify-content-start">
                                        <div className="heroBanner row">
                                            <div className="col-auto">
                                                <div className="heroPill">
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                    FAQ
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroHeading">
                                                    Tinnitus FAQs
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>What are the symptoms ?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Who is affected?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>What are the types of tinnitus?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>What are the causes of tinnitus?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>What do I do if I have tinnitus?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                {/* faq Section End */}
                {/* footer start */}
                    <Footer />
                {/* footer end */}
            </div>
        </>
    )
}

export default About
