import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../components/frontendComponents/header/Header'
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../common/firebase';
import Loader from '../../extra/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { FILENAME, STORAGE, STORAGEURL } from '../../../constants/StorageConst';
import Footer from '../../../components/frontendComponents/footer/Footer';
import { NavLink } from 'react-bootstrap';

const Journals = () => {
    const scrollContainer = useRef(null)
    const [jsonData, setJsonData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [checkScroller, setCheckScroller] = useState(true);
    const hash = window.location.hash;
    const navigate = useNavigate();

    useEffect(() => {
        let i = 0;
        const fetchData = async () => {
            const jsonFileRef = ref(storage, STORAGEURL + '/' + STORAGE.JOURNALS + '/' + FILENAME.JOURNALSFILE);
            try {
                const url = await getDownloadURL(jsonFileRef);
                const response = await fetch(url);
                const data = await response.json();
                const dataCategories = data.categories;
                setJsonData(dataCategories);
                setIsLoading(false)
                if (hash) {
                    if (checkScroller && i <= 3) {
                        const element = document.getElementById(hash.substring(1));
                        if (element) {
                            const offset = element.offsetTop - 100;
                            scrollContainer.current.scrollTo({ top: offset, behavior: 'smooth' });
                            setCheckScroller(false);
                        } else {
                            i++;
                            fetchData();
                        }
                    }
                }
            } catch (error) {
                navigate.push('/404');
            }
        };
        fetchData();
    }, [hash]);

    const handleDirectLinkClick = (slug) => (event) => {
        setCheckScroller(true)
        event.preventDefault();
        const element = document.getElementById(slug);
        if (element) {
            const offset = element.offsetTop - 100;
            scrollContainer.current.scrollTo({ top: offset, behavior: 'smooth' });
        }
        navigate.push(`#${slug}`);
    };

    return (
        <>
            <div className='frontendOuter paddingTopHeader' ref={scrollContainer}>
                <Header scrollContainer={scrollContainer} secondHeader={true} />
                <div className="container-fluid journalsFluid py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 px-0">
                                <div className="row heroBanner mx-0">
                                    <div className="col-12">
                                        <div className="row mx-0">
                                            <div className="col-auto px-0">
                                                <div className="heroPill"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>Neuromonics Resources</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading && <Loader />}
                        {!isLoading && jsonData && (
                            Object.entries(jsonData).map(([category, content], categoryIndex) => (
                                <div key={categoryIndex} className={`row${categoryIndex === 0 ? ' pt-3' : ' pt-5'}`}>
                                    {Array.isArray(content) ? (
                                        <>
                                            <div className="col-12 px-0">
                                                <div className="row heroBanner mx-0">
                                                    <div className="col-12">
                                                        <div className="row mx-0 mb-2">
                                                            <div className="col-12 px-0">
                                                                <div className="row mx-0">
                                                                    <div className="col px-0">
                                                                        <div className="heroHeading categoryColor">{category}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row g-3">
                                                {content.map((item, itemIndex) => (
                                                    <div key={itemIndex} className="col-12 col-sm-6 col-lg-4 position-relative" id={item.slug}>
                                                        <div className="JournalsCard">
                                                            <div className="row h-100">
                                                                <div className="col-12 mb-1">
                                                                    <div className="title"><Link to={item.link} target='_blank' className="link">{item.title}</Link></div>
                                                                </div>
                                                                <div className="col-12 mt-auto px-0">
                                                                    <div className="row mx-0 align-items-end">
                                                                        <div className="col">
                                                                            <div className="col-12 mt-1">
                                                                                <div className="authors"><span> {item.citation}</span></div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="authors">{item.authors.join(', ')}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <NavLink onClick={() => handleDirectLinkClick(item.slug)} href={`#${encodeURIComponent(item.slug)}`} className="link text-dark">direct link</NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ) : (
                                        Object.entries(content).map(([subCategory, subItems], subCategoryIndex) => (
                                            <div key={subCategoryIndex} className={`row${subCategoryIndex === 0 ? '' : ' pt-3 mt-3'}`}>
                                                <div className="col-12 px-0">
                                                    <div className="row heroBanner mx-0">
                                                        <div className="col-12">
                                                            <div className="row mx-0 mb-2">
                                                                <div className="col-12 px-0">
                                                                    <div className="row mx-0">
                                                                        <div className="col px-0">
                                                                            {subCategoryIndex === 0 && (
                                                                                <div className="heroHeading categoryColor">{category}</div>
                                                                            )}
                                                                            <div className="heroHeadingg">{subCategory}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {Array.isArray(subItems) && (
                                                    <div className="row g-3">
                                                        {subItems.map((subItem, subItemIndex) => (
                                                            <div key={subItemIndex} className="col-12 col-sm-6 col-lg-4 position-relative" id={subItem.slug}>
                                                                <div className="JournalsCard">
                                                                    <div className="row h-100">
                                                                        <div className="col-12 mb-4">
                                                                            <div className="title"><Link to={subItem.link} target='_blank' className="link">{subItem.title}</Link></div>
                                                                        </div>
                                                                        <div className="col-12 mt-auto px-0">
                                                                            <div className="row mx-0 align-items-end">
                                                                                <div className="col">
                                                                                    <div className="col-12 mt-1">
                                                                                        <div className="authors"><span> {subItem.citation}</span></div>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <div className="authors">{subItem.authors.join(', ')}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <NavLink onClick={() => handleDirectLinkClick(subItem.slug)} href={`#${encodeURIComponent(subItem.slug)}`} className="link text-dark">direct link</NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
                {/* footer start */}
                <Footer />
                {/* footer end */}
            </div>
        </>
    )
}

export default Journals