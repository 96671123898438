import React from 'react'
import loader from '../../assets/admin/img/loader.gif'


const Loader = ()=>{
    return(
        <>
        <div className="loaderPage">
            <div className="container-fluid vh-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-auto px-0">
                        {/* <div className="loaderImg">
                            <img src={loader} className="img-fluid h-100 w-100" alt="tableAccordion" />
                        </div> */}
                        <div className="preloader">
                            <div className="spinner"></div>
                            <div className='mt-1 text-nowrap'> Please wait...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Loader