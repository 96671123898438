import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        const isAuthenticated = localStorage.getItem('role') !== '' && localStorage.getItem('role') !== null;
        setAuthChecked(isAuthenticated);
    }, []);

    return (
        <AuthContext.Provider value={{ authChecked, setAuthChecked }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuth };
