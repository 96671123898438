export const DATABASECOLLECTION = {
    ACTIVATIONCODES: 'activationCodes',
    COUPONS:'coupons',
    TEMPLATES:'templates',
    USERAUDIO:'userAudio',
    USEREVENTS:'userEvents',
    USERPROFILES:'userProfiles',
    OTHERSETTINGS:'otherSettings',
    ACTIVATIONCODE:'activationCode',
    STATE:'state',
    CURRENT:'app',
    PAYMENTREDIRECTION:'paymentRedirection',
    DELETED_ACCOUNTS:"deleteAccounts"
}