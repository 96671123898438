import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../assets/common/img/logo.png';
import { Swiper, SwiperSlide } from "swiper/react";
import slideOne from '../../../assets/common/img/slider1.jpg';
import slideTwo from '../../../assets/common/img/slider2.jpg';
import r from '../../../assets/common/img/r.jpg';
import rr from '../../../assets/common/img/rr.jpg';
import { Autoplay, FreeMode, EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import "swiper/css";
import 'swiper/css/free-mode';
import cardNewImages from '../../../assets/common/img/rr.jpg';

import one from '../../../assets/common/img/one.jpg';
import two from '../../../assets/common/img/two.jpg';
import three from '../../../assets/common/img/three.jpg';
import four from '../../../assets/common/img/four.jpg';
import five from '../../../assets/common/img/five.jpg';
import fivefive from '../../../assets/common/img/fiveFive.jpg';
import six from '../../../assets/common/img/six.jpg';
import seven from '../../../assets/common/img/seven.jpg';
import eight from '../../../assets/common/img/eight.jpg';
import ten from '../../../assets/common/img/ten.jpg';
import eleven from '../../../assets/common/img/eleven.jpg';
import nine from '../../../assets/common/img/nine.avif';
import Header from '../../../components/frontendComponents/header/Header';
import TestimonialComponent from '../../../components/frontendComponents/homePage/TestimonialComponent';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../common/firebase';
import { FILENAME, STORAGE, STORAGEURL } from '../../../constants/StorageConst';
import BlogComponent from '../../../components/frontendComponents/homePage/BlogComponent';
import Footer from '../../../components/frontendComponents/footer/Footer';
import { Link } from 'react-router-dom';
import { FRONTEND_ROUTES } from '../../../constants/RouteConst';
import ReactModal from 'react-modal';
import { Alert, ModalBody, ModalHeader } from 'react-bootstrap';
import { getAuth, signInAnonymously, signOut } from 'firebase/auth';
import { checkEmailExists, getTemplateContent } from '../../../common/helper';
import { getData, sendEmail } from '../../../common/fetch';
import { createEvent } from '@testing-library/react';
import { EventType } from '../../../constants/EventConst';
import { TEMPPLATETYPE } from '../../../constants/TemplateTypeConst';
import Loader from '../../extra/Loader';


const HomePage = () => {
    const scrollContainer = useRef(null)
    const [jsonDataFirst, setJsonDataFirst] = useState(null);
    const [jsonDataSecond, setJsonDataSecond] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isLoadingModal, setIsLoadingModal] = useState(false);

    // To handle Enquire Form
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [message, setMessage] = useState("");

    const handleClickk = () => {
        setIsClicked(!isClicked);
    };

/* Enquiry Modal */
const openModal = () => {
    setSuccessMsg('');
    setErrorMsg('');
    setIsModalOpen(true);
};

const closeModal = () => {
    setIsModalOpen(false);
};
/* Enquiry Modal */

    const [autoplayEnabled, setAutoplayEnabled] = useState(true);

    const handleSliderClick = () => {
        setAutoplayEnabled(!autoplayEnabled);
    };

    useEffect(() => {
        const fetchData = async () => {
            const jsonFileRef = ref(storage, STORAGEURL + '/' + STORAGE.BLOGS + '/' + FILENAME.TESTIMONIALSFILE);
            try {
                const url = await getDownloadURL(jsonFileRef);
                const response = await fetch(url);
                const data = await response.json();
                const jsonDataLength = data.length;
                if (jsonDataLength > 5) {
                    if (jsonDataLength % 2 === 0) {
                        const halfLength = jsonDataLength / 2;
                        const firstHalfData = data.slice(0, halfLength);
                        const secondHalfData = data.slice(halfLength);
                        setJsonDataFirst(firstHalfData);
                        setJsonDataSecond(secondHalfData);
                    } else {
                        const greaterData = data.slice(0, (jsonDataLength - 1) / 2 + 1);
                        const lesserData = data.slice(((jsonDataLength - 1) / 2 + 1));
                        setJsonDataFirst(greaterData);
                        setJsonDataSecond(lesserData);
                    }
                } else {
                    setJsonDataFirst(data);
                }
                setIsLoading(false)

            } catch (error) {
                console.error('Error fetching or parsing JSON file:', error);
            }
        };
        fetchData();
    }, []);

        // Handle the enquire by Unauthorized or Authorized user from Public web
        const handleEnquire = async () => {
            try {
                setErrorMsg('');
                setSuccessMsg('');
                setIsLoading(true);
                const isValidEmail = (email) => {
                    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    return emailRegex.test(email);
                };

            const mandatoryFields = [firstName, email, message];

                for (const field of mandatoryFields) {
                    if (!field) {
                        setIsLoading(false);
                        setErrorMsg('Please complete all mandatory fields (indicated by an asterisk).');
                        return;
                    }
                }

                if (!isValidEmail(email)) {
                    setIsLoading(false);
                    setErrorMsg('Invalid email format. Please enter a valid email address.');
                    return;
                }

                const auth = getAuth();
                const checkClientEmailExists = await checkEmailExists(email);
                const userId = checkClientEmailExists ? (await getData(email)).uid : (await signInAnonymously(auth)).user.uid;

                const data = {
                    userId: userId,
                    type: EventType.ENQUIRY,
                    details: {
                        firstName: firstName,
                        emailAddress: email,
                        state: state,
                        country: country,
                        message: message,
                    }
                };

                await createEvent(data); //create event for enquiry data by unauthorized user from public website

                const adminTemplate = await getTemplateContent(TEMPPLATETYPE.WEBSITEENQUIRYADMIN);
                const templateAdminContent = adminTemplate[0].content;
                const updatedEmailAdminContent = templateAdminContent.replace(/%%FirstName%%/g, firstName)
                    .replace(/%%Email%%/g, email)
                    .replace(/%%State%%/g, state).replace(/%%Country%%/g, country)
                    .replace(/%%Message%%/g, message);

                const clientTemplate = await getTemplateContent(TEMPPLATETYPE.WEBSITEENQUIRYCLIENT);
                const templateClientContent = clientTemplate[0].content;
                const updatedEmailClientContent = templateClientContent.replace(/%%FirstName%%/g, firstName);
    
                await sendEmail(adminTemplate[0].recipient, adminTemplate[0].subject, updatedEmailAdminContent, adminTemplate[0].bcc);
                await sendEmail(email, clientTemplate[0].subject, updatedEmailClientContent, adminTemplate[0].bcc);

                setErrorMsg('');
                // setFirstName('');
                // setEmail('');
                // setState('');
                // setCountry('');
                // setMessage('');
                setIsLoading(false);
                setSuccessMsg('Enquiry sent successfully!');
                await signOut(auth);

            } catch (error) {
                setIsLoading(false);
                if (error.code === 'auth/email-already-in-use') {
                    setErrorMsg('This email is already in use. Please use a different email.');
                } else {
                    console.log(error);
                    setErrorMsg('An error occurred. Please try again later.');
                }
            }
        };

    return (
        <>
            <div className="frontendOuter" ref={scrollContainer}>
                <Header scrollContainer={scrollContainer} />
                <div className="container-fluid heroFluid px-0">
                    <div className="row">
                        <Swiper
                            spaceBetween={20}
                            effect={'fade'}
                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            centeredSlides={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[EffectFade, Autoplay]}
                        >
                            <SwiperSlide>
                                <div className="container-fluid sliderFluid px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100">
                                        <img src={slideOne} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center">
                                        <div className="row position-relative">
                                            <div className="col-12 col-md-7">
                                                <div className="heroTxtWrapper">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-9"><div className="heading">Your Path to Tinnitus Relief</div></div>
                                                        <div className="col-md-12 col-lg-9">
                                                            <div className="para">
                                                                Join the thousands finding solace with Neuromonics - where science harmonizes with music and wellness.
                                                            </div>
                                                        </div>
                                                        <div className="col-12"><Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>Find a Clinic <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="container-fluid sliderFluid roundedSlidedMain px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={slideTwo} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center  flex-column heroTxtWrapper">
                                    <div className='row pt-md-5'>
                                    <div className='col-12'><div className="heading text-center">Flexible treatment options to suit you</div></div>
                                    </div>
                                        <div className="row position-relative  align-items-stretch justify-content-between mx-auto g-3 mt-sm-5 mt-2  ">
                                            <div className="col-12">
                                                <div className="heroTxtWrapper pt-md-5 pt-1 h-100 roundedSlideOne position-relative d-flex align-items-center justify-content-center gx-4">
                                                    <div className="row px-3 align-items-stretch gy-3 gy-sm-4">
                                                        <div className="col-12 col-md-5 h-auto">

                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-sm-4 mt-2">
                                                                            <span className="numberSubheading">
                                                                               In-clinic Care
                                                                            </span>
                                                                            <div className="py-sm-4 py-2">
                                                                                bespoke, comprehensive tinnitus treatment
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-center mx-auto mt-md-4 mt-2"><Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>Find a Clinic <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2 h-auto d-flex justify-content-center align-items-center fs-sm-1 fs-6 fw-bold">
                                                            OR
                                                        </div>
                                                        <div className="col-12 col-md-5 h-auto">
                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-2 mt-2">
                                                                            <span className="numberSubheading">
                                                                              Virtual Health
                                                                            </span>
                                                                            <div className="py-4">
                                                                               convenient, accessible tinnitus treatment, tailored to you, wherever you live
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-center mx-auto mt-md-3 mt-2 text-nowrap"><Link onClick={openModal} className='text-decoration-none nuroBtn py-3'>Enquire about Neuromonics Virtual Health </Link></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="container-fluid sliderFluid px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={slideTwo} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center">
                                        <div className="row position-relative justify-content-between">
                                            <div className="col-sm-6 order-2 order-sm-1">
                                                <div className="heroTxtWrapper position-relative paddingAuto">
                                                    <div className="row text-center">
                                                        <div className="col-md-12 col-lg-10">
                                                            <div className="heading numberHeading py-1">
                                                                <Link to={FRONTEND_ROUTES.JOURNALS} className='text-decoration-none text-white'>
                                                                    83%
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-10"><div className="para para mb-2 mt-2"> <span className='numberSubheading'>Neuromonics success rate*</span>
                                                            <div className="py-2">
                                                                * success is defined as a 40%+ reduction in TRQ score.
                                                            </div>
                                                            <div className="py-2 fw-light">
                                                                Achieved across 10 clinical trials in the United States & Australia (σ = 8.8%), involving 1,000+ clinical trial subjects. Subject-weighted success rate across clinical trials {'>'}87%.</div>
                                                        </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-10 mt-3"><Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>Find a Clinic <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 order-1 order-sm-2">
                                                <div className="heroTxtWrapper text-end ">
                                                    <div className="row text-center">
                                                        <div className="col-md-12 col-lg-10"><div className="heading numberHeading">10,000+</div></div>
                                                        <div className="col-md-12 col-lg-10"><div className="para para mb-2 mt-2"><span className="numberSubheading">Neuromonics treatments delivered</span><br />
                            {/* <div className="py-2">as of October 2023</div> */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="container-fluid sliderFluid px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={eight} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center justify-content-center">
                                        <div className="row position-relative justify-content-center align-items-center">
                                            <div className="col-12">
                                                <div className="heroTxtWrapper">
                                                    <div className="row text-center">
                                                        <div className="col-12"><div className="heading numberHeading py-1">50 million
                                                        </div></div>
                                                        <div className="col-12">
                                                            <div className="para para mb-2 mt-2"> <span className='numberSubheading'> Americans with tinnitus</span>
                                                                <div className="py-1 fw-light">
                                                                    According to the American Tinnitus Association (ATA)</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-3"><Link className='text-decoration-none nuroBtn py-3' to={FRONTEND_ROUTES.FINDCLINIC}>Find a Clinic <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>




                            <SwiperSlide>
                                <div className="container-fluid sliderFluid px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={rr} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center">
                                        <div className="row position-relative">
                                            <div className="col-12">
                                                <div className="heroTxtWrapper">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="para">
                                                                <span className='numitalic'>
                                                                    <i>There are days, weeks, sometimes even a month that I hardly even notice I have tinnitus ... Now, with Neuromonics, my brain gets reset and I'm good to go again</i>
                                                                    <div className="pt-2">
                                                                        <div className="authorNametxt fw-lighter">
                                                                            <span className='fw-lighter'>Walt Tarpley</span>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="container-fluid sliderFluid roundedSlidedMain px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={slideTwo} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center  flex-column heroTxtWrapper">
                                    <div className='row pt-md-4  '>
                                    <div className='col-12'><div className="heading text-center">Flexible treatment options to suit you</div></div>
                                    </div>
                                        <div className="row position-relative  align-items-stretch justify-content-between mx-auto g-3 mt-sm-5 mt-1  ">
                                            <div className="col-12">
                                                <div className="heroTxtWrapper pt-md-3 pt-1 h-100 roundedSlideOne position-relative d-flex align-items-center justify-content-center gx-4">
                                                    <div className="row px-3 align-items-stretch gy-3 gy-sm-4">
                                                        <div className="col-12 col-md-5 h-auto">

                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-sm-4 mt-2">
                                                                            <span className="numberSubheading">
                                                                               In-clinic Care
                                                                            </span>
                                                                            <div className="py-sm-4 py-2">
                                                                                bespoke, comprehensive tinnitus treatment
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-center mx-auto mt-md-3 mt-2"><Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>Find a Clinic <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2 h-auto d-flex justify-content-center align-items-center fs-sm-1 fs-3 fw-bold">
                                                            OR
                                                        </div>
                                                        <div className="col-12 col-md-5 h-auto">
                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-2 mt-2">
                                                                            <span className="numberSubheading">
                                                                              Virtual Health
                                                                            </span>
                                                                            <div className="py-4">
                                                                               convenient, accessible tinnitus treatment, tailored to you, wherever you live
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-center mx-auto mt-md-4 mt-2 text-nowrap"><Link onClick={openModal} className='text-decoration-none nuroBtn py-3'>Enquire about Neuromonics Virtual Health </Link></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="container-fluid sliderFluid roundedSlidedMain px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={six} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center">
                                        <div className="row position-relative align-align-items-stretch justify-content-between mx-auto g-3">
                                            <div className="col-12">
                                                <div className="heroTxtWrapper h-100 roundedSlideOne position-relative d-flex align-items-center justify-content-center gx-4">
                                                    <div className="row px-3 align-items-stretch gy-3 gy-sm-4">
                                                        <div className="col-12 col-sm-6 col-md-4 h-auto">
                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-2 mt-2">
                                                                            <div className="roundedNumber position-absolute">
                                                                                1
                                                                            </div>
                                                                            <span className='numberSubheading'>
                                                                                Visit a Neuromonics Audiologist
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4 h-auto">
                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-2 mt-2">
                                                                            <div className="roundedNumber position-absolute">
                                                                                2
                                                                            </div>
                                                                            <span className="numberSubheading">
                                                                                2 hrs per day
                                                                            </span>
                                                                            <div className="py-2">
                                                                                listen and relax to  individually- tailored, spectrally-modified Music
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4 h-auto">
                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-2 mt-2">
                                                                            <div className="roundedNumber position-absolute">
                                                                                3
                                                                            </div>
                                                                            <span className="numberSubheading">
                                                                                6 Months
                                                                            </span><br />
                                                                            <div className="py-2">
                                                                                Receive one-on-one structured guided counselling and support through your treatment
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-center mx-auto mt-4"><Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>Find a Clinic <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="container-fluid sliderFluid px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={four} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center">
                                        <div className="row position-relative">
                                            <div className="col-12">
                                                <div className="heroTxtWrapper">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="para">
                                                                <span className='numitalic'>
                                                                    <i>Finally, I found the relief I need.</i>
                                                                    <div className="pt-2">
                                                                        <div className="authorNametxt">
                                                                            <span>Ed Bennett</span>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="container-fluid sliderFluid roundedSlidedMain px-0 position-relative vh-100">
                                    <div className="slider position-absolute top-0 start-0 w-100 h-100"><img src={slideTwo} alt="" /></div>
                                    <div className="container h-100 d-flex align-items-center  flex-column heroTxtWrapper">
                                    <div className='row pt-md-5'>
                                    <div className='col-12'><div className="heading text-center">Flexible treatment options to suit you</div></div>
                                    </div>
                                        <div className="row position-relative  align-items-stretch justify-content-between mx-auto g-3 mt-5  ">
                                            <div className="col-12">
                                                <div className="heroTxtWrapper pt-md-5 pt-1 h-100 roundedSlideOne position-relative d-flex align-items-center justify-content-center gx-4">
                                                    <div className="row px-3 align-items-stretch gy-3 gy-sm-4">
                                                        <div className="col-12 col-md-5 h-auto">

                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-sm-4 mt-2">
                                                                            <span className="numberSubheading">
                                                                               In-clinic Care
                                                                            </span>
                                                                            <div className="py-sm-4 py-2">
                                                                                bespoke, comprehensive tinnitus treatment
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-center mx-auto mt-md-4 mt-2"><Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>Find a Clinic <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2 h-auto d-flex justify-content-center align-items-center fs-1 fw-bold">
                                                            OR
                                                        </div>
                                                        <div className="col-12 col-md-5 h-auto">
                                                            <div className="roundedCircle h-100 position-relative">
                                                                <div className="row text-center">
                                                                    <div className="col-12">
                                                                        <div className="para mb-2 mt-2">
                                                                            <span className="numberSubheading">
                                                                              Virtual Health
                                                                            </span>
                                                                            <div className="py-4">
                                                                               convenient, accessible tinnitus treatment, tailored to you, wherever you live
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-center mx-auto mt-md-4 mt-2 text-nowrap"><Link onClick={openModal} className='text-decoration-none nuroBtn py-3'>Enquire about Neuromonics Virtual Health </Link></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className="container-fluid neuromonicsFluid position-relative bg-light py-lg-5 py-3" style={{ zIndex: 5 }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12">
                                <div className="row mx-0 heroBanner">
                                    <div className="col-12"><div className="heroHeading">What is Neuromonics?</div></div>
                                    <div className="col-12 d-block d-md-none mb-3">
                                        <div className="image"><img src={five} alt="" /></div>
                                    </div>
                                    <div className="col-12">
                                        <div className="paragraph">
                                            Discover relief with the Neuromonics Tinnitus Treatment. Our clinically- proven, patented, FDA-cleared approach pairs individually-tailored relaxation music therapy and spectrally-modified auditory stimulation with personalized one-on-one guided counselling, designed to retrain your brain and diminish tinnitus perception
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <Link to={FRONTEND_ROUTES.NEUROMONICS} className='text-decoration-none nuroBtn py-3'>Learn more <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 d-md-block d-none">
                                <div className="image"><img src={five} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid neuromonicsFluid position-relative bg-light py-lg-5 py-3" style={{ zIndex: 5 }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6 d-md-block d-none">
                                <div className="image">
                                    <img src={fivefive} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="row mx-0 heroBanner">
                                    <div className="col-12"><div className="heroHeading">What is Tinnitus?</div></div>
                                    <div className="col-12 d-block d-md-none mb-3">
                                        <div className="image"><img src={fivefive} alt="" /></div>
                                    </div>
                                    <div className="col-12"><div className="paragraph">
                                        Tinnitus, often experienced as a persistent ringing or buzzing in the ears, affects millions worldwide and can disrupt daily life. Learn more about this common condition and explore Neuromonics' innovative solutions for effective relief and management
                                    </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <Link to={FRONTEND_ROUTES.TINNITUS} className='text-decoration-none nuroBtn py-3'>Learn more <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Section new  */}

                <div className="container-fluid position-relative bg-white imageFluid py-lg-5 py-4" style={{ zIndex: 5 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                                            </svg>
                                            Services
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">Flexible treatment options to suit you</div>
                                    </div>
                                    {/* <div className="col-12">
                                        <div className="heroSubHeading">Experience the transformative benefits of Neuromonics. Our tailored approach offers effective tinnitus relief, seamlessly integrating into your daily lifestyle for enhanced well-being.</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container mt-4'>
                        <div className='row gap-lg-0 gap-5 '>
                        <div className='col-lg-6 pe-xl-5 pe-lg-3'>
                                    <div className='row pe-xl-4 pe-lg-2'>
                                        <div className='col-12'>
                                            <div className='cardNew'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='ImageCard'><img src={eleven} alt="" /></div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='contantBox'>

                                                            <div className='text-center heading pb-4'>In-Clinic Care</div>
                                                        <div className="">
                                                            <table className='card-table'>
                                                                <tr className='subHeading'>
                                                                    <td className="pb-2 pe-2"><span>One-on-One, Personalized Care:</span> </td>
                                                                    <td className="pb-2 ">comprehensive, face-to-face support from accredited clinicians</td>
                                                                </tr>
                                                                <tr className='subHeading'>
                                                                    <td className="pb-2 pe-2"><span>Access Advanced Diagnostic Tools:</span> </td>
                                                                    <td className="pb-2 ">cutting-edge diagnostics for precise assessment & treatment</td>
                                                                </tr>
                                                                <tr className='subHeading'>
                                                                    <td className="pb-2 pe-2"><span>Clinically Validated Setting:</span> </td>
                                                                    <td className="pb-2 ">treatment mirrors the clinical trial setting, ensuring tried & tested approach</td>
                                                                </tr>
                                                                <tr className='subHeading'>
                                                                    <td className="pb-2 pe-2"><span>Clinically Validated Setting:</span> </td>
                                                                    <td className="pb-2">treatment mirrors the clinical trial setting, ensuring tried & tested approach</td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <div className='subHeading pt-3'>Perfect for those seeking premium, bespoke, interactive healthcare exporionco </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                        <Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>Find a Clinic<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 ps-lg-5 ps-lg-3'>
                                <div className='row ps-xl-4 ps-lg-2'>
                                    <div className='col-12'>
                                        <div className='cardNew'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='ImageCard'><img src={ten} alt="" /></div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='contantBox'>

                                                        <div className='text-center heading pb-4'>Virtual Health</div>
                                                        <table className='card-table'>
                                                            <tr className='subHeading'>
                                                                <td className="pb-2 pe-2"><span>Direct from Neuromonics:</span> </td>
                                                                <td className="pb-2 ">Access treatment directly from the experts at a fixed, affordable price</td>
                                                            </tr>
                                                            <tr className='subHeading'>
                                                                <td className="pb-2 pe-2"><span>Telehealth Services & Support:</span> </td>
                                                                <td className="pb-2 ">all services delivered virtually through client portal or phone app</td>
                                                            </tr>
                                                            <tr className='subHeading'>
                                                                <td className="pb-2 pe-2"><span>Flexible Payment Options:</span> </td>
                                                                <td className="pb-2 ">Upfront vs Subscription model payment options available (enquire today)</td>
                                                            </tr>
                                                        </table>
                                                        <div className='subHeading pt-3'>Perfect for those seeking effective tinnitus treatment relief with the flexibility, affordability & comfort of remote treatment</div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                        <Link onClick={openModal} className='text-decoration-none nuroBtn py-3'>Enquire about Neuromonics Virtual Health</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section end  */}

                <div className="container-fluid position-relative bg-white imageFluid py-lg-5 py-3" style={{ zIndex: 5 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                                            </svg>
                                            Neuromonics
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">Benefits of Neuromonics</div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroSubHeading">Experience the transformative benefits of Neuromonics. Our tailored approach offers effective tinnitus relief, seamlessly integrating into your daily lifestyle for enhanced well-being.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="imageBox">
                            <div className="imageInnerBox active">
                                <div className={`imageWrapper position-relative ${isClicked ? 'clicked' : ''}`}
                                    onClick={handleClickk}>
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="image"><img src={one} alt="" srcSet="" /></div>
                                        </div>
                                    </div>
                                    <div className="row position-absolute imageTextWrapper mx-0">
                                        <div className="col-12">
                                            <div className="imageHead">Listen and Relax</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="imagePara">Ease your mind with therapeutic sounds, designed for relaxation and tinnitus relief.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imageInnerBox">
                                <div className={`imageWrapper position-relative ${isClicked ? 'clicked' : ''}`}
                                    onClick={handleClickk}>
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="image"><img src={slideTwo} alt="" srcSet="" /></div>
                                        </div>
                                    </div>
                                    <div className="row position-absolute imageTextWrapper">
                                        <div className="col-12">
                                            <div className="imageHead">While You Travel</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="imagePara">Travel with comfort, as our treatment reduces tinnitus distractions, making journeys more pleasant.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imageInnerBox">
                                <div className={`imageWrapper position-relative ${isClicked ? 'clicked' : ''}`}
                                    onClick={handleClickk}>
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="image"><img src={rr} alt="" srcSet="" /></div>
                                        </div>
                                    </div>
                                    <div className="row position-absolute imageTextWrapper">
                                        <div className="col-12">
                                            <div className="imageHead">During Light Exercise</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="imagePara">Complement a light exercise routine with calming sounds, aiding in focus and tinnitus management.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imageInnerBox">
                                <div className={`imageWrapper position-relative ${isClicked ? 'clicked' : ''}`}
                                    onClick={handleClickk}>
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="image"><img src={four} alt="" srcSet="" /></div>
                                        </div>
                                    </div>
                                    <div className="row position-absolute imageTextWrapper">
                                        <div className="col-12">
                                            <div className="imageHead">Listen During Your Favorite Hobby</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="imagePara">Integrate tinnitus therapy seamlessly into your hobbies for an enjoyable, calming experience.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imageInnerBox">
                                <div className={`imageWrapper position-relative ${isClicked ? 'clicked' : ''}`}
                                    onClick={handleClickk}>
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="image"><img src={five} alt="" srcSet="" /></div>
                                        </div>
                                    </div>
                                    <div className="row position-absolute imageTextWrapper">
                                        <div className="col-12">
                                            <div className="imageHead">Supported by One-on-One Counseling</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="imagePara">Benefit from personalized guidance, ensuring your treatment is as effective as possible.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imageInnerBox">
                                <div className={`imageWrapper position-relative ${isClicked ? 'clicked' : ''}`}
                                    onClick={handleClickk}>
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="image"><img src={eight} alt="" srcSet="" /></div>
                                        </div>
                                    </div>
                                    <div className="row position-absolute imageTextWrapper">
                                        <div className="col-12">
                                            <div className="imageHead">New, More Expansive Music Library</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="imagePara">Explore a broader range of tailored music, catering to diverse tastes and therapeutic needs.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-2">
                                <div className="image"><img src={six} alt="" srcSet="" /></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid testimonialFluid bg-white position-relative py-lg-5 py-3" style={{ zIndex: 5 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                                            </svg>
                                            Testimonials
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">Our Happy Clients</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container testimonialContainer mt-4">
                        {jsonDataSecond && jsonDataSecond ? (
                            <div className="row g-4">
                                <div className="col-12">
                                    <TestimonialComponent TestimonialData={jsonDataFirst} dir="ltr" />
                                </div>
                                <div className="col-12">
                                    <TestimonialComponent TestimonialData={jsonDataSecond} dir="rtl" />
                                </div>
                            </div>
                        ) :
                            <div className="col-12">
                                <TestimonialComponent TestimonialData={jsonDataFirst} dir="ltr" />
                            </div>
                        }
                    </div>
                </div>
                <div className="container-fluid position-relative bg-dark py-2 darkFluid" style={{ zIndex: 5 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Swiper
                                    // slidesPerView={1}
                                    spaceBetween={70}
                                    slidesPerView={'auto'}
                                    loop={true}
                                    freeMode={true}
                                    // centeredSlides= {true}
                                    speed={19000}
                                    allowTouchMove={true}
                                    autoplay={{
                                        delay: 10,
                                        enabled: true,
                                        disableOnInteraction: false,
                                        pauseOnMouseEnter: false,
                                    }}
                                    modules={[FreeMode, Autoplay]}
                                    className="mySwiper testimonialsSwiper textswiper"
                                >
                                    <SwiperSlide>
                                        <div className="textWrapper">
                                            <div className="row justify-content-center">
                                                <div className="col-auto textOne text-white">83% Neuromonics Success Rate</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="textWrapper">
                                            <div className="row justify-content-center">
                                                <div className="col-auto textOne text-white">10,000+ treatments delivered by Neuromonics</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="textWrapper">
                                            <div className="row justify-content-center">
                                                <div className="col-auto textOne text-white">50 million Americans suffer with persistent tinnitus</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                        {/* <div className="row align-items-center ">
                            <div className="col-lg-6 col-12"><div className="image"><img src={seven} alt="" /></div></div>
                            <div className="col-lg-6 col-12">
                                <div className="row mx-0 align-items-center py-4 py-lg-0">
                                    <div className="col-auto"><div className="percentage text-white"><strong>83%</strong></div></div>
                                    <div className="col">
                                        <div className="text">Neuromonics</div>
                                        <div className="text">Success Rate</div>
                                    </div>
                                    <div className="col-12 pt-2 pt-lg-0">
                                        <div className="para">Across 10 clinical trials conducted in the United States and Australia, involving 1,000+ clnical trial subjects, where success is defined as 40%+ reduction in TRQ score. NB: Subject weighted success rate across clinical trials 87%.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                <div className="row mx-0 align-items-center py-4 py-lg-0">
                                    <div className="col-12"><div className="percentage text-white"><strong>50 million</strong></div></div>
                                    <div className="col-12">
                                        <div className="textt">Americans suffer with persistent tinnitus</div>
                                        <div className="textt">American Tinnitus Association, 2023</div>
                                    </div>
                                    <div className="col-12">
                                        <div className="para mt-lg-4 mt-3"><a href="javascript:;" className='text-decoration-none text-white opacity-50'>Tinnitus is a common medical condition</a> afflicting millions of people worldwide.  50 million Americans suffer from persistent tinnitus with 2 million severely debilitated by the condition.  Often stemming from damage to the hair cells of the inner ear, tinnitus is the result of a complex interplay of auditory, limbic and other centres of the brain. 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 order-1 order-lg-2"><div className="image"><img src={six} alt="" /></div></div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center flex-column align-items-center my-lg-5 my-3">
                                <div className="mainHead">10,000+</div>
                                <div className="sub">Neuromonics treatments delivered</div>
                                <div className="min">as of October 2023</div>
                                <div className="para mt-2 mt-lg-0">Neuromonics directly addresses the underlying neurophysiology of tinnitus, providing near-term relief and long-term habituation to your tinnitus.  Read a book, go for a walk; passively listen to customised, spectrally-modified relaxation music, while Neuromonics retrains your limbic system’s response to tinnitus. </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="container-fluid serviceFluid position-relative pt-lg-5 pt-3 " style={{ zIndex: 5, background: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                                            </svg>
                                            Services
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">Our Services</div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroSubHeading">At Neuromonics, we provide a comprehensive, step-by-step journey towards effective tinnitus management, tailored to your unique needs.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row overflow-y-auto flex-nowrap">
                            <div className="col-xl-4 cardBox pb-lg-5 pb-3">
                                <div className="cardWrapper">
                                    <div className="row mx-0 position-relative h-100">
                                        <div className="col-12"><div className="cardImg"><img src={eight} alt="" /></div></div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <div className="cardTxtWrapper">
                                                <div className="head mb-2">Visit a Neuromonics Audiologist </div>
                                                <div className="para">Begin with a thorough evaluation to understand your specific tinnitus profile and hearing needs.  Find a Clinic
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 cardBox pb-lg-5 pb-3">
                                <div className="cardWrapper">
                                    <div className="row mx-0 position-relative h-100">
                                        <div className="col-12"><div className="cardImg"><img src={seven} alt="" /></div></div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <div className="cardTxtWrapper">
                                                <div className="head mb-2">Individually-tailored, Spectrally Modified Relaxation Music</div>
                                                <div className="para">Experience customized music, fine-tuned to your hearing profile, designed for 2+ hours of daily listening for maximum therapeutic impact.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 cardBox pb-lg-5 pb-3">
                                <div className="cardWrapper">
                                    <div className="row mx-0 position-relative h-100">
                                        <div className="col-12"><div className="cardImg"><img src={two} alt="" /></div></div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <div className="cardTxtWrapper">
                                                <div className="head mb-2">One-on-One Guided Counselling Sessions</div>
                                                <div className="para">Receive personalized guidance and support for the six month treatment program, enhancing your journey to tinnitus understanding, relief and well-being
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid blogFluid position-relative py-lg-5 py-3" style={{ zIndex: 5, background: '#EAE6E1' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                                            </svg>
                                            Our Blog
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">Recent Posts</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <BlogComponent />
                    </div>
                </div>
                <Footer />
            </div>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Your Modal"
                className="custom-modal"
                style={{
                    content: {
                        // width: '50%',
                        height: '90%',
                        margin: 'auto',
                        left: '356px',
                        marginTop: '20px',

                        backgroundColor: '#ffffff',
                        border: '1px solid',
                        overflow: "auto",
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    overlay: {
                        backgroundColor: 'rgb(255 255 255 / 88%)',
                        zIndex: "99999",
                    }
                }}
            >
                <ModalHeader style={{
                    padding: "10px",
                    background: "#517991",
                    color: "#fff",
                    position: "sticky",
                    top: "0",
                    zIndex: "100000",
                }} className='row gx-0 align-items-center justify-content-center'>
                    <div className="col-10">
                        <div className="enqnumLogo">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className="col-2"><Link className='text-light d-flex justify-content-end' onClick={closeModal} ><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "24px", width: "24px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path></svg></Link></div>

                </ModalHeader>
                <ModalBody style={{
                    overflow: "auto",
                    maxHeight: "73%%",
                    padding: "20px",
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div className="col-12">
                        <div className="enquiretxt mb-2">
                            Enquiry Form
                        </div>
                        {/* <p className="enqpara">
                            Please fill out the form below to send your inquiry.
                        </p> */}
                    </div>
                    <div className="col-12 flex-fill">
                        {errorMsg && (
                            <Alert text={errorMsg} type="error" />
                        )}
                        {successMsg && (
                            <Alert text={successMsg} type="success" />
                        )}
                        {isLoadingModal && <Loader isEnquieySpinner={true} />}
                        {!isLoadingModal && (
                            <div className="MainForm">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="firstName"
                                                name="firstName"
                                                placeholder=""
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            <label htmlFor="firstName" className='formControlLable'>
                                                <div className="labelTxt">
                                                    Name *
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="email"
                                                name="email"
                                                placeholder=""
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label htmlFor="email" className='formControlLable'>
                                                <div className="labelTxt">
                                                    Email *
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="state"
                                                name="state"
                                                placeholder=""
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                            <label htmlFor="state" className='formControlLable'>
                                                <div className="labelTxt">
                                                    State
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                id="country"
                                                name="country"
                                                placeholder=""
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                            />
                                            <label htmlFor="country" className='formControlLable'>
                                                <div className="labelTxt">
                                                    Country
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-3 ">
                                            <textarea
                                                className="form-control formcontroltxtCustom shadow-none maptextarea"
                                                rows="3" placeholder="Leave a comment here"
                                                name="message"
                                                id="message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            ></textarea>
                                            <label htmlFor="message">
                                                <div className="labelTxt">
                                                    Message*
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 mx-auto">
                                        <div className="signInButton mt-3 text-center">
                                            <button className='signinBtn' onClick={handleEnquire}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </ModalBody>
            </ReactModal>
        </>
    )
}

export default HomePage