import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../components/frontendComponents/header/Header'
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../common/firebase';
import Loader from '../../extra/Loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FILENAME, STORAGE, STORAGEURL } from '../../../constants/StorageConst';
import Footer from '../../../components/frontendComponents/footer/Footer';

const SingleJournal = () => {
    const scrollContainer = useRef(null)
    const slug = useParams();
    const [jsonData, setJsonData] = useState(null);
    const [getMainCategory, setGetMainCategory] = useState(null);
    const [getSubCategory, setGetSubCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigator = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const jsonFileRef = ref(storage, STORAGEURL + '/' + STORAGE.JOURNALS + '/' + FILENAME.JOURNALSFILE);
            try {
                const url = await getDownloadURL(jsonFileRef);
                const response = await fetch(url);
                const data = await response.json();
                const categories = data.categories;
                const result = findItemBySlug(categories, slug.slug);
                if (result) {
                    const { category, subcategory, item } = result;
                    setGetMainCategory(category);
                    setGetSubCategory(subcategory);
                    setJsonData(item);
                } else {
                    navigator('/404'); 
                }
                setIsLoading(false)
            } catch (error) {
                console.error('Error fetching or parsing JSON file:', error);
            }
        };
        fetchData();
    }, []);

    const findItemBySlug = (categories, slug) => {
        for (const category in categories) {
            if (categories.hasOwnProperty(category)) {
                const categoryItems = categories[category];

                if (Array.isArray(categoryItems) && categoryItems.length > 0) {
                    const indexOfObject = categoryItems.findIndex(item => item.slug === slug);
                    if (indexOfObject !== -1) {
                        return { category, subcategory: '', item: categoryItems[indexOfObject] };
                    }
                } else if (typeof categoryItems === 'object') {
                    for (const subCategory in categoryItems) {
                        if (categoryItems.hasOwnProperty(subCategory)) {
                            const subCategoryItems = categoryItems[subCategory];

                            if (Array.isArray(subCategoryItems) && subCategoryItems.length > 0) {
                                const indexOfObject = subCategoryItems.findIndex(item => item.slug === slug);
                                if (indexOfObject !== -1) {
                                    return { category: category, subcategory: subCategory, item: subCategoryItems[indexOfObject] };
                                }
                            }
                        }
                    }
                }
            }
        }

        return null;
    }

    return (
        <>
            <div className='frontendOuter paddingTopHeader' ref={scrollContainer}>
                <Header scrollContainer={scrollContainer} secondHeader={true} />
                <div className="container-fluid journalsFluid py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 px-0">
                                <div className="row heroBanner mx-0">
                                    <div className="col-12">
                                        <div className="row mx-0">
                                            <div className="col-auto px-0">
                                                <div className="heroPill"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>Neuromonics Resources</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading && <Loader />}
                        {!isLoading && jsonData && (
                            <>
                                <div className="col-12 px-0">
                                    <div className="row heroBanner mx-0">
                                        <div className="col-12">
                                            <div className="row mx-0 mb-2">
                                                <div className="col-12 px-0">
                                                    <div className="row mx-0">
                                                        <div className="col px-0">
                                                            <div className="heroHeading categoryColor">{getMainCategory}</div>
                                                            {(getSubCategory && (
                                                                <div className="heroHeadingg">{getSubCategory}</div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-12 col-sm-6 col-lg-4 position-relative">
                                        <div className="JournalsCard">
                                            <div className="row h-100">
                                                <div className="col-12 mb-4">
                                                    <div className="title"><Link to={jsonData.link} target='_blank' className="link">{jsonData.title}</Link></div>
                                                </div>
                                                <div className="col-12 px-0 mt-auto">
                                                    <div className="row mx-0 align-items-end">
                                                        <div className="col">
                                                            <div className="col-12 mt-1">
                                                                <div className="authors"><span> {jsonData.citation}</span></div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="authors">{jsonData.authors.join(', ')}</div>
                                                            </div>
                                                            {/* <div className="col-auto">
                                                                <Link to={jsonData.slug} target='_blank' className="link text-dark">Download</Link>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {/* footer start */}
                <Footer />
                {/* footer end */}
            </div>
        </>
    )
}

export default SingleJournal