import React, { useEffect, useState } from 'react'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import DataTable from 'react-data-table-component';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../common/firebase';
import Loader from '../extra/Loader'
import { Link } from 'react-router-dom'
import { ADMIN_ROUTES } from '../../constants/RouteConst'

const TemplateList = () => {

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const templateRef = collection(db, `templates`);
      const querySnapshot = await getDocs(templateRef);
      if (querySnapshot.empty) {
        console.log('No documents found in templates');
        return;
      }
      const resultData = [];
      for (const docn of querySnapshot.docs) {
        const templatesSnapshot = docn.data();
        resultData.push({
          id: docn.id,
          ...templatesSnapshot,
        });
      }
      setData(resultData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching documents:', error.message);
    }
  };

  const columns = [
    {
      name: 'Type',
      selector: row => row.type,
      sortable: true,
      filter: true,
    },
    {
      name: 'Purpose/Description',
      selector: row => row.purpose,
      sortable: true,
      filter: true,
    },
    {
      name: 'Subject',
      selector: row => row.subject,
      sortable: true,
      filter: true,
    },
    {
      name: 'Modified On',
      selector: row => {
        const updatedAt = row.updated_at;

        if (updatedAt && updatedAt.seconds) {
          const updatedDate = new Date(updatedAt.seconds * 1000);
          return updatedDate.toLocaleString([], {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
        } else {
          return '';
        }
      },
      sortable: true,
      filter: true,
    },
    {
      name: 'Actions',
      cell: (row) => <CustomActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const CustomActionsCell = ({ row }) => {
    const template = row;
    const updateURL = `${ADMIN_ROUTES.EDIT_TEMPLATE}/${template.id}`;
    return (
      <div className='col-auto'>
        <Link to={updateURL} className="text-decoration-none">
          <button className='editBtn btn btn-outline-dark'>
            <div className="editPencilImg d-flex align-items-center gap-1">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }} xmlns="http://www.w3.org/2000/svg"><path d="M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z"></path></svg>Details
            </div>
          </button>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            <div className='row'>
              <div className='col-12'>
                <div className='commonCard'>
                  <div className='card-header '>
                    <div className="col-12">
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h4 className='SectionHeadingMain fw-bold'>Manage Template</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    {isLoading && <Loader />}
                    <div className='templateTable managepatientsTable'>
                      {!isLoading && data && (
                          <DataTable
                            fixedHeader
                            fixedHeaderScrollHeight="700px"
                            highlightOnHover
                            noContextMenu
                            pagination
                            responsive
                            subHeaderAlign="right"
                            subHeaderWrap
                            columns={columns}
                            data={data}
                          />
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateList