import React from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../constants/RouteConst';

const PaymentFailed = () => {
  return (
    <div class="box">
        <div class="error custom-alert">
            <div class="alert-body">
                Payment Failed !
            </div>
        </div>
        <div className="homeBtnheaderDiv">
          <Link to={ADMIN_ROUTES.LOGIN_URL} className='text-text-decoration-none homeBtnheaderDivanc'>Go to Dashboard</Link>
        </div>
    </div>
  )
}

export default PaymentFailed;