import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../common/firebase';
import { FILENAME, STORAGE, STORAGEURL } from '../../constants/StorageConst';
import Loader from '../extra/Loader';

const TRQModal = ({ TRQResponse }) => {
    const [jsonData, setJsonData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const jsonFileRef = ref(storage, `${STORAGEURL}/questionnaires/TRQ.json`);
            try {
                const url = await getDownloadURL(jsonFileRef);
                const response = await fetch(url);
                const data = await response.json();
                setJsonData(data);
                setIsLoading(false)
            } catch (error) {
                console.error('Error fetching or parsing JSON file:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {isLoading && <Loader />}
            {!isLoading && jsonData && (
                jsonData.map((item, index) => (
                    <div key={index} style={{ backgroundColor: "#f0f0f0", padding: "9px", margin: "10px" }}>
                        <p className='mb-1'>Question {index+1}: {item}</p>
                        <p className='mb-0'><strong>Answer: {TRQResponse.find(response => response.index === index)?.value}</strong></p>
                    </div>
                ))
            )}
        </>
    );
};

export default TRQModal;
