import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../common/firebase';
import { FILENAME, STORAGE, STORAGEURL } from '../../../constants/StorageConst';
import { Link } from 'react-router-dom';
import Alert from '../../../components/alert/Alert';
import { createEvent } from '../../../common/helper';
import { getData, sendEmail } from '../../../common/fetch';
import { getAuth, signInAnonymously, signOut } from 'firebase/auth';
import { checkEmailExists } from '../../../common/helper';
import { EventType } from '../../../constants/EventConst';
import { MAP_API } from '../../../common/map';
import axios from 'axios';
import { isClickableInput } from '@testing-library/user-event/dist/utils';

const containerStyle = {
    height: '400px'
};

const center = {
    lat: 25,
    lng: -10,
};

const monochromeMapStyle = [
    {
        featureType: 'all',
        elementType: 'all',
        stylers: [
            // { saturation: 100 }, 
            { lightness: 20 },
            { hue: '#517991' },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            { color: '#ffffff' },
            { weight: 1 },
        ],
    }
];

const mapOptions = {
    styles: monochromeMapStyle,
};

const MapMarker = ({ selectedLocation, isClicked }) => {
    const [jsonData, setJsonData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [map, setMap] = useState(null)
    const [selectedMarker, setSelectedMarker] = useState(null);

    // To handle Enquire Form
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [message, setMessage] = useState("");

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: MAP_API
    })

    // const onLoad = useCallback(function callback(map) {
    //     const bounds = new window.google.maps.LatLngBounds(center);
    //     map.fitBounds(bounds);
    //     setMap(map)
    // }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const jsonFileRef = ref(storage, STORAGEURL + '/' + STORAGE.CLINICS + '/' + FILENAME.CLINICSFILENAME);

            try {
                const url = await getDownloadURL(jsonFileRef);
                const response = await fetch(url);
                const data = await response.json();

                let clinicsWithDistance = data;

                if (selectedLocation && isClicked) {
                    const result = await getLatLngByZipCode(selectedLocation);
                    if (result.lat && result.lng) {
                        clinicsWithDistance = data.map(clinic => {
                            const distance = calculateDistance(
                                clinic.lat,
                                clinic.lng,
                                result.lat,
                                result.lng
                            );
                            return { ...clinic, distance };
                        });
                    }
                    // clinicsWithDistance.filter(clinic => {
                    //     return (
                    //         clinic.clinicName.toLowerCase().includes(selectedLocation.toLowerCase()) ||
                    //         clinic.country.toLowerCase().includes(selectedLocation.toLowerCase()) ||
                    //         clinic.zipCode.includes(selectedLocation) ||
                    //         clinic.clinicAddress.toLowerCase().includes(selectedLocation.toLowerCase())
                    //     );
                    // });
                } else {
                    // const filteredClinics = clinicsWithDistance;
                }
                const filteredClinics = clinicsWithDistance.filter(clinic => {
                    return (
                        clinic.clinicName.toLowerCase().includes(selectedLocation.toLowerCase()) ||
                        clinic.country.toLowerCase().includes(selectedLocation.toLowerCase()) ||
                        clinic.zipCode.includes(selectedLocation) ||
                        clinic.clinicAddress.toLowerCase().includes(selectedLocation.toLowerCase())
                    );
                });
                setJsonData(filteredClinics);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching or parsing JSON file:', error);
            }
        };
        fetchData();
    }, [selectedLocation, isClicked]);

    // Get the Latitude and Longitude of the selected location
    const getLatLngByZipCode = async (zipCode) => {
        if (zipCode) {
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${MAP_API}`;

            try {
                const response = await axios.get(apiUrl);
                const { results } = response.data;
                if (results.length > 0) {
                    const { lat, lng } = results[0].geometry.location;
                    return { lat, lng };
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }
        return true;
    };

    // calculate the distance between the selected location and clinic location
    const calculateDistance = (lat1, lng1, lat2, lng2) => {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lng1 - lng2;
        const radtheta = (Math.PI * theta) / 180;
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515; // Convert to miles

        return dist.toFixed(2);
    };

    // Handle the enquire by Unauthorized or Authorized user from Public web
    const handleEnquire = async () => {
        try {
            setSuccessMsg('');
            setErrorMsg('');

            const isValidEmail = (email) => {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailRegex.test(email);
            };

            const mandatoryFields = [firstName, email, zipCode, message];

            for (const field of mandatoryFields) {
                if (!field) {
                    setErrorMsg('Please complete all mandatory fields (indicated by an asterisk).');
                    return;
                }
            }

            if (!isValidEmail(email)) {
                setErrorMsg('Invalid email format. Please enter a valid email address.');
                return;
            }

            const auth = getAuth();
            const checkClientEmailExists = await checkEmailExists(email);

            const userId = checkClientEmailExists ? (await getData(email)).uid : (await signInAnonymously(auth)).user.uid;

            const data = {
                userId: userId,
                type: EventType.ALTTREATMENT,
                details: {
                    firstName: firstName,
                    emailAddress: email,
                    zipCode: zipCode,
                    message: message,
                }
            };

            await createEvent(data); //create event for enquiry data by unauthorized user from public website

            const templateContent = `<p>Hi,<br/> A new request for alternative treatment options from a client had been received. Below are the details of the inquiry:</p><p>Name: ${firstName}</p><p>Email Address: ${email}</p><p>Zip Code/Postal Code: ${zipCode}</p><p>Message: ${message}</p>`;

            await sendEmail('lachlan.james@neuromonics.com', 'Request Neuromonics Alternative Treatment', templateContent);

            setErrorMsg('');
            setFirstName('');
            setEmail('');
            setZipCode('');
            setMessage('');
            setSuccessMsg('Enquiry sent successfully!');
            await signOut(auth);

        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setErrorMsg('This email is already in use. Please use a different email.');
            } else {
                setErrorMsg('An error occurred. Please try again later.');
            }
        }
    };

    //customized svg icon for marker
    const svgIcon = '<svg width="70" height="70" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx = "20.6719" cy = "16.7344" r = "5.57812" fill = "white" /><path d="M21 6.77734C15.5636 6.77734 11.1562 11.1847 11.1562 16.6211C11.1562 20.9556 17.4149 30.4765 19.9513 34.1581C20.5236 34.9882 21.4764 34.9882 22.0487 34.1581C24.5851 30.4758 30.8438 20.9563 30.8438 16.6211C30.8438 11.1847 26.4364 6.77734 21 6.77734ZM21 11.3711C22.3924 11.3711 23.7277 11.9242 24.7123 12.9088C25.6969 13.8933 26.25 15.2287 26.25 16.6211C26.25 17.3105 26.1142 17.9932 25.8504 18.6302C25.5865 19.2671 25.1998 19.8459 24.7123 20.3334C24.2248 20.8209 23.646 21.2076 23.0091 21.4715C22.3721 21.7353 21.6894 21.8711 21 21.8711C19.6076 21.8711 18.2723 21.318 17.2877 20.3334C16.3031 19.3488 15.75 18.0135 15.75 16.6211C15.75 15.2287 16.3031 13.8933 17.2877 12.9088C18.2723 11.9242 19.6076 11.3711 21 11.3711Z" fill="#2C2B6D" /></svg >';

    // styled the marker icon
    const markerIcon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`,
        scaledSize: new window.google.maps.Size(50, 50),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(20, 40),
    };

    return (
        <>
            {
                <div className="row">
                    <div className="col-4 overflow-y-auto px-0">
                        <div className="MapDetails">
                            {
                                (jsonData && jsonData.length === 0) ? (
                                    <div className='px-2 px-sm-2 px-lg-4 py-4'>
                                        <h3 className='blogPara'>There are no Neuomonics clinics near you..</h3>
                                        <p>There doesn't appear to be any Neuromonics clinics near you. If you can't travel to an existing clinic, please contact Neuromonics today about alternative treatment options</p>
                                        <div className="col-12 ">
                                            {errorMsg && (
                                                <Alert text={errorMsg} type="error" />
                                            )}
                                            {successMsg && (
                                                <Alert text={successMsg} type="success" />
                                            )}
                                            <div className="MainForm py-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-floating mb-3">
                                                            <input type="text"
                                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                                id="firstName"
                                                                name="firstName"
                                                                placeholder=""
                                                                value={firstName}
                                                                onChange={(e) => setFirstName(e.target.value)}
                                                            />
                                                            <label htmlFor="firstName" className='formControlLable'>
                                                                <div className="labelTxt">
                                                                    Name *
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-floating mb-3">
                                                            <input type="text"
                                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                                id="email"
                                                                name="email"
                                                                placeholder=""
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                            <label htmlFor="email" className='formControlLable'>
                                                                <div className="labelTxt">
                                                                    Email *
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-floating mb-3">
                                                            <input type="text"
                                                                className="form-control shadow-none border formcontrolCustom modalRound"
                                                                id="zipcode"
                                                                name="zipcode"
                                                                placeholder=""
                                                                value={zipCode}
                                                                onChange={(e) => setZipCode(e.target.value)}
                                                            />
                                                            <label htmlFor="zipcode" className='formControlLable'>
                                                                <div className="labelTxt">
                                                                    Zip Code / Postal Code *
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-floating mb-3 ">
                                                                <textarea
                                                                    className="form-control formcontroltxtCustom shadow-none maptextarea"
                                                                    rows="3" placeholder="Leave a comment here"
                                                                    name="message"
                                                                    id="message"
                                                                    value={message}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                ></textarea>
                                                                <label htmlFor="message">
                                                                    <div className="labelTxt">
                                                                        Message*
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mx-auto mx-sm-0">
                                                        <div className="signInButton mt-3 text-center signBtnFind">
                                                            <button className='signinBtn' onClick={handleEnquire}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    jsonData && jsonData.map((data, index) => (
                                        <div className="mapCard" key={index}>
                                            <div className="mapHead">
                                                {data.clinicName}
                                            </div>
                                            <div className="mapAddress py-3">
                                                {data.clinicAddress}
                                            </div>
                                            {data && data.distance && (
                                                <div className="distanceDiv py-3">
                                                    <div className="distIcon">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className='h-100 w-100' xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 1.74.5 3.37 1.41 4.84.95 1.54 2.2 2.86 3.16 4.4.47.75.81 1.45 1.17 2.26.26.55.47 1.5 1.26 1.5s1-.95 1.25-1.5c.37-.81.7-1.51 1.17-2.26.96-1.53 2.21-2.85 3.16-4.4C18.5 12.37 19 10.74 19 9c0-3.87-3.13-7-7-7zm0 9.75a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z"></path></svg>
                                                    </div>
                                                    <div className="distNumber">
                                                        {data.distance} mi
                                                    </div>
                                                </div>
                                            )}
                                            {data && data.phone && (
                                                <div className="telPhone">
                                                    T: <span><Link to={'tel:' + data.phone} className='telAnc'>{' ' + data.phone}</Link></span>
                                                </div>
                                            )}
                                            {data && data.email && (
                                                <div className="telPhone py-2">
                                                    E: <span><Link to={'mailto:' + data.email} className='telAnc'>{' ' + data.email}</Link></span>
                                                </div>
                                            )}
                                            <div className="mapBtn">
                                                <Link to={'https://' + data.URL} className='nuroBtn visitBtn text-decoration-none py-2' target='_blank'>
                                                    Visit Website
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path></svg>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    </div>
                    <div className="col-8 px-0">
                        <div className="mapDiv">
                            {
                                isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={2}
                                        // onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        options={mapOptions}

                                    >
                                        {jsonData && jsonData.map((item, itemIndex) => (
                                            <>
                                                <Marker
                                                    key={itemIndex}
                                                    position={{ lat: item.lat, lng: item.lng }}
                                                    icon={markerIcon}
                                                    onClick={() => {
                                                        setSelectedMarker(item);
                                                    }}
                                                />
                                            </>
                                        ))
                                        }
                                        {selectedMarker && (
                                            <InfoWindow
                                                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                                                onCloseClick={() => setSelectedMarker(null)}
                                                className='mapModal'
                                            >
                                                <div>
                                                    <div className="mapHeading">
                                                        {selectedMarker.clinicName}
                                                    </div>
                                                    <div className="mapDescription">
                                                        {selectedMarker.clinicAddress}
                                                    </div>
                                                    {selectedMarker && selectedMarker.email && (
                                                        <div className="mapEmail d-flex align-items-center">
                                                            <div className="labelMap">
                                                                E:
                                                            </div>
                                                            <Link to={'mailto:' + selectedMarker.email}>
                                                                {' ' + selectedMarker.email}
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {selectedMarker && selectedMarker.phone && (
                                                        <div className="mapPhone d-flex align-items-center">
                                                            <div className="labelMap">
                                                                T:
                                                            </div>
                                                            <Link to={'tel:' + selectedMarker.phone}>
                                                                {' ' + selectedMarker.phone}
                                                            </Link>
                                                        </div>
                                                    )}
                                                    <div className="mapButton">
                                                        <Link to={'https://' + selectedMarker.URL} className='mapBtnanc d-flex align-items-baseline gap-2 justify-content-center' target='_blank'>
                                                            <div className="mapAncTxt">
                                                                Visit Website
                                                            </div>
                                                            <div className="svgMapIcon">
                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className='h-100 w-100' xmlns="http://www.w3.org/2000/svg"><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"></path></svg>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </GoogleMap>
                                ) : <></>

                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MapMarker