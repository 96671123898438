
import React from 'react'
import { Offcanvas } from 'react-bootstrap';
import logo from '../../../assets/common/img/logo.png';
import { Link } from 'react-router-dom';
import logoColoured from '../../../assets/common/img/nuroColur.png';

import { ADMIN_ROUTES, FRONTEND_ROUTES } from '../../../constants/RouteConst';

const HeaderOffcanvas = ({ show, setShow }) => {
    const handleClose = () => setShow(false);
    return (
        <>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <div className="logo"><img src={logoColoured} alt="" /></div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        <div className="col-12">
                            <ul className='navlinks m-0 p-p list-unstyled'>
                                <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.TINNITUS}>What is Tinnitus?</Link></li>
                                <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.NEUROMONICS}>Neuromonics</Link></li>
                                <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.JOURNALS}>Resources</Link></li>
                                {/* <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.ABOUT}>About</Link></li> */}
                                <li className='navLi'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.BLOGS}>Blog</Link></li>
                                <li className='navLi mb-2 mt-4'><Link className='navAnchor text-dark' to={ADMIN_ROUTES.LOGIN_URL}>Login</Link></li>
                            </ul>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default HeaderOffcanvas