import { collection, doc, updateDoc } from "firebase/firestore";
import React, { useState, useLayoutEffect, useEffect } from "react";
import { db } from "./firebase";
import { EventType } from "../constants/EventConst";
import { createEvent } from "./helper";

/* Common for Range Slider with handle updation in treatment Settings */
const RangeSlider = ({ id, initValue, name, uid, selectedClinician ,onChange }) => { // onChnage is callback function for new updated value 
  const [inputVal, setInputVal] = useState(initValue);

  const handleChange = async (e) => {
    setInputVal(parseInt(e.target.value, 10));
    initValue = e.target.value;
    onChange(e.target.value);
    const userAudioRef = doc(collection(db, 'userAudio'), uid);
    const data = {
      userId: uid,
      type: EventType.CLIENTSETTINGS,
      details: {
        value: initValue,
        clinicianUID: doc(db, selectedClinician),
      },
    };
    await createEvent(data); // Create event for changes in treatment by clinician in clinician userEvents

    if (name === 'neuralStimulus'){
      await updateDoc(userAudioRef, {
        neuralStimulus: parseInt(e.target.value, 10),
      });
      data.details.settingChanged = 'neuralStimulus';
    }
    if (name === 'balance') {
      await updateDoc(userAudioRef, {
        balance: parseInt(e.target.value, 10),
      });
      
      data.details.settingChanged = 'balance';
    }

    data.userId = selectedClinician.split('/').pop();;
    delete data.details.clinicianUID;
    data.details.clientUID = doc(db, 'userProfiles', uid); 
    await createEvent(data); // Create event for changes in treatment by clinician in client userEvents
  };

  useEffect(() => {
    setInputVal(initValue);
  }, [initValue]);

  
  useEffect(() => {
      const range = document.getElementById(id);
      const tooltip = document.getElementById(`${id}-tooltip`);
  
      const setValue = () => {
        const newValue = Number(((range.value - range.min) * 100) / (range.max - range.min));
        const newPosition = 16 - newValue * 0.32;
  
        tooltip.innerHTML = `<span>${range.value}</span>`;
        tooltip.style.left = `calc(${newValue}% + (${newPosition}px))`;
        range.style.setProperty("--range-progress", `calc(${newValue}% + (${newPosition}px))`);
  
      };
      setValue();
      range.addEventListener("input", setValue);
      return () => {
        range.removeEventListener("input", setValue);
      };
  });

  return (
    <div className="range-slider">
      <div id={`${id}-tooltip`} className="tooltip" ></div>
      <input
        id={id}
        type="range"
        className="range"
        step="1"
        value={inputVal}
        min="-10"
        max="10"
        // disabled
        onChange={handleChange}
      />
    </div>
  );
};

export default RangeSlider;
