export const ADMIN_ROUTES = {
    LOGIN_URL : '/login',
    SIGNUP_URL : '/signup',
    DASHBOARD_URL : '/dashboard',
    FORGET_PASSWORD_URL : '/forget',
    PROFILE: '/my-profile',
    LOGOUT_URL: '/logout',
    ADD_PATIENT: '/add-patient',
    PATIENT_LIST: '/patient-list',
    PAGE_NOT_FOUND_URL: '/pagenotfound',
    INTERNAL_SERVER_URL: '/internalserver',
    LOADER_URL: '/loader',
    MANAGE_PATIENTS_URL:'/manage-patients',
    MANAGE_ALL_PATIENTS_URL: '/manage-all-patients',
    MANAGE_CLINICIAN_URL: '/manage-clinician',
    APPROVAL_URL:'/approval',
    MANAGE_CLIENT_URL:'/manageclient',
    ACCESS_DENIED_URL:'/accessdenied',
    COUPONS_URL:'/coupons',
    EDIT_COUPON:'/edit-coupon',
    ADD_COUPON: '/add-coupon',
    MARKETING_MATERIALS:'/supporting-materials',
    MANAGE_USERS_URL:'/manage-users',
    ADD_ADMIN: '/add-admin',
    EDIT_USER: '/edit-user',
    MANAGE_TEMPLATE: '/manage-template',
    EDIT_TEMPLATE: '/edit-template',
    EDIT_REDIRECT: '/edit-redirect',
}
export const FRONTEND_ROUTES = {
    HOME_PAGE: '/',
    BLOGS: '/blogs',
    JOURNALS: '/resources',
    BLOGSLISTCHANGE: '/blogListChange',
    BLOG_DETAILS: '/blog',
    ABOUT: '/about',
    TINNITUS: '/what-is-tinnitus',
    NEUROMONICS: '/neuromonics',
    FINDCLINIC: '/find-a-clinic',
    FIND_PAGE: '/findpage',
    PAGE_NOT_FOUND: '/page-not-found',
    PRIVACYPOLICY: '/privacy-policy',
    PAYMENT_SUCCESSFUL:'/payment-successful',
    PAYMENT_FAILED:'/payment-failed'
}