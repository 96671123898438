import React from 'react';
import InternalServerImg from '../../assets/admin/img/internal01.jpg'


const InternalServer = ()=>{
    return(
        <>
        <div className="adminPAgeFound">
            <div className="container-fluid h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12">
                        <div className="MainPage text-center pt-5 h-100">
                            <div className="number">
                                500
                            </div>
                            <div className="heading pt-2 pb-3 pb-md-4">
                                Internal Server Error
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                                    <div className="internalImg ">
                                        <img src={InternalServerImg} className="img-fluid w-100 h-100 object-fit-cover" alt="tableAccordion" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default InternalServer