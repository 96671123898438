import React from 'react';
import pageNotFound from '../../assets/admin/img/404pageAgain.png'


const PageNotFound = ()=>{
    return(
        <>
        {/* <div className="adminPAgeFound">
            <div className="container-fluid h-100">
                <div className="row align-items-center justify-content-center h-100">
                    <div className="col-12 px-0">
                        <div className="MainPage text-center pageFound">
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="adminPAgeFound">
            <div className="container-fluid h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12">
                        {/* <div className="MainPage text-center pt-5 h-100">
                            <div className="number">
                                500
                            </div>
                            <div className="heading pt-2 pb-3 pb-md-4">
                                Internal Server Error
                            </div> */}
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                                    <div className="internalImg ">
                                        <img src={pageNotFound} className="img-fluid w-100 h-100 object-fit-cover" alt="tableAccordion" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound