import React, { useEffect, useState } from 'react'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import DataTable from 'react-data-table-component';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../../common/firebase';
import Loader from '../extra/Loader'
import { Link } from 'react-router-dom'
import { ADMIN_ROUTES } from '../../constants/RouteConst'
import { SuccessBadge, WarningBadge } from '../../components/badges/Badges'
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';

const PatientListing = () => {

  const [data, setData] = useState();
  const [finalData, setFinalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState('');

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const docid = localStorage.getItem('docid');
      try {
        const resultEvent = [];
        const userSettingsRef = collection(db, `${DATABASECOLLECTION.USERPROFILES}/${docid}/${DATABASECOLLECTION.OTHERSETTINGS}`);
        const userSettingsSnapshot = await getDocs(userSettingsRef);

        if (!userSettingsSnapshot.empty) {
          const clientUIDsReferences = userSettingsSnapshot.docs[0];
          const clientUIDsDataU = clientUIDsReferences.data().clientUIDs || [];

          const clientUIDsDataPromises = clientUIDsDataU.map(async (clientUIDReference) => {
            const clientUIDSnapshot = await getDoc(clientUIDReference);

            if (clientUIDSnapshot.exists()) {
              const clientUIDData = clientUIDSnapshot.data();
              const clientUIDReferenceValue = clientUIDReference.path;
              const otherSettingsCollectionRef = collection(db, `${clientUIDReferenceValue}/${DATABASECOLLECTION.OTHERSETTINGS}`);
              const otherSettingsSnapshot = await getDocs(otherSettingsCollectionRef);

              if (!otherSettingsSnapshot.empty) {
                const otherSettingsData = otherSettingsSnapshot.docs.map(doc => doc.data());
                clientUIDData.uid = clientUIDSnapshot.id;
                clientUIDData.activationCode = otherSettingsData[0].activationCode;
                const activationCodeDoc = doc(collection(db, DATABASECOLLECTION.ACTIVATIONCODES), otherSettingsData[0].activationCode);
                const activationDocumentSnapshot = await getDoc(activationCodeDoc);
                const activationSnapshot = activationDocumentSnapshot.data();
                clientUIDData.isUsed = activationSnapshot?.isUsed;
              } else {
                console.log(`Something went wrong. Please try again`);
              }
              resultEvent.push(clientUIDData);
              return clientUIDData;
            } else {
              return null;
            }
          });
          await Promise.all(clientUIDsDataPromises);
        } else {
          console.log('Document does not exist');
        }
        setData(resultEvent);
        setFinalData(resultEvent);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching documents:', error.message);
      }
    };
    fetchData();
  }, []);

  const onFilterClick = async () => {
    const lowercasedEmail = email.toLowerCase();
    const lowercasedInput = fullName.toLowerCase();
    const hasStatusFilter = status !== undefined && status.trim() !== '';

    const searchData = finalData.filter(({ firstName, lastName, emailAddress, isUsed }) => {
      const fullNameInput = `${firstName} ${lastName}`.toLowerCase();
      const statusFilter =
        hasStatusFilter ? isUsed === (status === 'true') : true;

      return (
        (emailAddress && emailAddress.toString().toLowerCase().includes(lowercasedEmail)) &&
        fullNameInput.includes(lowercasedInput) && statusFilter
      );
    });

    setData(searchData);
  };

  const onResetClick = async () => {
    setData(finalData);
  };

  const columns = [
    {
      name: 'Full Name',
      selector: row => row.firstName + ' ' + row.lastName,
      sortable: true,
      filter: true,
    },
    {
      name: 'Email ID',
      selector: row => row.emailAddress,
      sortable: true,
      filter: true,
    },
    {
      name: 'Activation Code',
      selector: row => (row.isUsed ? '-' : row.activationCode),
    },
    {
      name: 'Status',
      selector: row => (row.isUsed ? <SuccessBadge text="Activated" /> : <WarningBadge text="Pending" />),
    },
    {
      name: 'Created At',
      selector: row => {
        const createdAt = row.created_at;

        if (createdAt && createdAt.seconds) {
          const createdDate = new Date(createdAt.seconds * 1000);
          return createdDate.toLocaleString([], {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
        } else {
          return '';
        }
      },
      sortable: true,
      filter: true,
    },
    {
      name: 'Actions',
      cell: (row) => <CustomActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const CustomActionsCell = ({ row }) => {
    const patient = row;
    const updateURL = `${ADMIN_ROUTES.MANAGE_CLIENT_URL}/${patient.uid}`;
    return (
      (row.isUsed &&
        <div className='col-auto'>
          <Link to={updateURL} className="text-decoration-none">
            <button className='editBtn btn btn-outline-dark'>
              <div className="editPencilImg d-flex align-items-center gap-1">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }} xmlns="http://www.w3.org/2000/svg"><path d="M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z"></path></svg>Manage
              </div>
            </button>
          </Link>
        </div>
      )
    );
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            <div className='row'>
              <div className='col-12'>
                <div className='commonCard'>
                  <div className='card-header '>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <div className='SectionHeadingMain'>Manage Client</div>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    {isLoading && <Loader />}
                    {!isLoading && (
                      <>
                        <div className="row">
                          <div className="col">
                            <input
                              type="text"
                              className="form-control shadow-none border controlRounded"
                              placeholder="Full Name"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control shadow-none border controlRounded"
                              placeholder="example@example.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="col">
                            <select
                              className="form-control shadow-none border controlRounded"
                              name="status"
                              onChange={(event) => setStatus(event.target.value)} value={status}
                            >
                              <option value='' selected>--Choose Status--</option>
                              <option value='false'>Pending</option>
                              <option value='true'>Activated</option>

                            </select>
                          </div>
                          <div className="col-auto">
                            <button className='btn btn-dark mt-0' onClick={onFilterClick}>Filter</button>
                            <button className='btn btn-light mt-0 ms-2' onClick={onResetClick}>Reset</button>
                          </div>
                        </div>
                        <div className='managepatientsTable'>
                          {(data.length === 0 ? (
                            <>
                              <div className="cfvlby">
                                {columns.map((column, index) => (
                                  <div className='sc-crrtmM WubHI' key={index} style={{ paddingLeft: "36px" }}>
                                    <span key={index} className="sc-dQpIV ofEne">{column.name}</span>
                                  </div>
                                ))}
                              </div>
                              <p className="text-center py-2">There are no records to display</p>
                            </>
                          ) : (
                            <DataTable
                              fixedHeader
                              fixedHeaderScrollHeight="700px"
                              highlightOnHover
                              noContextMenu
                              pagination
                              responsive
                              subHeaderAlign="right"
                              subHeaderWrap
                              columns={columns}
                              data={data}
                            />
                          )
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientListing