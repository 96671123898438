import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../components/frontendComponents/header/Header'
import five from '../../../assets/common/img/five.jpg';
import four from '../../../assets/common/img/four.jpg';
import { Autoplay, FreeMode, EffectFade, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import treatment from '../../../assets/common/img/neuromonics-tinnitus-treatment.png';
import tinnitus from '../../../assets/common/img/what-is-tinnitus.png';
import rr from '../../../assets/common/img/rr.jpg';
import 'swiper/css/effect-fade';
import "swiper/css";
import 'swiper/css/free-mode';
import Footer from '../../../components/frontendComponents/footer/Footer';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../common/firebase';
import { FILENAME, STORAGE, STORAGEURL } from '../../../constants/StorageConst';
import TestimonialComponent from '../../../components/frontendComponents/homePage/TestimonialComponent';
import { Link } from 'react-router-dom';


const Tinnitus = () => {
    const scrollContainer = useRef(null)
    const [jsonDataFirst, setJsonDataFirst] = useState(null);
    const [jsonDataSecond, setJsonDataSecond] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const jsonFileRef = ref(storage, STORAGEURL + '/' + STORAGE.BLOGS + '/' + FILENAME.TESTIMONIALSFILE);
            try {
                const url = await getDownloadURL(jsonFileRef);
                const response = await fetch(url);
                const data = await response.json();
                const jsonDataLength = data.length;
                if (jsonDataLength > 5) {
                    if (jsonDataLength % 2 === 0) {
                        const halfLength = jsonDataLength / 2;
                        const firstHalfData = data.slice(0, halfLength);
                        const secondHalfData = data.slice(halfLength);
                        setJsonDataFirst(firstHalfData);
                        setJsonDataSecond(secondHalfData);
                    } else {
                        const greaterData = data.slice(0, (jsonDataLength - 1) / 2 + 1);
                        const lesserData = data.slice(((jsonDataLength - 1) / 2 + 1));
                        setJsonDataFirst(greaterData);
                        setJsonDataSecond(lesserData);
                    }
                } else {
                    setJsonDataFirst(data);
                }
                setIsLoading(false)

            } catch (error) {
                console.error('Error fetching or parsing JSON file:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className='frontendOuter paddingTopHeader aboutPadding' ref={scrollContainer}>
                <Header scrollContainer={scrollContainer} secondHeader={true} />
                {/* home Section Start */}
                <div className="container-fluid aboutHomeSec tinnitusSec">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 order-2 order-lg-1 px-3 px-md-5 py-3 py-md-4">
                            <div className="heading">
                                What is Tinnitus?
                            </div>
                            <div className="description pt-3 pt-xl-4 tinnitusDesciption">
                                <div className="py-1">
                                Tinnitus is a common auditory condition experienced by millions worldwide, characterized by the perception of sound when there is no external sound present. Often described as a ringing, buzzing, hissing, or humming in the ears.<br/>
                                </div>
                                <div className="py-1">
                                Tinnitus can vary in pitch, occur in one or both ears, and may be constant or intermittent. While it affects individuals differently, for many, tinnitus can significantly impact daily life, leading to discomfort, difficulty concentrating, and even sleep disturbances.<br/>
                                </div>
                                <div className="py-1">
                                Tinnitus is not a disease itself, but a symptom arising from a range of underlying causes, including age-related hearing loss, exposure to loud noises, or certain medical conditions. These causes might include exposure to loud noises, certain medications, ear or sinus infections, age-related hearing loss, various other medical conditions, and/or hearing-related dysfunction.
                                </div>
                            </div>
                            {/* <div className="pt-3 pt-xl-5">
                                <button className="nuroBtn">
                                    Find a clinic to get started
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
                                    </svg>
                                </button>
                            </div> */}
                        </div>
                        <div className="col-12 col-lg-6 order-1 order-lg-2 px-0">
                            <div className="imgSide">
                                <img src={four} alt="" className='h-100 w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                {/* home Section End */}
                {/* darkSlider start */}
                <div className="container-fluid position-relative bg-dark py-2 darkFluid" style={{ zIndex: 5 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Swiper
                                    // slidesPerView={1}
                                    spaceBetween={70}
                                    slidesPerView={'auto'}
                                    loop={true}
                                    freeMode={true}
                                    // centeredSlides= {true}
                                    speed={19000}
                                    allowTouchMove={false}
                                    autoplay={{
                                        delay: 0,
                                        enabled: true,
                                        disableOnInteraction: false,
                                        pauseOnMouseEnter: true,
                                    }}
                                    modules={[FreeMode, Autoplay]}
                                    className="mySwiper testimonialsSwiper textswiper"
                                >
                                    <SwiperSlide>
                                        <div className="textWrapper">
                                            <div className="row justify-content-center">
                                                <div className="col-auto textOne text-white">83% Neuromonics Success Rate</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="textWrapper">
                                            <div className="row justify-content-center">
                                                <div className="col-auto textOne text-white">10,000+ treatments delivered by Neuromonics</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="textWrapper">
                                            <div className="row justify-content-center">
                                                <div className="col-auto textOne text-white">50 million Americans suffer with persistent tinnitus</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                {/* darkSlider Ends */}
                {/* mission Section Start */}
                <div className="container-fluid missionSec py-lg-5 py-3">
                    <div className="container">
                        <div className="row align-items-center gy-3">
                            <div className="col-12 col-lg-6 order-2 order-lg-1">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-start">
                                        <div className="heroBanner row">
                                            {/* <div className="col-auto">
                                                <div className="heroPill">
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                    .........
                                                </div>
                                            </div> */}
                                            <div className="col-12 pb-3">
                                                <div className="heroHeading">
                                                    Prevalence of Tinnitus
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroSubHeading descripton">
                                                    <div className="py-1">
                                                    Up to 750 million people worldwide suffer from tinnitus.<sup><Link to="/blog/prevalence-and-incidence-of-tinnitus">1</Link></sup> In the United States alone, the American Tinnitus Association (ATA) estimates that 50 million people have tinnitus, while separate studies indicate 3-7 million Americans struggle with severe tinnitus.<sup><Link to="/journals">2</Link></sup><br />
                                                    </div>
                                                    <div className="py-1">
                                                    There are four different classes of tinnitus: subjective, objective, somatic and neurological, with subjective tinnitus representing as much as 99% of all tinnitus cases.<sup><Link to="/blog/the-four-types-of-tinnitus">3</Link></sup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 order-1 order-lg-2">
                                <div className="missionImg">
                                    <img src={five} alt="" className='h-100 w-100' />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center gy-3 pt-3 pt-lg-5">
                            <div className="col-12 col-lg-6 order-2 order-md-1">
                                <div className="missionImg">
                                    <img src={rr} alt="" className='h-100 w-100' />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 order-1 order-md-2">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-start">
                                        <div className="heroBanner row ps-md-3">
                                            {/* <div className="col-auto">
                                                <div className="heroPill">
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                    ......
                                                </div>
                                            </div> */}
                                            <div className="col-12">
                                                <div className="heroHeading pb-3">
                                                    Impact of Tinnitus
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroSubHeading descripton">
                                                    While tinnitus is typically a benign condition, it can have a significant impact on a person's quality of life, leading to frustration, sleep disturbances, difficulty concentrating, anxiety and even more severe conditions such as depression or worse in more severe cases. It is important to understand that you are not alone in this experience, and there are many avenues for support and management.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* mission Section End */}
                <div className="container-fluid mapSection pt-3 pt-md-5 pb-3">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-sm-11 col-lg-9 px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <div className="heroBanner row">
                                        <div className="col-12">
                                            <div className="heroHeading clinicHeading">
                                                Underlying Mechanism(s) of Tinnitus
                                            </div>
                                        </div>
                                        <div className="col-12 py-3">
                                            <div className="heroSubHeading descripton">
                                                In our blog post "The Neurophysiological Model of Tinnitus," we discuss in some detail the underlying neurophysiological mechanism(s) underpinning the formation and development of disturbing tinnitus.<br />

                                                <div className='italicfont'>(Note: Not everyone with tinnitus suffers from disturbing tinnitus. The Neuromonics Tinnitus Treatment is about transitioning sufferers with disturbing tinnitus to having tinnitus that is no longer disturbing)</div>
                                                <br />
                                                In summary, however, disturbing subjective tinnitus arises from three discrete but interrelated and self-reinforcing processes, all involving neuroplastic changes to the brain:
                                                <br />
                                            </div>
                                        </div>
                                        <div className="container-fluid cardThreeSection py-3 py-lg-4">
                                            <div className="container">
                                                <div className="row gy-4 gy-md-5 align-items-stretch">
                                                    <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                                        <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                            <div className="cardheading cardheading-md pb-3">
                                                                Auditory System & Perception
                                                            </div>
                                                            <div className="description">
                                                                Involves changes within the auditory system which lead to the initial perception of the tinnitus sound;
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                                        <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                            <div className="cardheading pb-3">
                                                                Attentional Filters
                                                            </div>
                                                            <div className="description">
                                                                involves the attentional filters in the brain which cause someone to pay attention to the perception of tinnitus; and
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                                        <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                            <div className="cardheading pb-3">
                                                                Emotional Response/ Reaction
                                                            </div>
                                                            <div className="description">
                                                                Involves the emotional response and the autonomic nervous system which cause an aversive reaction to the tinnitus.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 pt-4">
                                            <div className="mapImgChart">
                                                <img src={tinnitus} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mapSection pt-3 pb-3 pb-md-5" style={{ backgroundColor: 'rgb(226, 244, 255)!important' }}>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-sm-11 col-lg-9 px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <div className="heroBanner row">
                                        <div className="col-12">
                                            <div className="heroHeading clinicHeading">
                                                Neuromonics & the Neuromonics Tinnitus Treatment
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mainTxt">
                                                At Neuromonics, we understand the challenges of living with tinnitus. Our dedicated approach to tinnitus treatment involves personalized sound therapy that is clinically proven to provide long-term relief. With our advanced technology and supportive care, we aim to help you regain comfort and control over your life.<br/>
                                                The Neuromonics Tinnitus Treatment is designed to address each aspect of the underlying neurophysiological model of tinnitus.
                                            </div>
                                        </div>
                                        <div className="container-fluid cardThreeSection py-3 py-lg-4">
                                            <div className="row align-items-stretch">
                                                <div className="col-12 col-sm-12 col-lg-12 h-auto mb-4">
                                                    <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                        <div className="cardheading cardheading-md pb-3">
                                                            Systematic desensitization to address the perceptual Auditory stimulation to address the effects of auditory deprivation
                                                        </div>
                                                        <div className="description">
                                                            Neuromonics delivers a broad frequency stimulus into the auditory system that's been deprived of stimulation. In doing so, it counters the need for increased auditory sensitivity. Studies have shown that neuronal changes that result from hearing damage can be reversed by feeding sound into the auditory system (Norena & Eggermont, 2005).
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-lg-12 h-auto mb-4">
                                                    <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                        <div className="cardheading pb-3">
                                                            Relaxation and relief to address the aversive reaction / stress response
                                                        </div>
                                                        <div className="description">
                                                            A key part of the Neuromonics stimulus is relaxation music. The relaxation music addresses the limbic/autonomic nervous system involvement that causes the aversive reaction to tinnitus.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-lg-12 h-auto mb-4">
                                                    <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                        <div className="cardheading pb-3">
                                                            Systematic desensitisation that leads to reduced attention to the tinnitus
                                                        </div>
                                                        <div className="description">
                                                            The Neuromonics treatment addresses the attentional filters using the principles of systematic desensitization. Because of the dynamics of music—once customized for the patient's profile and delivered in a controlled way—the stimulus allows the patient to cover the tinnitus in the peaks of intensity in the music, while allowing the tinnitus to be momentarily exposed to and perceived by the limbic system during periods of musical troughs. In this way, the brain experiences repeated but momentary exposure to tinnitus whilst in a relaxed state. By gradually increasing the degree of exposure to the tinnitus perception over time, the brain is retrained to perceive the tinnitus sound, but not to pay particular attention to it, and not to trigger the aversive stress response in reaction to it.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 pt-4">
                                            <div className="mapImgSecondChart">
                                                <img src={treatment} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* find Section Start */}
                <div className="container-fluid mapSection bg-white">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-5 px-0">
                            <div className="mapImg">
                                <img src={rr} alt="" className='h-100 w-100' />
                            </div>
                        </div>
                        <div className="col-12 col-lg-7 py-3 py-lg-4 px-3 px-lg-5">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-start">
                                    <div className="heroBanner row">
                                        <div className="col-auto">
                                            <div className="heroPill">
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                Contact
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="heroHeading clinicHeading">
                                                Contact Neuromonics Today
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="heroSubHeading descripton py-1">
                                                Understanding tinnitus is the first step towards effective management and relief.<br/>
                                                While tinnitus can be a challenging condition, understanding and managing it effectively can significantly reduce its impact. At Neuromonics, we're committed to guiding you through your journey to better auditory health.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* find Section End */}

                {/* testimonial Section start */}
                {/* <div className="container-fluid testimonialFluid bg-white position-relative py-lg-5 py-3" style={{ zIndex: 5 }}> */}
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                                            </svg>
                                            Testimonials
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">Our Happy Clients</div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroSubHeading">Web design involves creating and designing websites, includinglayout, content, and user experience. that not only captivate but also elevate.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="container testimonialContainer">
                        {jsonDataSecond ? (
                            <div className="row g-4">
                                <TestimonialComponent TestimonialData={jsonDataFirst} dir="rtl" />
                                <TestimonialComponent TestimonialData={jsonDataSecond} dir="ltr" />
                            </div>
                        ) :
                            <TestimonialComponent TestimonialData={jsonDataFirst} dir="rtl" />
                        }
                    </div> */}
                {/* </div> */}
                {/* testimonial Section end */}
                
                {/* faq Section start */}
                {/* <div className="container-fluid faqSection py-lg-5 py-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                    <div className="col-12 d-flex justify-content-start">
                                        <div className="heroBanner row">
                                            <div className="col-auto">
                                                <div className="heroPill">
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                    FAQ
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroHeading">
                                                    Tinnitus FAQs
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>What are the symptoms ?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Who is affected?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>What are the types of tinnitus?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>What are the causes of tinnitus?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>What do I do if I have tinnitus?</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                    </div> */}
                {/* faq Section End */}

                {/* footer start */}
                <Footer />
                {/* footer ebd */}
            </div>
        </>
    )
}

export default Tinnitus
