import React from 'react'
import 'swiper/css/effect-fade';
import "swiper/css";
import 'swiper/css/free-mode';
import one from '../../../assets/common/img/one.jpg';

const ContactComponent = () => {

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-start">
                        <div className="heroBanner row">
                            <div className="col-auto">
                                <div className="heroPill">
                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                    Contact Us
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="heroHeading">
                                    Tell Us
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="heroSubHeading descripton">
                                    Lorem, ipsum dolor Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur, magni! sit amet consectetur adipisicing elit. Expedita, ex?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center gy-3 gy-lg-4">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <div className="formSide">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control shadow-none contactForm" id="floatingInput" placeholder="name@example.com" />
                                <label htmlFor="floatingInput">Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control shadow-none contactForm" id="floatingPassword" placeholder="Password" />
                                <label htmlFor="floatingPassword">Email</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="tel" className="form-control shadow-none contactForm" id="floatingPassword" placeholder="Password" />
                                <label htmlFor="floatingPassword">Contact Number</label>
                            </div>
                            <div className="form-floating">
                                <textarea className="form-control formTextarea shadow-none" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                                <label htmlFor="floatingTextarea2">Comments</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        <div className="ContactImg">
                            <img src={one} alt="" className='h-100 w-100' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactComponent