import React, { useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const YAxisChart = ({ eventData, week }) => {
    const [fetchDate, setFetchDate] = useState('');
    const options = {
        responsive: true,
        scales: {
            x: {
                display: true,
            },
            y: {
                display: true,
                beginAtZero: true
            },
        },
        plugins: {
            tooltip: {
                enabled: true
            },
            legend: {
                display: true
            }
        }
    };


    const songListens = eventData.map(item => {
        const durationInSeconds = item.details.listenDuration;
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        return {
            date: new Date(item.date.seconds * 1000).toISOString(),
            duration: durationInSeconds,
            durationTime: `${hours} hours ${minutes} minutes`,
            title: item.details.title,
            startTime: item.details.startTime,
            endTime: item.details.endTime,
            startVolume: item.details.startVolume,
            endVolume: item.details.endVolume,
        };
    });

    function timestampToTime(timestamp) {
        const date = new Date(timestamp.seconds * 1000);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        return `${hours}:${minutes}:${seconds} ${period}`;
    }

    // const chartData = {
    //     labels: songListens.map(item => {
    //         const startTime = timestampToTime(item.startTime);
    //         const endTime = timestampToTime(item.endTime);
    //         return `${startTime} - ${endTime}`;
    //     }),
    //     datasets: [
    //         songListens.map(item => {
    //             console.log('item:', item);
    //             return {
    //                 label: 'test',
    //                 borderColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`,
    //                 backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`,
    //                 borderWidth: 1,
    //                 data: item.endVolume - item.startVolume
    //             }
    //         }),
    //     ]
    // };

    const chartData = {
        labels: songListens.map(item => {
            const startTime = timestampToTime(item.startTime);
            const endTime = timestampToTime(item.endTime);
            return `${startTime} - ${endTime}`;
        }),
        datasets: songListens.map(item => ({
            label: item.title,
            backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`,
            borderColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`,
            borderWidth: 1,
            data: item.endVolume - item.startVolume
        }))
    };


    // songListens.sort((a, b) => new Date(a.date) - new Date(b.date));

    // const allLabels = ["Midnight", "Midday", "Midnight(end)"];

    // const listenLabel = (date, duration, label) => {
    //     const hour = new Date(date).getHours();
    //     if (
    //         (label === "Midnight" && hour >= 0 && hour < 12) ||
    //         (label === "Midday" && hour === 12) ||
    //         (label === "Midnight(end)" && hour >= 12 && hour < 24)
    //     ) {
    //         return duration;
    //     }
    //     return 0;
    // };

    // const groupedByTitle = {};
    // songListens.forEach(listen => {
    //     if (!groupedByTitle[listen.title]) {
    //         groupedByTitle[listen.title] = [];
    //     }
    //     groupedByTitle[listen.title].push(listen);
    // });

    // const datasets = Object.keys(groupedByTitle).map(title => {
    //     const totalDurations = allLabels.map(label => {
    //         const totalDuration = groupedByTitle[title].reduce((sum, listen) => {
    //             return sum + listenLabel(listen.date, listen.duration, label);
    //         }, 0);
    //         const hours = Math.floor(totalDuration / 3600);
    //         const minutes = Math.floor((totalDuration % 3600) / 60);
    //         return { label: `${hours} hours ${minutes} minutes`, duration: totalDuration };
    //     });
    //     return {
    //         label: title,
    //         data: totalDurations.map(item => item.duration),
    //         backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`,
    //         barThickness: 70,
    //     };
    // });


    // const aggregatedData = {
    //     labels: allLabels,
    //     datasets: datasets,
    // };

    return (
        <>
            <div className="row py-3">
                <div className="col-12 pe-0">
                    <div className="paraOne mb-3">
                        Week {week + 1}: <span style={{ fontWeight: '100' }}>{eventData.dateString}</span>
                    </div>
                    {/* <Bar options={options} data={aggregatedData} height={70} /> */}
                    <Bar
                        options={options} 
                        data={chartData}
                    />
                </div>
            </div>
        </>
    );
};

export default YAxisChart;
