export const STORAGE = {
    MATERIALS: 'materials',
    QUESTIONNARIES: 'questionnaires',
    JOURNALS: 'journals',
    BLOGS: 'blog',
    TESTIMONIALS: 'testimonials',
    CLINICS: 'clinics'
}

let storage_url;
if (process.env.REACT_APP_LOCAL_EMULATOR === "true") {
    storage_url = 'gs://neuromonics-iphone-app-local.appspot.com';    
}
else {
    storage_url = 'gs://neuromonics-iphone-app.appspot.com';
}
export const STORAGEURL = storage_url;

export const FILENAME = {
    BLOGFILENAME: 'blogs.json',
    QUESTIONNARIESFILE: 'TRQ.json',
    JOURNALSFILE: 'journals.json',
    TESTIMONIALSFILE:'testimonials.json',
    CLINICSFILENAME: 'clinic-list.json',
    SUPPORTINGMATERIALS: 'supporting_materials.json',
}