import axios from 'axios';

let base_url
if (process.env.REACT_APP_FB_PROJECT_ID === "neuromonics-iphone-app-local") {
    base_url = 'http://127.0.0.1:5081/neuromonics-iphone-app-local/us-central1/api/'
}
else if (process.env.REACT_APP_FB_PROJECT_ID === "neuromonic-iphone-app-dev") {
    base_url = 'https://us-central1-neuromonic-iphone-app-dev.cloudfunctions.net/api/'
}
else {
    base_url = 'https://us-central1-neuromonics-iphone-app.cloudfunctions.net/api/';
}
export const BASE_URL = base_url;
console.debug(BASE_URL)

/* GET API to get the User Role */
export const getData = (email) => {
    return axios.get(`${BASE_URL}getCustomClaims?email=` + email)
        .then(response => response.data.data)
        .catch(error => console.error('Error fetching data:', error));
}

/* POST API to get the User Role */
export const setRoleData = (userId, roleType) => {
    const data = {
        "uid": userId,
        "role": roleType
    }
    axios.post(`${BASE_URL}createCustomClaims`, data)
        .then(response => {
            if (response.status === 200) {
                console.log('Data posted successfully');
                return true;
            } else {
                console.error('Error posting data:', response.status);
            }
        })
        .catch(error => console.error('Error posting data:', error));
}

/* GET API to get Clinician Lists  */
export const getClinicanList = () => {
    return axios.get(`${BASE_URL}getClinicanList`)
        .then(response => response.data.data)
        .catch(error => console.error('Error fetching data:', error));
}

/* GET API to get Client Lists  */
export const getAllClientList = () => {
    return axios.get(`${BASE_URL}getClientList`)
        .then(response => response.data.data)
        .catch(error => console.error('Error fetching data:', error));
}

/* POST API to trigger a email */
export const sendEmail = (to, subject, text, bcc, cc = '') => {
    const data = {
        "to": to,
        "subject": subject,
        "text": text,
        "bcc": bcc,
        "cc": cc
    }
    axios.post(`${BASE_URL}sendEmail`, data)
        .then(response => {
            if (response.status === 200) {
                return true;
            } else {
                console.error('Error posting data:', response.status);
            }
        })
        .catch(error => console.error('Error posting data:', error));
}

/* GET API to get all user Lists  */
export const getAllUserList = () => {
    return axios.get(`${BASE_URL}getAllUserList`)
        .then(response => response.data.data)
        .catch(error => console.error('Error fetching data:', error));
}

/* POST api to delete user  */
export const deleteUser = (uid) => {
    const data = {
        uid
    }
    return axios.post(`${BASE_URL}disableUser`, data)
        .then(response => {
            if (response.status === 200) {
                return true;
            } else {
                console.error('Error deleting user:', response.status);
            }
        })
        .catch(error => console.error('Error deleting user:', error));
}