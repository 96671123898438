import React, { useEffect, useState } from 'react';
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import DataTable from 'react-data-table-component';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../common/firebase';
import { getClinicanList } from '../../common/fetch';
import Loader from '../extra/Loader';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../constants/RouteConst';
import { SuccessBadge, WarningBadge } from '../../components/badges/Badges';
import Swal from 'sweetalert2';
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';

const ClinicianListing = () => {

    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchClinicians = async () => {
            setIsLoading(true);
            try{
                const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
                const users = await getClinicanList();
                const clinicianList = [];
                for (const user of users) {
                    const userDocRef = doc(usersCollection, user.uid);
                    const userDocumentSnapshot = await getDoc(userDocRef);
                    if (userDocumentSnapshot.exists()) {
                        const userDetails = userDocumentSnapshot.data();
                        const stateRef = collection(userDocRef, DATABASECOLLECTION.STATE);
                        const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
                        const currentDocSnap = await getDoc(currentDocRef);
                        // userDetails.emailVerified = user.emailVerified;
                        userDetails.creationTime = user.metadata.creationTime;
                        userDetails.uid = user.uid;
                        userDetails.state = currentDocSnap.data();
                        clinicianList.push(userDetails);
                        setData(clinicianList);
                    }
                }
            } catch (error) {
                Swal.fire('Error!', 'Failed to one page', 'error');
            }
            setIsLoading(false);
        };
        fetchClinicians();
    }, []);

    const columns = [
        {
            name: 'Full Name',
            selector: row => row.firstName + ' ' + row.lastName,
            sortable: true,
        },
        {
            name: 'Email ID',
            selector: row => row.emailAddress,
            sortable: true,
        },
        {
            name: 'Email Verified',
            selector: row => (row.state?.isEmailVerified ? <SuccessBadge text="Verified" /> : <WarningBadge text="Pending" />),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (row.state?.onboardComplete ? <SuccessBadge text="Approved" /> : <WarningBadge text="Clinician (Pending)" />),
            sortable: true,
        },
        {
            name: 'Registered On',
            selector: row => row.creationTime,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => <CustomActionsCell row={row} />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const CustomActionsCell = ({ row }) => {
        const clinician = row;
        const updateURL = `${ADMIN_ROUTES.APPROVAL_URL}/${clinician.uid}`;

        return (
            <Link to={updateURL} className="text-decoration-none">
                <button className='editBtn btn btn-outline-dark'>
                    <div className="editPencilImg d-flex align-items-center gap-1">
                       {(!row.state?.onboardComplete ?'Approve':'View')}
                    </div>
                </button>
            </Link>
        );
    };

    return (
        <>
            <div className="dashboardWrapper">
                <AdimnSidebar />
                <div className="adminDashBoard">
                    <AdminHeader />
                    <div className="adminBody">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='commonCard'>
                                    <div className='card-header '>
                                        <div className='row align-items-center'>
                                            <div className='col'>
                                                <div className='SectionHeadingMain'>Approve Clinician</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        {isLoading && <Loader />}
                                        <div className='managepatientsTable'>
                                            {!isLoading && (
                                                <DataTable
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="700px"
                                                    highlightOnHover
                                                    noContextMenu
                                                    pagination
                                                    responsive
                                                    // subHeader
                                                    subHeaderAlign="right"
                                                    subHeaderWrap
                                                    columns={columns}
                                                    data={data}
                                                    options={{
                                                        search: false,
                                                    }}

                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClinicianListing