import React, { useRef } from 'react'
import Header from '../../../components/frontendComponents/header/Header'
import five from '../../../assets/common/img/five.jpg';
import one from '../../../assets/common/img/one.jpg';
import four from '../../../assets/common/img/four.jpg';
import eight from '../../../assets/common/img/eight.jpg';
import Footer from '../../../components/frontendComponents/footer/Footer';
import { FRONTEND_ROUTES } from '../../../constants/RouteConst';
import { Link } from 'react-router-dom';
import ContactComponent from '../../../components/frontendComponents/homePage/ContactComponent';


function Neuromonics() {
    const scrollContainer = useRef(null)
    return (
        <>
            <div className='frontendOuter paddingTopHeader aboutPadding' ref={scrollContainer}>
                <Header scrollContainer={scrollContainer} secondHeader={true} />
                {/* home page start */}
                <div className="container-fluid aboutHomeSec tinnitusSec">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 order-2 order-lg-1 px-3 px-md-5 py-3 py-md-5">
                            <div className="heading">
                                What is Neuromonics ?
                            </div>
                            <div className="description pt-3 pt-xl-5 tinnitusDesciption">
                            Neuromonics is a pioneer in tinnitus relief and management.  Founded with the mission to provide music wellness and improve the quality of life for people suffering from tinnitus, Neuromonics has been at the forefront of research and development in the field.
                            </div>
                            <div className="pt-3 pt-xl-5">
                                <Link to={FRONTEND_ROUTES.FINDCLINIC} className='text-decoration-none nuroBtn py-3'>
                                    Find a clinic to get started
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 order-1 order-lg-2 px-0">
                            <div className="imgSide">
                                <img src={eight} alt="" className='h-100 w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                {/* home page ends */}

                {/* history section start */}
                {/* <div className="container-fluid py-3 py-lg-5 cardSevensection">
                    <div className="container">
                        <div className="row gy-3 gy-md-4">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="heroBanner row">
                                    <div className="col-auto">
                                        <div className="heroPill">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                            Treatment
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroHeading">
                                            Experience the Neuromonics Tinnitus Treatment
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="heroSubHeading descripton">
                                        The Neuromonics Tinnitus Treatment offers a personalized and non-invasive approach to managing tinnitus.  Your treatment journey involves several key steps:
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="mainCard shadow-lg bg-white rounded-2">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-lg-6">
                                            <div className="cardImg overflow-hidden">
                                                <img src={five} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="row p-3 p-lg-0">
                                                <div className="col-12">
                                                    <div className="cardHeading">
                                                        Initial Assessment 
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="description">
                                                        The treatment process begins with a thorough assessment of your hearing and tinnitus profile. This helps to tailor your therapy to your specific needs.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="mainCard shadow-lg bg-white rounded-2">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-lg-6">
                                            <div className="cardImg overflow-hidden">
                                                <img src={five} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="row p-3 p-lg-0">
                                                <div className="col-12">
                                                    <div className="cardHeading">
                                                        Customized Sound Therapy 
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="description">
                                                        Based on your assessment, through the Neuromonics app, you will be provided with and listen to individually-tailored, spectrally-modified relaxation music to provide relief and reduce tinnitus perception.  The listening schedule is flexible and designed to fit into your daily routine, usually requiring 2+ hours of passive listening each day while you engage in other routine daily activities, eg. walking the dog, reading a newspaper, engaging in your favourite hobby, etc.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="mainCard shadow-lg bg-white rounded-2">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-lg-6">
                                            <div className="cardImg overflow-hidden">
                                                <img src={five} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="row p-3 p-lg-0">
                                                <div className="col-12">
                                                    <div className="cardHeading">
                                                        Ongoing Support  
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="description">
                                                    Throughout the treatment, you'll have regular one-on-one check-ins with a Neuromonics-trained clinician. They will support you through the six-month treatment program, and monitor your progress, provide guidance and assistance, and make adjustments to the treatment as needed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="mainCard shadow-lg bg-white rounded-2">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-lg-6">
                                            <div className="cardImg overflow-hidden">
                                                <img src={five} alt="" className='h-100 w-100' />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="row p-3 p-lg-0">
                                                <div className="col-12">
                                                    <div className="cardHeading">
                                                        Gradual Relief  
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="description">
                                                    Over a period of weeks-to-months, most users experience a significant reduction in the disturbance caused by their tinnitus. The goal is to reach a point where tinnitus becomes a minor, background noise having minimal if any impact on your daily routine.<br/>
                                                    The journey with the Neuromonics Tinnitus Treatment is about regaining comfort and control over your life, reducing the impact of tinnitus, and improving overall well-being.  Neuromonics enables you to hear a better life.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* history section ends */}
                {/*new Section start  */}
                    <div className="container-fluid mapSection">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-11 col-lg-9 py-4 py-lg-4 px-4 px-lg-0">
                                <div className="row pt-4">
                                    <div className="col-12 d-flex justify-content-center">
                                        <div className="heroBanner row">
                                            <div className="col-12">
                                                <div className="heroHeading clinicHeading">
                                                    The Neuromonics Tinnitus Treatment
                                                </div>
                                            </div>
                                            <div className="col-12 py-3">
                                                <div className="heroSubHeading descripton">
                                                    The Neuromonics Tinnitus Treatment is a clinically proven, patented, FDA-cleared therapy designed to reduce tinnitus awareness and disturbance levels.<br />
                                                    It uses a unique audio profile tailored to each individual's auditory profile and tinnitus to deliver relief, relaxation and comfort.
                                                    <br />
                                                </div>
                                            </div>
                                            <div className="container-fluid cardThreeSection py-3 py-lg-4">
                                                <div className="container">
                                                    <div className="row gy-3 gy-md-4 align-items-stretch">
                                                        <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                                            <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                                <div className="cardheading cardheading-md pb-3">
                                                                    Customized Treatment 
                                                                </div>
                                                                <div className="description">
                                                                    tailored to your specific hearing profile.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                                            <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                                <div className="cardheading pb-3">
                                                                    Proven Effectiveness 
                                                                </div>
                                                                <div className="description">
                                                                    clinically tested with significant positive results.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 h-auto">
                                                            <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                                                <div className="cardheading pb-3">
                                                                    Non-Invasive 
                                                                </div>
                                                                <div className="description">
                                                                    a simple, non-surgical approach to tinnitus management.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/*new Section ends  */}

                {/* aboutDevice Section Start */}
                    <div className="container-fluid mapSection bg-white">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-lg-5 px-0">
                                <div className="mapImg numroMapImg">
                                    <img src={four} alt="" className='h-100 w-100' />
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 py-3 py-lg-4 px-3 px-lg-5">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-start">
                                        <div className="heroBanner row">
                                            <div className="col-auto">
                                                <div className="heroPill">
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                                    Origins
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroHeading clinicHeading">
                                                    The Origins of the Neuromonics Tinnitus Treatment
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="heroSubHeading descripton">
                                                The Neuromonics Tinnitus Treatment (NTT) stems from research and PhD by Dr Paul Davis at the University of Western Australia in the late-90s and early-2000s.  It has since undergone extensive clinical trials across Australia and the United States involving over 1,000 trial subjects and shown to be 83% effective in reducing the severity of subjective tinnitus by 40% or more.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* aboutDevice Section End */}

                {/* card Section Start */}
                <div className="container-fluid cardThreeSection py-3 py-lg-5 cardBackColor">
                    <div className="container">
                        <div className="col-12 d-flex justify-content-start">
                            <div className="heroBanner row">
                                <div className="col-auto">
                                    <div className="heroPill">
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path></svg>
                                        Treatment
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="heroHeading">
                                        Experience the Neuromonics Tinnitus Treatment
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="heroSubHeading">
                                    The Neuromonics Tinnitus Treatment offers a personalized and non-invasive approach to managing tinnitus. Your treatment journey involves several key steps:
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gy-4 gy-md-5 align-items-stretch">
                            <div className="col-12 col-md-6 h-auto">
                                <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                    <div className="icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" className="h-100 w-100 img-fluid" xmlns="http://www.w3.org/2000/svg"><path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"></path></svg>
                                    </div>
                                    <div className="cardheading pt-4 pb-3">
                                        Initial Assessment
                                    </div>
                                    <div className="description">
                                        The treatment process begins with a thorough assessment of your hearing and tinnitus profile. This helps to tailor your therapy to your specific needs.
                                    </div>
                                    {/* <div className="cardButton">
                                        <button className="btn cardbtnbtn rounded-pill">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 h-auto">
                                <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                    <div className="icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="h-100 w-100 img-fluid" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><circle cx="10" cy="9" r="4"></circle><path d="M16.39 15.56C14.71 14.7 12.53 14 10 14s-4.71.7-6.39 1.56A2.97 2.97 0 002 18.22V21h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM16 1h-2a9 9 0 009 9V8c-3.86 0-7-3.14-7-7z"></path><path d="M20 1h-2c0 2.76 2.24 5 5 5V4c-1.65 0-3-1.35-3-3z"></path></svg>
                                    </div>
                                    <div className="cardheading pt-4 pb-3">
                                        Customized Sound Therapy
                                    </div>
                                    <div className="description">
                                        Based on your assessment, through the Neuromonics app, you will be provided with and listen to individually-tailored, spectrally-modified relaxation music to provide relief and reduce tinnitus perception. The listening schedule is flexible and designed to fit into your daily routine, usually requiring 2+ hours of passive listening each day while you engage in other routine daily activities, eg. walking the dog, reading a newspaper, engaging in your favourite hobby, etc.
                                    </div>
                                    {/* <div className="cardButton">
                                        <button className="btn cardbtnbtn rounded-pill">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 h-auto">
                                <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                    <div className="icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="h-100 w-100 img-fluid" xmlns="http://www.w3.org/2000/svg"><path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path><path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.492 1.492 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.72.72 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.72.72 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"></path></svg>
                                    </div>
                                    <div className="cardheading pt-4 pb-3">
                                        Ongoing Support
                                    </div>
                                    <div className="description">
                                        Throughout the treatment, you'll have regular one-on-one check-ins with a Neuromonics-trained clinician. They will support you through the six-month treatment program, and monitor your progress, provide guidance and assistance, and make adjustments to the treatment as needed.
                                    </div>
                                    {/* <div className="cardButton">
                                        <button className="btn cardbtnbtn rounded-pill">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 h-auto">
                                <div className="mainCard shadow-lg rounded-3 h-100 serviveCard">
                                    <div className="icon">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="h-100 w-100 img-fluid" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"></path></svg>
                                    </div>
                                    <div className="cardheading pt-4 pb-3">
                                        Gradual Relief
                                    </div>
                                    <div className="description">
                                        Over a period of weeks-to-months, most users experience a significant reduction in the disturbance caused by their tinnitus. The goal is to reach a point where tinnitus becomes a minor, background noise having minimal if any impact on your daily routine.<br/>
                                        The journey with the Neuromonics Tinnitus Treatment is about regaining comfort and control over your life, reducing the impact of tinnitus, and improving overall well-being. Neuromonics enables you to hear a better life.
                                    </div>
                                    {/* <div className="cardButton">
                                        <button className="btn cardbtnbtn rounded-pill">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*card Section Ends */}
                {/* contact section Start */}
                {/* <div className="container-fluid py-3 py-lg-5 contactSection">
                    <ContactComponent />
                </div> */}
                {/* contact section Ends */}
                {/* footer start */}
                    <Footer />
                {/* footer end */}
            </div >
        </>
    )
}

export default Neuromonics
