import React, { useRef } from 'react'
import Header from '../../../components/frontendComponents/header/Header'
import Footer from '../../../components/frontendComponents/footer/Footer'
import { Link } from 'react-router-dom'


const PrivacyPolicy = () => {
    const scrollContainer = useRef(null)
    return (
        <div className='frontendOuter paddingTopHeader aboutPadding' ref={scrollContainer}>
            <Header scrollContainer={scrollContainer} secondHeader={true} />
            <div className="container-fluid neuromonicsFluid mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='mb-4'>Neuromonics Privacy Policy</h4>
                            <p className="paragraph">Effective Date: January 1, 2024</p>
                            <p className="paragraph">This Privacy Policy outlines how Neuromonics ("we", "us", or "our") collects, uses, discloses, and safeguards your information when you use our application (the "App"). This policy is designed to ensure the protection of your privacy, and  enable us to provide enhanced features through responsible data sharing practices.</p>
                            <ol className='privacylisting'>
                                <li className='paragraph'>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Information Collection</h4>
                                            <p className="paragraph mb-2">We collect information that you provide directly to us when you use the App, including but not limited to:</p>
                                            <ul style={{listStyleType:'disc'}}>
                                                <li><b>Personal Identification Information:</b> Name, email address, and other contact details.</li>
                                                <li><b>Health and Medical Information:</b> Specific data related to your tinnitus condition and treatment progress.</li>
                                                <li><b>Device and Usage Information:</b> Information about how you use the App and your device,including the operating system, device identifiers, and usage patterns</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Use of Information</h4>
                                            <p className="paragraph mb-2">The information we collect is used to:</p>
                                            <ul style={{listStyleType:'disc'}}>
                                                <li>Provide, maintain, and improve our services.</li>
                                                <li>Personalize your App experience.</li>
                                                <li>Communicate with you, including responding to your inquiries, comments, and requests for customer service.</li>
                                                <li>Enhance the security of the App..</li>
                                                <li>Comply with legal obligations</li>
                                                <li>With your consent, enhance your experience of Neuromonics or third-party products you are using.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Information Sharing and Disclosure</h4>
                                            <p className="paragraph">
                                                <b>Service Providers:</b> We may share your information with third-party entities that perform services on our behalf, such as data analysis, customer service, and other operations necessary for the App's functioning.
                                            </p>
                                            <p className="paragraph">
                                                <b>Aggregated and Anonymized Data:</b> With your consent, we may provide aggregated, anonymized data to third-party providers to enhance your experience of Neuromonics and the third-party product you are using. This data does not identify you personally.
                                            </p>
                                            <p className="paragraph">
                                                <b>Legal Requirements:</b> We may disclose your information when required by law, such as to comply with a subpoena or similar legal process.
                                            </p>
                                            <p className="paragraph">
                                                <b>Business Transfers:</b> In connection with a merger, acquisition, or sale of all or a portion of our assets.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Data Security </h4>
                                            <p className="paragraph">We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, alteration, and disclosure.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Your Rights</h4>
                                            <p className="paragraph mb-2">You have the right to:</p>
                                            <ul style={{listStyleType:'disc'}}>
                                                <li>
                                                    Access the personal information we hold about you.
                                                </li>
                                                <li>
                                                    Request that we update or correct your information.
                                                </li>
                                                <li>
                                                    Request deletion of your personal information, subject to certain exceptions.
                                                </li>
                                                <li>
                                                    Opt-in and provide consent before we share your information for enhanced experience purposes
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Children’s Privacy</h4>
                                            <p className="paragraph">The App does not knowingly collect personal information from children under the age of 13. If we learn that we have collected personal information from a child under 13, we will take steps to delete such information from our files as soon as possible.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Medical Disclaimer</h4>
                                            <p className="paragraph"><b>The Neuromonics Tinnitus Treatment comprises BOTH (i) the Neuromonics App + (ii) the supervision & guidance of a Neuromonics-accredited audiological professional</b></p>
                                            <p className="paragraph">The services and applications provided by the Neuromonics App are designed to support, not replace, the relationship that exists between a patient and their Neuromonics-accredited audiological professional. It is crucial that the Neuromonics App is not used in isolation but rather used in conjunction with the strict supervision and guidance of a Neuromonics-accredited audiological professional. The application is intended to be a component of a comprehensive treatment plan tailored to the individual's needs.</p>
                                            <p className="paragraph"><b>Medical Advice</b></p>
                                            <p className="paragraph">Before making any medical decisions, including but not limited to the initiation, modification, or cessation of treatments related to tinnitus or any other condition, users should seek the advice of a medical doctor or qualified healthcare provider. The information and services provided through our application are not intended to be, and must not be taken as, professional medical advice or a substitute for such advice.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Regulatory Approval</h4>
                                            <p className="paragraph">The Neuromonics Tinnitus Treatment combines: (i) the Neuromonics App that monitors and delivers spectrally-modified audio, specifically tailored to the individual’s hearing and tinnitus profile, together with (ii) specialist evaluation, supervision, guidance and counselling of a Neuromonics-accredited audiological professional. The Neuromonics App embodies the audio component of the treatment, previously embodied in Neuromonics hardware devices (eg. the Neuromonics Oasis) which received United States Food & Drug Administration (FDA) 510(k) clearance <Link to="https://www.accessdata.fda.gov/cdrh_docs/pdf4/K043274.pdf" target="_blank">K043274</Link> on January 28, 2005.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Changes to This Privacy Policy</h4>
                                            <p className="paragraph">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-4 paragraph">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='mb-4'> Contact Us</h4>
                                            <p className="paragraph mb-2">If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
                                            <div className="row">
                                                <div className="col-1 col-sm-12 col-md-2 col-lg-1 col-xl-1">
                                                    <p className="paragraph"> Email:</p>
                                                </div>
                                                <div className="col-md-5">
                                                    <p className="paragraph"> info@neuromonics.com</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-1 col-sm-12 col-md-2 col-lg-1 col-xl-1">
                                                    <p className="paragraph"> Address:</p>
                                                </div>
                                                <div className="col-md-5">
                                                    <p className="paragraph"> SoundVida, Inc. (trading as Neuromonics)<br />
                                                        26565 West Agoura Road, Suite 200<br />
                                                        Calabasas  CA  91302<br />
                                                        United States of America</p>
                                                </div>
                                            </div>
                                            <p className="paragraph">
                                                Your privacy is our priority, and we are committed to protecting your personal information while
                                                enhancing your experience with Neuromonics.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer start */}
            <Footer />
            {/* footer end */}
        </div >
    )
}

export default PrivacyPolicy
