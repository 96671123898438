import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import dashboardlogo from '../../assets/admin/img/dashboardLogo.png'
import home from '../../assets/admin/img/home.svg'
import patientLogView from '../../assets/admin/img/patientlogview.svg'
import Cross from '../../assets/admin/img/whitecross.svg'
import { Link, useLocation } from 'react-router-dom';
import { ADMIN_ROUTES, FRONTEND_ROUTES } from '../../constants/RouteConst';
import { ROLES } from '../../constants/RoleConst';

const AdimnSidebar = () => {
    const role = localStorage.getItem('role');
    const closeSidebar = () => {
        document.body.classList.remove('show')
    }
    const location = useLocation();
    const isActive = (path) => location.pathname === path;
    return (
        <>
            <div className="adminSideBar ">
                <div className="sideBarHead d-flex align-items-center">
                    <div className="dashboardLogo">
                        <Link to={FRONTEND_ROUTES.HOME_PAGE}><img src={dashboardlogo} alt="dashboard" /></Link>
                    </div>
                    <span className='ps-2'><Link to={FRONTEND_ROUTES.HOME_PAGE} className='text-dark text-decoration-none'>NEUROMONICS</Link></span>
                    <div className="crossIcon ms-auto">
                        <img src={Cross} onClick={closeSidebar} className="img-fluid w-100 h-100" alt="tableAccordion" />
                    </div>
                </div>
                <div className="sidebarBody ">
                    <div className="row">
                        <div className="col-12">
                            <ul className='siderbarHeadingUl mb-0 ps-0'>
                                <li className='sidebarHeadingLi'>MENU</li>
                                {role && (role === ROLES.CLINICIAN || role === ROLES.CLINICIANINCLINIC || role === ROLES.CLINICIANINHOUSE || role === ROLES.ADMIN) && (
                                    <Accordion className='adminAccordion mb-2'>
                                        <Accordion.Item>
                                            <Accordion.Header className='accordianSideButton'>
                                                <div className="accordianSideIcon">
                                                    <img className='pe3 h-100 w-100' src={patientLogView} alt="patient log" />
                                                </div>
                                                <span>Manage Client</span></Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='accordionUl mb-0'>
                                                    <li className={`accordionLi ${isActive(ADMIN_ROUTES.ADD_PATIENT) ? 'active' : ''}`}>
                                                        <Link to={ADMIN_ROUTES.ADD_PATIENT} className='accordionAnchor'>Add Client & Invoice</Link>
                                                    </li>
                                                    {(role === ROLES.CLINICIAN || role === ROLES.CLINICIANINCLINIC || role === ROLES.CLINICIANINHOUSE) ? (
                                                        <li className={`accordionLi ${isActive(ADMIN_ROUTES.MANAGE_PATIENTS_URL) ? 'active' : ''}`}>
                                                            <Link to={ADMIN_ROUTES.MANAGE_PATIENTS_URL} className='accordionAnchor'>Client List</Link>
                                                        </li>
                                                    ) :
                                                        <li className={`accordionLi ${isActive(ADMIN_ROUTES.MANAGE_ALL_PATIENTS_URL) ? 'active' : ''}`}>
                                                            <Link to={ADMIN_ROUTES.MANAGE_ALL_PATIENTS_URL} className='accordionAnchor'>Client List</Link>
                                                        </li>
                                                    }
                                                    <li className={`accordionLi ${isActive(ADMIN_ROUTES.MARKETING_MATERIALS) ? 'active' : ''}`}>
                                                        <Link to={ADMIN_ROUTES.MARKETING_MATERIALS} className='accordionAnchor'>Supporting Materials</Link>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )}
                                {role && role === ROLES.ADMIN && (
                                    <>
                                        {/* <li className={`sidebarLi ${isActive(ADMIN_ROUTES.MANAGE_CLINICIAN_URL) ? 'active' : ''}`}>
                                            <Link to={ADMIN_ROUTES.MANAGE_CLINICIAN_URL} className='sidebarLiAnchor row align-items-center'>
                                                <div className="col-auto">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "22px", width: "22px" }} xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M17 20c-.29 0-.56-.06-.76-.15-.71-.37-1.21-.88-1.71-2.38-.51-1.56-1.47-2.29-2.39-3-.79-.61-1.61-1.24-2.32-2.53C9.29 10.98 9 9.93 9 9c0-2.8 2.2-5 5-5s5 2.2 5 5h2c0-3.93-3.07-7-7-7S7 5.07 7 9c0 1.26.38 2.65 1.07 3.9.91 1.65 1.98 2.48 2.85 3.15.81.62 1.39 1.07 1.71 2.05.6 1.82 1.37 2.84 2.73 3.55A3.999 3.999 0 0021 18h-2c0 1.1-.9 2-2 2zM7.64 2.64L6.22 1.22C4.23 3.21 3 5.96 3 9s1.23 5.79 3.22 7.78l1.41-1.41C6.01 13.74 5 11.49 5 9s1.01-4.74 2.64-6.36zM11.5 9a2.5 2.5 0 005 0 2.5 2.5 0 00-5 0z"></path></svg>
                                                </div>
                                                <div className="col ps-0 d-flex justify-content-start"><div className="sidebarHeadingLiText">Approve Clinician</div></div>
                                            </Link>
                                        </li> */}
                                        <li className={`sidebarLi ${isActive(ADMIN_ROUTES.COUPONS_URL) ? 'active' : ''}`}>
                                            <Link to={ADMIN_ROUTES.COUPONS_URL} className='sidebarLiAnchor row align-items-center'>
                                                <div className="col-auto">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{height:"22px",width:"22px"}} xmlns="http://www.w3.org/2000/svg"><path d="m20.749 12 1.104-1.908a1 1 0 0 0-.365-1.366l-1.91-1.104v-2.2a1 1 0 0 0-1-1h-2.199l-1.103-1.909a1.008 1.008 0 0 0-.607-.466.993.993 0 0 0-.759.1L12 3.251l-1.91-1.105a1 1 0 0 0-1.366.366L7.62 4.422H5.421a1 1 0 0 0-1 1v2.199l-1.91 1.104a.998.998 0 0 0-.365 1.367L3.25 12l-1.104 1.908a1.004 1.004 0 0 0 .364 1.367l1.91 1.104v2.199a1 1 0 0 0 1 1h2.2l1.104 1.91a1.01 1.01 0 0 0 .866.5c.174 0 .347-.046.501-.135l1.908-1.104 1.91 1.104a1.001 1.001 0 0 0 1.366-.365l1.103-1.91h2.199a1 1 0 0 0 1-1v-2.199l1.91-1.104a1 1 0 0 0 .365-1.367L20.749 12zM9.499 6.99a1.5 1.5 0 1 1-.001 3.001 1.5 1.5 0 0 1 .001-3.001zm.3 9.6-1.6-1.199 6-8 1.6 1.199-6 8zm4.7.4a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path></svg></div>
                                                <div className="col ps-0 d-flex justify-content-start"><div className="sidebarHeadingLiText">Coupons</div></div>
                                            </Link>
                                        </li>
                                        <li className={`sidebarLi ${isActive(ADMIN_ROUTES.MANAGE_USERS_URL) ? 'active' : ''}`}>
                                            <Link to={ADMIN_ROUTES.MANAGE_USERS_URL} className='sidebarLiAnchor row align-items-center'>
                                                <div className="col-auto"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{ height: "22px", width: "22px" }} xmlns="http://www.w3.org/2000/svg"><path d="M12 14V22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM14.5946 18.8115C14.5327 18.5511 14.5 18.2794 14.5 18C14.5 17.7207 14.5327 17.449 14.5945 17.1886L13.6029 16.6161L14.6029 14.884L15.5952 15.4569C15.9883 15.0851 16.4676 14.8034 17 14.6449V13.5H19V14.6449C19.5324 14.8034 20.0116 15.0851 20.4047 15.4569L21.3971 14.8839L22.3972 16.616L21.4055 17.1885C21.4673 17.449 21.5 17.7207 21.5 18C21.5 18.2793 21.4673 18.551 21.4055 18.8114L22.3972 19.3839L21.3972 21.116L20.4048 20.543C20.0117 20.9149 19.5325 21.1966 19.0001 21.355V22.5H17.0001V21.3551C16.4677 21.1967 15.9884 20.915 15.5953 20.5431L14.603 21.1161L13.6029 19.384L14.5946 18.8115ZM18 17C17.4477 17 17 17.4477 17 18C17 18.5523 17.4477 19 18 19C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17Z"></path></svg></div>
                                                <div className="col ps-0 d-flex justify-content-start"><div className="sidebarHeadingLiText">Manage Users</div></div>
                                            </Link>
                                        </li>
                                        <li className={`sidebarLi ${isActive(ADMIN_ROUTES.MANAGE_TEMPLATE) ? 'active' : ''}`}>
                                            <Link to={ADMIN_ROUTES.MANAGE_TEMPLATE} className='sidebarLiAnchor row align-items-center'>
                                                <div className="col-auto">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" style={{ height: "22px", width: "22px" }} xmlns="http://www.w3.org/2000/svg"><path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"></path></svg>
                                                </div>
                                                <div className="col ps-0 d-flex justify-content-start"><div className="sidebarHeadingLiText">Manage Template</div></div>
                                            </Link>
                                        </li>
                                        <li className={`sidebarLi ${isActive(ADMIN_ROUTES.EDIT_REDIRECT) ? 'active' : ''}`}>
                                            <Link to={ADMIN_ROUTES.EDIT_REDIRECT} className='sidebarLiAnchor row align-items-center'>
                                                <div className="col-auto">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" style={{ height: "22px", width: "22px" }} xmlns="http://www.w3.org/2000/svg"><path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"></path></svg>
                                                </div>
                                                <div className="col ps-0 d-flex justify-content-start"><div className="sidebarHeadingLiText">Stripe Payment</div></div>
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdimnSidebar