import React from 'react'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'

const Dashboard = () => {

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar/>
        <div className="adminDashBoard">
            <AdminHeader/>
          <div className="adminBody">
            <div className='row'>
              <div className='col-12'>
                <div className='commonCard'>
                  <div className='card-header '>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <div className='SectionHeadingMain'>Dashboard</div>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard