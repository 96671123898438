import React, { useEffect, useState } from 'react'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import DataTable from 'react-data-table-component';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../../common/firebase';
import Loader from '../extra/Loader'
import { Link } from 'react-router-dom'
import { ADMIN_ROUTES } from '../../constants/RouteConst'
import { SuccessBadge, WarningBadge } from '../../components/badges/Badges'
import { getAllClientList, getClinicanList } from '../../common/fetch'
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';
import { ROLES } from '../../constants/RoleConst';

const AllPatientListing = () => {

  const [data, setData] = useState();
  const [finalData, setFinalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState('');
  const [clinicianList, setClinicianList] = useState('');
  const [selectedClinician, setSelectedClinician] = useState('')
  const role = localStorage.getItem('role');
  const [clinicianObj,setClinicianObj]=useState({});

  useEffect(() => {
    const getclinicianList = async () => {
      const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
      const users = await getClinicanList();
      const clinicianL = [];
      for (const user of users) {
        const userDocRef = doc(usersCollection, user.uid);
        const userDocumentSnapshot = await getDoc(userDocRef);
        if (userDocumentSnapshot.exists()) {
          const userDetails = userDocumentSnapshot.data();
          const stateRef = collection(userDocRef, DATABASECOLLECTION.STATE);
          const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
          const currentDocSnap = await getDoc(currentDocRef);
          // userDetails.emailVerified = user.emailVerified;
          userDetails.creationTime = user.metadata.creationTime;
          userDetails.uid = user.uid;
          userDetails.state = currentDocSnap.data();
          clinicianL.push(userDetails);
        }
      }
      const sorted = clinicianL.sort((a,b)=>{
        return `${a?.firstName} ${a?.lastName}`.localeCompare(`${b?.firstName} ${b?.lastName}`)  
      })
      setClinicianList(sorted);
    };
    getclinicianList()
  }, [])

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const clientRef = await getAllClientList();
        const promises = clientRef?.map(async (client) => {
          const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
          const userDocRef = doc(usersCollection, client.uid);
          const userDocumentSnapshot = await getDoc(userDocRef);
          if (userDocumentSnapshot.exists()) {
            const clientUIDData = userDocumentSnapshot.data();
            clientUIDData.uid = userDocumentSnapshot.id;

            const userSettingsRef = collection(db, `${DATABASECOLLECTION.USERPROFILES}/${client.uid}/${DATABASECOLLECTION.OTHERSETTINGS}`);
            const userSettingsSnapshot = await getDocs(userSettingsRef);

            if (!userSettingsSnapshot.empty) {
              const clientUIDsReferences = userSettingsSnapshot.docs[0];
              const clientUIDsDataU = clientUIDsReferences.data() || [];
              // console.log("clientUIDsDataU",clientUIDsDataU.clinicianUID.path);
              clientUIDData.clinicianUID = clientUIDsDataU.clinicianUID.path
              clientUIDData.activationCode = clientUIDsDataU.activationCode;

              const activationCodeDoc = doc(collection(db, DATABASECOLLECTION.ACTIVATIONCODES), clientUIDsDataU.activationCode);
              const activationDocumentSnapshot = await getDoc(activationCodeDoc);
              const activationSnapshot = activationDocumentSnapshot.data();
              clientUIDData.isUsed = activationSnapshot?.isUsed;
            }
            return clientUIDData;
          }
          return null;
        });

        const resolvedPromises = await Promise.all(promises);
        const resultEvent = resolvedPromises.filter(client => client !== null);

        setData(resultEvent);
        setFinalData(resultEvent);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching documents:', error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(()=>{
    if(clinicianList?.length){
      let obj = {};

      clinicianList.forEach(element => {
        obj[element?.uid] = `${element?.firstName} ${element?.lastName}`
      });
      setClinicianObj(obj);
    }
  },[clinicianList?.length]);

  const onFilterClick = async () => {
    const lowercasedEmail = email.toLowerCase();
    const lowercasedInput = fullName.toLowerCase();
    const hasStatusFilter = status !== undefined && status.trim() !== '';
    const clinicianUid = selectedClinician
    const searchData = finalData.filter(({ firstName, lastName, emailAddress, isUsed, uid, clinicianUID }) => {
      const fullNameInput = `${firstName} ${lastName}`.toLowerCase();
      const statusFilter =
        hasStatusFilter ? isUsed === (status === 'true') : true;

      return (
        (emailAddress && emailAddress.toString().toLowerCase().includes(lowercasedEmail)) &&
        fullNameInput.includes(lowercasedInput) && statusFilter && clinicianUid === clinicianUID
      );
    });

    setData(searchData);
  };

  const onResetClick = async () => {
    setData(finalData);
  };

  const columns = [
    {
      name: 'Full Name',
      selector: row => row.firstName + ' ' + row.lastName,
      sortable: true,
    },
    {
      name: 'Email ID',
      selector: row => row.emailAddress,
      sortable: true,
    },
    {
      name: 'Activation Code',
      selector: row => (row.isUsed ? '-' : row.activationCode),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => {
        return (row.isUsed ? <SuccessBadge text="Activated" /> : <WarningBadge text="Pending" />)
      },
      sortable: true,
    },
    {
      name: 'Clinician',
      selector: row => {
        let clinicianId = row?.clinicianUID ? row?.clinicianUID.split("/")?.[1] : ""
       return  clinicianObj?.[clinicianId]
       
      },
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <CustomActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const CustomActionsCell = ({ row }) => {
    const patient = row;
    const updateURL = `${ADMIN_ROUTES.MANAGE_CLIENT_URL}/${patient.uid}`;
    return (
      (row.isUsed &&
        <div className='col-auto'>
          <Link to={updateURL} className="text-decoration-none">
            <button type="button" button="true" className='editBtn btn btn-outline-dark'>
              <div className="editPencilImg d-flex align-items-center gap-1">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }} xmlns="http://www.w3.org/2000/svg"><path d="M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z"></path></svg>Manage
              </div>
            </button>
          </Link>
        </div>
      )
    );
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            <div className='row'>
              <div className='col-12'>
                <div className='commonCard'>
                  <div className='card-header '>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <div className='SectionHeadingMain'>Manage Patients</div>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    {isLoading && <Loader />}
                    {!isLoading && (
                      <>
                        <div className="row">
                          <div className="col">
                            <input
                              type="text"
                              className="form-control shadow-none border controlRounded"
                              placeholder="Full Name"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control shadow-none border controlRounded"
                              placeholder="example@example.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="col">
                            <select
                              className="form-control shadow-none border controlRounded"
                              name="status"
                              onChange={(event) => setStatus(event.target.value)} value={status}
                            >
                              <option value=''>--Choose Status--</option>
                              <option value='false'>Pending</option>
                              <option value='true'>Activated</option>

                            </select>
                          </div>
                          {
                            role && role === ROLES.ADMIN ? (
                              <>
                                <div className="col">
                                  <select
                                    className="form-control shadow-none border controlRounded"
                                    name="status"
                                    onChange={(event) => setSelectedClinician(event.target.value)}
                                    value={selectedClinician}
                                  >
                                    <option value=''>--Choose Clinician--</option>
                                    {clinicianList && clinicianList.map((item) => (
                                      <option
                                        // key={item.uid}
                                        // value={item.uid}
                                        key={item.uid}
                                        value={`${DATABASECOLLECTION.USERPROFILES}/` + item.uid}
                                        selected={`${DATABASECOLLECTION.USERPROFILES}/` + item.uid == data.clinicianUID}
                                      // selected={`${DATABASECOLLECTION.USERPROFILES}/` + item.uid == data.clinicianUID}
                                      >
                                        {item.firstName + ' ' + item.lastName + ' (' + item.emailAddress + ')'}
                                      </option>
                                    ))}

                                  </select>
                                </div>
                              </>
                            ) : (
                              <></>
                            )
                          }

                          <div className="col-auto">
                            <button className='btn btn-dark mt-0' onClick={onFilterClick}>Filter</button>
                            <button className='btn btn-light mt-0 ms-2' onClick={onResetClick}>Reset</button>
                          </div>
                        </div>
                        <div className='managepatientsTable'>
                          {(data.length === 0 ? (
                            <>
                              <div className="cfvlby">
                                {columns.map((column, index) => (
                                  <div className='sc-crrtmM WubHI' style={{ paddingLeft: "36px" }}>
                                    <span key={index} className="sc-dQpIV ofEne">{column.name}</span>
                                  </div>
                                ))}
                              </div>
                              <p className="text-center py-2">There are no records to display</p>
                            </>
                          ) : (
                            <DataTable
                              fixedHeader
                              fixedHeaderScrollHeight="700px"
                              highlightOnHover
                              noContextMenu
                              pagination
                              responsive
                              subHeaderAlign="right"
                              subHeaderWrap
                              columns={columns}
                              data={data}
                            />
                          )
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllPatientListing