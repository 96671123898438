import React from "react";
import successRight from '../../assets/admin/img/rightSuccess.svg'
import RedCross from '../../assets/admin/img/Redcross.svg'

const Alert = ({ text, type }) => {
    return (
        <>
            <div className="alertAdmin py-3">
                {type && type === 'success' ?
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="successMsg shadow-sm">
                                <div className="row align-items-center mx-0">
                                    <div className="col borderDiv">
                                        <div className="row align-items-center mx-0">
                                            <div className="col-auto pe-2 ps-1">
                                                <div className="successImg d-flex">
                                                    <img src={successRight} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                                </div>
                                            </div>
                                            <div className="col px-0">
                                                <div className="successtxt">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {/* <div className="head">
                                                                Success
                                                            </div> */}
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="subHead">
                                                                {text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-auto ps-0">
                                        <div className="closeBtn p-0">
                                            <a href="javaScript:;" className="text-decoration-none closeBtnAnc">Close</a>
                                        </div>
                                    </div>  */}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row align-items-center py5 mx-0">
                        <div className="col-12">
                            <div className="successMsg dangerMsg shadow-sm">
                                <div className="row align-items-center mx-0">
                                    <div className="col borderDiv">
                                        <div className="row align-items-center mx-0">
                                            <div className="col-auto pe-2 ps-1">
                                                <div className="successImg d-flex">
                                                    <img src={RedCross} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                                </div>
                                            </div>
                                            <div className="col px-0">
                                                <div className="successtxt">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {/* <div className="head">
                                                                Error
                                                            </div> */}
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="subHead">
                                                                {text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-auto">
                                        <div className="closeBtn">
                                            <a href="javaScript:;" className="text-decoration-none closeBtnAnc">Close</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Alert