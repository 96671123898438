import React, { useState } from 'react';
import tinnImg from '../../assets/admin/img/signFinal.jpg'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../common/firebase';
import { ROLES } from '../../constants/RoleConst';
import Alert from '../../components/alert/Alert';
import { sendEmail, setRoleData } from '../../common/fetch';
import { getTemplateContent } from '../../common/helper';
import { TEMPPLATETYPE } from '../../constants/TemplateTypeConst';
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTES, FRONTEND_ROUTES } from '../../constants/RouteConst';

const SignUp = () => {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistration = async () => {
    try {
      setSuccessMsg('');
      setErrorMsg('');
      const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

      const mandatoryFields = [firstName, lastName, email, password, phone, state, country];

      for (const field of mandatoryFields) {
        if (!field) {
          setErrorMsg('Please complete all mandatory fields (indicated by an asterisk).');
          return false;
        }
      }

      if (!isValidEmail(email)) {
        setErrorMsg('Entered invalid email address');
        return false;
      }
      setIsLoading(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;
      const defaultRole = ROLES.CLINICIANPENDING;

      if (userCredential && userCredential.user) {
        try {
          // const user = userCredential.user;
          // const verify = await sendEmailVerification(userCredential.user);
          const clinicianTemplate = await getTemplateContent(TEMPPLATETYPE.CLINICIANAPPLICATION);
          const templateContent = clinicianTemplate[0].content;
          const updatedEmailContent = templateContent.replace(/%%FirstName%%/g, firstName)
            .replace(/%%LastName%%/g, lastName);
          await sendEmail(email, clinicianTemplate[0].subject, updatedEmailContent, clinicianTemplate[0].bcc);

          await auth.signOut();
          await setRoleData(userId, defaultRole);
          const userProfileDocRef = doc(collection(db, DATABASECOLLECTION.USERPROFILES), userId);
          await setDoc(userProfileDocRef, {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            emailAddress: email,
            qualifications: qualifications,
            state: state,
            country: country,
            message: message,
            isActive: true,
          });
          const stateRef = collection(userProfileDocRef, DATABASECOLLECTION.STATE);
          const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
          const currentDocSnap = await getDoc(currentDocRef);

          if (!currentDocSnap.exists()) {
            await setDoc(currentDocRef, {
              completedHearingTest: false,
              completedTRQ: false,
              connectedClinician: false,
              onboardComplete: false,
            });
          } else {
            await updateDoc(currentDocRef, {
              onboardComplete: false,
            });
          }
          setIsLoading(false);
          setSuccessMsg('User registered successfully! Please check your email for verification.');
        } catch (error) {
          if (error.code === 'auth/email-already-in-use') {
            setErrorMsg('This email is already in use. Please use a different email.');
          } else {
            setErrorMsg('An error occurred during registration. Please try again.');
          }
          setIsLoading(false);
        }
      };
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMsg('This email is already in use. Please use a different email.');
      } else {
        setErrorMsg('An error occurred during registration. Please try again.');
      }
      setIsLoading(false);
    }
  }
  return (
    <>
      <div className="authpages h-100 overflow-y-auto">
        <div className="container-fluid  h-100 signupSection">
          <div className="row h-lg-100 align-items-lg-center justify-content-center ">
            <div className="col-12 col-lg-6 px-0 overflow-hidden">
              <div className="registerImg">
                <div className="position-relative h-100 w-100">
                  <img src={tinnImg} className="img-fluid w-100 h-100 object-fit-cover" alt="table accordion" />
                  <div className="homePageBtn">
                    <div className="honeBtn">
                      <Link to={FRONTEND_ROUTES.HOME_PAGE} className='d-flex align-items-center gap-1 text-decoration-none'>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className=' homepageBtnAnc' xmlns="http://www.w3.org/2000/svg"><path d="M216.4 163.7c5.1 5 5.1 13.3.1 18.4L155.8 243h231.3c7.1 0 12.9 5.8 12.9 13s-5.8 13-12.9 13H155.8l60.8 60.9c5 5.1 4.9 13.3-.1 18.4-5.1 5-13.2 5-18.3-.1l-82.4-83c-1.1-1.2-2-2.5-2.7-4.1-.7-1.6-1-3.3-1-5 0-3.4 1.3-6.6 3.7-9.1l82.4-83c4.9-5.2 13.1-5.3 18.2-.3z"></path></svg>
                        <span className='d-flex align-items-center ms-1 text-white btnName'>Go To Home Page</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="inputsignSide text-center py-4">
                <div className="row">
                  <div className="col-12">
                    <div className="headingI pb-1">
                      Sign Up
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="subHeding">
                      to continue to Neuromonics.
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="MainForm">
                      {errorMsg && (
                        <Alert text={errorMsg} type="error" />
                      )}
                      {successMsg && (
                        <Alert text={successMsg} type="success" />
                      )}
                      {isLoading && (<p style={{ color: '#34617e', fontWeight: 600 }}><i>Please wait....</i></p>)}
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input type="text"
                              className="form-control shadow-none formcontrolCustom"
                              id="firstName"
                              name="firstName"
                              placeholder=""
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                            <label htmlFor="firstName" className='formControlLable'>
                              <div className="labelTxt">
                                First Name *
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none formcontrolCustom"
                              id="lastname"
                              name="lastname"
                              placeholder=""
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                            <label htmlFor="lastname" className='formControlLable'>
                              <div className="labelTxt">
                                Last Name *
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control shadow-none formcontrolCustom"
                              id="email"
                              name="email"
                              placeholder="name@example.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <label htmlFor="email" className='formControlLable'>
                              <div className="labelTxt">
                                Email *
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control shadow-none formcontrolCustom"
                              id="password"
                              name="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <label htmlFor="password" className='formControlLable'>
                              <div className="labelTxt">
                                Password *
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="tel"
                              className="form-control shadow-none formcontrolCustom"
                              id="phone"
                              name="phone"
                              placeholder=""
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            <label htmlFor="phone" className='formControlLable'>
                              <div className="labelTxt">
                                Phone Number *
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none formcontrolCustom"
                              id="qualifications"
                              name="qualifications"
                              placeholder=""
                              value={qualifications}
                              onChange={(e) => setQualifications(e.target.value)}
                            />
                            <label htmlFor="qualifications" className='formControlLable'>
                              <div className="labelTxt">
                                Qualifications
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none formcontrolCustom"
                              id="state"
                              name="state"
                              placeholder=""
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                            />
                            <label htmlFor="state" className='formControlLable'>
                              <div className="labelTxt">
                                State/province *
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none formcontrolCustom"
                              id="country"
                              name="country"
                              placeholder=""
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                            <label htmlFor="country" className='formControlLable'>
                              <div className="labelTxt">
                                Country *
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3 ">
                            <textarea
                              className="form-control formcontroltxtCustom shadow-none"
                              rows="3" placeholder="Leave a comment here"
                              name="message"
                              id="message"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                            <label htmlFor="message">
                              <div className="labelTxt">
                                Message  (Optional)
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-6 mx-auto">
                          <div className="signInButton mt-3 mt-md-4  text-center">
                            <button className='signinBtn' onClick={handleRegistration}>Register</button>
                          </div>
                        </div>
                        <div className="col-12 text-center mt-2">
                          <span className='pe-1'>Already have an account?</span>
                          <Link to={ADMIN_ROUTES.LOGIN_URL} className='text-decoration-none forgottxt'>
                            Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp;