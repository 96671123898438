import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/effect-fade';
import "swiper/css";
import 'swiper/css/free-mode';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../common/firebase';
import { FILENAME, STORAGE, STORAGEURL } from '../../../constants/StorageConst';
import CoverImage from '../../../components/getCoverImage/CoverImage';
import { FRONTEND_ROUTES } from '../../../constants/RouteConst';
import slideOne from '../../../assets/common/img/slider1.jpg';


const BlogComponent = () => {
    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const jsonFileRef = ref(storage, STORAGEURL + '/' + STORAGE.BLOGS + '/' + FILENAME.BLOGFILENAME);
            try {
                const url = await getDownloadURL(jsonFileRef);
                const response = await fetch(url);
                const data = await response.json();
                data.slice(0, 6);
                setJsonData(data)
            } catch (error) {
                console.error('Error fetching or parsing JSON file:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            {/* <div className="row">
                <Swiper
                    slidesPerView={3}
                    spaceBetween={20}
                    loop={true}
                    freeMode={true}
                    // centeredSlides= {true}
                    speed={7000}
                    allowTouchMove={true}
                    autoplay={{
                        delay: 2000,
                        enabled: true,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        360: {
                            slidesPerView: 1.1,
                        },
                        576: {
                            slidesPerView: 1.5,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 2.5,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                        1400: {
                            slidesPerView: 3,
                        },
                    }}
                    modules={[FreeMode, Autoplay]}
                    className="mySwiper"
                >
                    {
                        jsonData && jsonData.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="col-12 blogInnerFluid">
                                    <div className="blogCradWrapper  giveSpace">
                                        <div className="row mx-0">
                                            <div className="col-12">
                                                <div className="blogImg one blogImg2">
                                                    <CoverImage image={item.cover_img} />
                                                    <div className="row mx-0 align-items-center authorRow">
                                                        <div className="col-12 px-0">
                                                            <div className="authorList">Post By: {item.author}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row justify-content-center py-lg-3 py-3">
                                                    <div className="col-12 d-flex justify-content-center mb-4">
                                                        <div className="title">{item.title}</div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <Link to={FRONTEND_ROUTES.BLOG_DETAILS + '/' + item.slug} className="nuroBtnn text-decoration-none">Read More </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div> */}
            <div className='row mx-0 mt-4 overflow-hidden blogSection blogInnerFluid'>
                <div className='col-12 blogscrrolInner d-flex align-items-center flex-nowrap gap-3 gap-sm-4 gap-md-5 overflow-hidden'>
                    <div className='innerblogmain  w-auto d-flex align-items-start overflow-hidden gap-3 gap-sm-4 gap-md-5 p-0'>
                        {
                            jsonData && jsonData.map((item, index) => (
                                <div className='blogcard h-100' key={index}>
                                    <div className='row'>
                                        <div className="col-12 ">
                                            <div className="blogTestiImg">
                                                <CoverImage image={item.cover_img} />
                                                <div className="row mx-0 align-items-center authorRow">
                                                    <div className="col-12 px-0">
                                                        <div className="authorList">Post By: {item.author}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="blogHeading py-4 text-center">
                                                {item.title}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="CardRead text-center">
                                                <Link to={FRONTEND_ROUTES.BLOG_DETAILS + '/' + item.slug} className="nuroBtnn text-decoration-none">Read More </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='innerblogmain  w-auto d-flex align-items-start overflow-hidden gap-3 gap-sm-4 gap-md-5 p-0'>
                        {
                            jsonData && jsonData.map((item, index) => (
                                <div className='blogcard h-100' key={index}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="blogTestiImg">
                                                <CoverImage image={item.cover_img} />
                                                <div className="row mx-0 align-items-center authorRow">
                                                    <div className="col-12 px-0">
                                                        <div className="authorList">Post By: {item.author}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="blogHeading py-4 text-center">
                                                {item.title}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="CardRead text-center">
                                                <Link to={FRONTEND_ROUTES.BLOG_DETAILS + '/' + item.slug} className="nuroBtnn text-decoration-none">Read More </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogComponent